import { useEffect, useMemo, useState } from "react";
import { Tabs } from "components/tabs/Tabs";
import { ViewsTab } from "./ViewsTab";
import { RevenueTab } from "./RevenueTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
    updateAnalyticsCustomEndDate,
    updateAnalyticsCustomStartDate,
    updateAnalyticsDateRangeSelection
} from "store/view/slice";
import { usePageHeader } from "hooks/usePageHeader";
import { GatedContentStatus } from "hooks/useUserStripeData";
import { useTranslation } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    VideoMetricsRequestSummaryInterval,
    RevenueMetricsRequestSummaryInterval
} from "@switcherstudio/switcher-api-client";
import { useDateRangeSelector } from "hooks/useDateRangeSelector";
import { useClaimCheck } from "hooks/useClaimCheck";
import { CustomersTab } from "./CustomersTab";
import { useUserStripeData } from "hooks/useUserStripeData";
import { getHoursFromTimespan, getHoursUntilUTCMidnight } from "helpers/time";

export enum AnalyticsDateRangeOptions {
    SevenDays = "sevenDays",
    ThirtyDays = "thirtyDays",
    MonthToDate = "monthToDate",
    YearToDate = "yearToDate",
    Custom = "custom"
}

enum AnalyticsTabs {
    Views = "views",
    Revenue = "revenue",
    Customers = "Customers"
}

export const AnalyticsPage: React.FC = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const {
        accountData: {
            gatedContentStatus,
            link: { href }
        }
    } = useUserStripeData({
        includeProducts: false,
        requestImmediately: true,
        redirectPath: "/analytics"
    });

    const [analyticsActiveTab, setAnalyticsActiveTab] = useState<AnalyticsTabs>(
        AnalyticsTabs.Views
    );

    const hasEmailGating = useClaimCheck("gatedcontent.email");

    const {
        analyticsDateRangeSelection,
        analyticsCustomDateRangeSelection = { start: null, end: null }
    } = useSelector((state: RootState) => state.view);

    const { customStartDate, customEndDate } = useMemo(() => {
        const { start, end } = analyticsCustomDateRangeSelection;
        return {
            customStartDate: start
                ? new Date(start)
                : new Date(new Date().setDate(new Date().getDate() - 7)),
            customEndDate: end ? new Date(end) : new Date()
        };
    }, [analyticsCustomDateRangeSelection]);

    const {
        start,
        end,
        component: DateRangeSelectorComponent
    } = useDateRangeSelector({
        id: "analytics-date-range",
        label: "Range",
        selection: analyticsDateRangeSelection,
        options: Object.values(AnalyticsDateRangeOptions).map((value) => ({
            value,
            text: t(`date-range-options:${value}`)
        })),
        onChange: (e) =>
            dispatch(
                updateAnalyticsDateRangeSelection(
                    e.target.value as AnalyticsDateRangeOptions
                )
            ),
        customStartDate: customStartDate,
        placeholderStartDate: analyticsCustomDateRangeSelection?.start,
        onStartDateChange: (date) =>
            dispatch(updateAnalyticsCustomStartDate(date)),
        customEndDate: customEndDate,
        placeholderEndDate: analyticsCustomDateRangeSelection?.end,
        onEndDateChange: (date) => dispatch(updateAnalyticsCustomEndDate(date)),
        datePickerProps: {
            showTimeSelect: false,
            locale: i18n.language
        }
    });

    const { data: userMetrics, loading } = useSwitcherClient(
        (client) => client.videoAnalytics_GetMetrics,
        {
            requestImmediately: true,
            args: [
                {
                    StartDate: start,
                    EndDate: end,
                    SummaryInterval: VideoMetricsRequestSummaryInterval.Day,
                    PopulateDateRange: true
                }
            ]
        }
    );

    const { data: revenueMetrics, loading: revenueLoading } = useSwitcherClient(
        (client) => client.revenueAnalytics_GetRevenue,
        {
            requestImmediately: true,
            args: [
                {
                    StartDate: start,
                    EndDate: end,
                    SummaryInterval: RevenueMetricsRequestSummaryInterval.Day,
                    PopulateDateRange: true
                }
            ]
        }
    );

    const { data: customerMetrics, loading: customersLoading } =
        useSwitcherClient(
            (client) => client.customerRedemptionAnalytics_GetCustomersForUser,
            {
                requestImmediately: true,
                args: [
                    {
                        StartDate: start,
                        EndDate: end
                    }
                ]
            }
        );

    useEffect(() => {
        if (gatedContentStatus !== GatedContentStatus.READY) {
            setAnalyticsActiveTab(AnalyticsTabs.Views);
        }
    }, [dispatch, gatedContentStatus]);

    const { lastUpdatedHours, nextUpdateHours } = useMemo(() => {
        if (loading) {
            return {
                lastUpdatedHours: null,
                nextUpdateHours: null
            };
        }

        const lastUpdatedHours = getHoursFromTimespan(userMetrics?.Age);
        const nextUpdateHours = getHoursUntilUTCMidnight();

        return {
            lastUpdatedHours,
            nextUpdateHours
        };
    }, [userMetrics?.Age, loading]);

    usePageHeader({
        subTitle: !loading
            ? `Last updated ${
                  lastUpdatedHours < 1
                      ? "less than an hour"
                      : `${lastUpdatedHours} hour${
                            lastUpdatedHours > 1 ? "s" : ""
                        }`
              } ago. Next update in ${
                  nextUpdateHours < 1
                      ? "less than an hour"
                      : `${nextUpdateHours} hours`
              }.`
            : null,
        customButtons: DateRangeSelectorComponent
    });

    return (
        <Tabs<AnalyticsTabs>
            tabs={[
                {
                    id: AnalyticsTabs.Views,
                    label: "Views",
                    component: (
                        <ViewsTab
                            metricsLoading={loading}
                            metrics={userMetrics}
                        />
                    )
                },
                {
                    id: AnalyticsTabs.Revenue,
                    label: "Revenue",
                    component: (
                        <RevenueTab
                            stripeLink={href}
                            metrics={revenueMetrics}
                            metricsLoading={revenueLoading}
                        />
                    )
                },
                hasEmailGating && {
                    id: AnalyticsTabs.Customers,
                    label: "Emails",
                    component: (
                        <CustomersTab
                            metrics={customerMetrics}
                            metricsLoading={customersLoading}
                        />
                    )
                }
            ]}
            activeTab={analyticsActiveTab}
            setActiveTab={(id) => setAnalyticsActiveTab(id)}
        />
    );
};
