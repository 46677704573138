import { useGetCreatorProductsV2 } from "./useGetCreatorProductsV2";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useSwitcherClient } from "./useSwitcherClient";
import rollbar from "helpers/rollbar";
import { useClaimCheck } from "./useClaimCheck";
import { useMemo, useState } from "react";

export enum GatedContentStatus {
    FEATURE_DISABLED,
    STRIPE_ACCOUNT_NOT_SETUP,
    STRIPE_ACCOUNT_UNVERIFIED,
    READY
}

export interface UseUserStripeProps {
    requestImmediately: boolean;
    includeProducts: boolean;
    redirectPath?: string;
}

export const useUserStripeData = ({
    requestImmediately = true,
    includeProducts = false,
    redirectPath = "/gated-content"
}: UseUserStripeProps) => {
    const enablePayPerView = useClaimCheck("gatedcontent");
    const userInfo = useSelector((state: RootState) => state.user.userInfo);

    const [target, setTarget] = useState<string>();
    const [href, setHref] = useState<string>();

    const { dispatchApiRequest: getAccountLink, loading: accountLinkLoading } =
        useSwitcherClient((client) => client.stripeConnect_GetAccountLink);

    // Get the stripe account for the user
    const {
        data: stripeAccounts,
        loading: stripeAccountLoading,
        dispatchApiRequest: getStripeAccounts
    } = useSwitcherClient((client) => client.stripeConnect_GetStripeAccount, {
        requestImmediately: true,
        args: [userInfo?.UserId, true],
        onError: (e) => {
            rollbar.warning("Error getting stripe account", e);
        },
        onSuccess: async (data) => {
            // If we have already configured an href we don't need to do anything
            if (!!href) return;

            // if account is not setup yet, we need to get the account link
            if (
                data?.accounts?.length === 0 ||
                !data?.accounts?.[0]?.DetailsSubmitted
            ) {
                const accountLink = await getAccountLink([
                    userInfo?.UserId,
                    new URL(
                        `/stripe-authorize?returnUrl=${encodeURIComponent(
                            redirectPath
                        )}`,
                        window.location.origin
                    ).href
                ]);

                // Refetch account data since it is now setup for componentry to render correctly
                if (data?.accounts?.length === 0) {
                    getStripeAccounts();
                }
                setHref(accountLink.url);
            } else {
                setTarget("_blank");
                setHref("https://dashboard.stripe.com/login");
            }
        }
    });

    // The stripe account
    const account = useMemo(
        () => stripeAccounts?.accounts?.[0],
        [stripeAccounts]
    );

    // The expanded stripe account details
    const details = useMemo(
        () => stripeAccounts?.Details?.[0],
        [stripeAccounts]
    );

    // The product data for the stripe account
    const productData = useGetCreatorProductsV2({
        requestImmediately: requestImmediately && includeProducts,
        stripeAccountLoading,
        stripeAccountId: account?.Id
    });

    // Calculate the gated content status
    const gatedContentStatus = useMemo(() => {
        if (!enablePayPerView) return GatedContentStatus.FEATURE_DISABLED;
        if (!account || (!account.DetailsSubmitted && !account.ChargesEnabled))
            return GatedContentStatus.STRIPE_ACCOUNT_NOT_SETUP;
        if (!account.DetailsSubmitted || !account.ChargesEnabled)
            return GatedContentStatus.STRIPE_ACCOUNT_UNVERIFIED;
        return GatedContentStatus.READY;
    }, [enablePayPerView, account]);

    return {
        accountData: {
            account,
            details,
            gatedContentStatus,
            link: {
                target,
                href
            }
        },
        productData,
        loading:
            productData?.loading || stripeAccountLoading || accountLinkLoading,
        getStripeAccounts
    };
};
