import React, { useMemo, useState } from "react";
import { PricingDetails } from "./PricingDetails";
import { useUserStripeData } from "hooks/useUserStripeData";
import { StripeConnectWrapper } from "components/stripe/StripeConnectWrapper";
import { useCombineLoading } from "hooks/useCombineLoading";

/**
 * A section of the Gated Content page that displays the Stripe section.
 */
export const GatedContentPageStripeSection = () => {
    const {
        accountData: {
            account,
            details,
            gatedContentStatus,
            link: { href }
        },
        productData: { products, getProducts, productSubscribers },
        loading: stripeLoading
    } = useUserStripeData({
        requestImmediately: true,
        includeProducts: true
    });

    const [loading, setLoading] = useState(false);

    const pageLoading = useCombineLoading(stripeLoading, loading);

    const nonCatalogProducts = useMemo(
        () =>
            products?.filter(
                (p) =>
                    p.CreatorProductEntitlements.length === 0 ||
                    // @ts-expect-error - Handle multiple types
                    p.CreatorProductEntitlements.some((cpe) => !cpe.CatalogId)
            ),
        [products]
    );

    return (
        <div>
            <StripeConnectWrapper
                loading={stripeLoading}
                gatedContentStatus={gatedContentStatus}
                href={href}
                inlineLoading={false}
            >
                <PricingDetails
                    account={account}
                    loading={pageLoading}
                    products={nonCatalogProducts}
                    productSubscribers={productSubscribers}
                    getProducts={getProducts}
                    details={details}
                    setLoading={setLoading}
                />
            </StripeConnectWrapper>
        </div>
    );
};
