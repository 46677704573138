import React, { useCallback, useState } from "react";
import { PillSelector } from "components/pill-selector/PillSelector";
import ChevronRight from "assets/icons/chevron-right.svg?react";
import profileImage from "assets/icons/username.svg?url";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { usePageHeader } from "hooks/usePageHeader";

interface DestinationSelectorProps {
    destinations: { id: string }[] | undefined;
    pills?: string[];
    initialPill?: string;
    imgSrcKey: string;
    destinationNameKey: string;
    emptyMessage?: string;
    emptyAction?: { text: string; action: () => void };
    onDestinationClick(e: Required<{ id: string }>): void;
    onPillSelect?: (p: string) => Promise<void>;
    isPaginated?: boolean;
    loadMore?: () => Promise<void>;
    isSimulcast?: boolean;
    disableAll?: boolean;
}

function getNestedValue(obj: object, path: string): string {
    const valuePath = path.split(".");
    let curr: any = obj;

    for (let v of valuePath) {
        if (!curr) break;
        curr = curr[v];
    }

    return curr || "";
}
export const DestinationSelector: React.FC<DestinationSelectorProps> = ({
    destinations,
    imgSrcKey,
    destinationNameKey,
    pills,
    initialPill,
    emptyMessage,
    emptyAction,
    onDestinationClick,
    isPaginated,
    loadMore,
    onPillSelect,
    isSimulcast = false,
    disableAll = false
}: DestinationSelectorProps) => {
    const [selectedPill, setSelectedPill] = useState<string>(
        initialPill || pills?.[0]
    );
    const { t } = useTranslation("platforms");
    const { loading } = useSelector((state: RootState) => state.loading);
    const { generalForm } = useSelector(
        (state: RootState) => state.platforms.general
    );

    // the second page of the livestream creation flow
    // appears after selecting a destination
    usePageHeader({
        title: t("platforms:new-livestream"),
        showBreadcrumbs: true,
        customCrumbs: [
            {
                label: t("page-titles:scheduled-livestreams"),
                route: "/scheduled-livestreams"
            },
            { label: t("platforms:new-livestream"), route: "/" }
        ]
    });

    const markSelectedDests = useCallback(
        (dests) => {
            const selectedDests = generalForm.selectedSimulcastDestinations;
            return dests.map((d) => {
                if (
                    selectedDests.some(
                        (sd) =>
                            sd.id === d.id ||
                            sd.id === d["switcher-stream-setting-id"]
                    ) ||
                    disableAll
                ) {
                    return {
                        ...d,
                        isDisabled: true
                    };
                }

                return d;
            });
        },
        [generalForm.selectedSimulcastDestinations, disableAll]
    );

    const renderDestinations = useCallback(
        (destinations: { id: string }[] | undefined) => {
            if (destinations?.length && isSimulcast) {
                destinations = markSelectedDests(destinations);
            }

            if (!destinations?.length) {
                if (loading) {
                    return (
                        <div className="alert alert-warning">
                            {t("loading-destinations", {
                                selectedPill: selectedPill || ""
                            })}
                        </div>
                    );
                }

                return (
                    <div className="alert alert-warning">
                        {emptyMessage ||
                            `There are no ${
                                selectedPill || ""
                            } destinations available.`}
                        &nbsp;
                        {!!emptyAction?.text && (
                            <button
                                className="btn btn-primary"
                                onClick={emptyAction.action}
                            >
                                {emptyAction.text}
                            </button>
                        )}
                    </div>
                );
            }
            return destinations.map((d, i) => {
                const imgSrc = getNestedValue(d, imgSrcKey);
                const destinationName = getNestedValue(d, destinationNameKey);

                return (
                    <li
                        key={d.id + i}
                        className={` ${styles["destination-list-item"]} ${
                            getNestedValue(d, "isDisabled") &&
                            styles["disabled"]
                        }`}
                        onClick={() => onDestinationClick(d)}
                    >
                        <img
                            src={imgSrc || profileImage}
                            alt={`Icon for ${destinationName}`}
                        />
                        <h6>{destinationName}</h6>
                        <ChevronRight />
                    </li>
                );
            });
        },
        [
            isSimulcast,
            markSelectedDests,
            loading,
            destinationNameKey,
            emptyAction,
            emptyMessage,
            imgSrcKey,
            onDestinationClick,
            selectedPill,
            t
        ]
    );

    return (
        <div className={styles["form-card"]}>
            {pills?.length > 0 && (
                <div className={styles["form-card-header"]}>
                    <PillSelector
                        pills={pills}
                        selectedPill={selectedPill}
                        selectPill={(p) => {
                            setSelectedPill(p);
                            onPillSelect(p);
                        }}
                    />
                </div>
            )}
            <div className={styles["form-card-body-destination-list"]}>
                <ul className={styles["destination-list"]}>
                    {renderDestinations(destinations)}
                </ul>
                {isPaginated && loadMore && (
                    <div className={`${styles["load-more"]}`}>
                        <button
                            onClick={loadMore}
                            className={`${styles["load-more-btn"]} justify-content-center btn btn-primary`}
                        >
                            Load More
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};
