import { StreamSettingResponse } from "@switcherstudio/api-core-client";
import { parse } from "helpers/json";
import {
    FacebookMetadata,
    YoutubeMetadata,
    TwitchMetadata
} from "views/page-content/VideoDetailsPage/ExternalDestinations/types";

export const getChannelName = (destination: StreamSettingResponse) => {
    switch (destination.Details?.Discriminator) {
        case "FaceBookSwitcherStreamSetting":
            return (
                parse<FacebookMetadata>(destination.Details?.Meta)?.edgeName ??
                "FaceBook"
            );
        case "YouTubeSwitcherStreamSetting":
            return (
                parse<YoutubeMetadata>(destination.Details?.Meta)?.username ??
                "YouTube"
            );
        case "TwitchSwitcherStreamSetting":
            return (
                parse<TwitchMetadata>(destination.Details?.Meta)?.username ??
                "Twitch"
            );
        default:
            return destination.Details?.ChannelName;
    }
};
