import { StreamingProvider } from "@switcherstudio/switcher-api-client";

export enum PlatformId {
    AmazonLive = "8c36a084-6912-411e-b4bb-6c2c9320f117",
    AzureMediaServices = "CA274A98-C17C-4454-BE52-EF6BF89DA1FF",
    BoxCast = "62B54187-6E38-43C8-9EC4-28709EEEC382",
    Caast = "32afd5f8-381a-4b42-bef9-b0682d27402e",
    Instafeed = "de803a00-914a-4966-8d65-08ab4ab060ad",
    ChurchStreamingTv = "churchstreamingtv",
    Facebook = "facebook",
    MsStream = "5B8A6C1A-3537-4564-98A8-84124B3DED38",
    Shopify = "shopify",
    Simulcast = "simulcast",
    StreamSpot = "streamspot",
    StripeConnect = "stripe-connect",
    SwitchboardLive = "switchboard",
    Twitch = "twitch",
    Youtube = "youtube",
    VideoPlayer = "switcherplayer"
}

export enum YoutubeEventPrivacy {
    Public = "public",
    Private = "private",
    Unlisted = "unlisted"
}

export enum FacebookEventPrivacy {
    Everyone = "EVERYONE",
    Friends = "ALL_FRIENDS",
    OnlyMe = "SELF"
}

export enum StripeStatus {
    Active = "active",
    PastDue = "past_due",
    Unpaid = "unpaid",
    Canceled = "canceled",
    Incomplete = "incomplete",
    IncompleteExpired = "incomplete_expired",
    Trialing = "trialing",
    Ended = "ended",
    Paused = "paused"
}

export type FacebookDestination = {
    id: string;
    name: string;
};

export type FacebookDestinations = {
    [key in "meDestinations" | "pageDestinations"]: FacebookDestination[];
};

export type PlatformActionKeys =
    | PlatformId.Facebook
    | PlatformId.Youtube
    | PlatformId.Twitch
    | PlatformId.VideoPlayer
    | PlatformId.Shopify
    | PlatformId.StripeConnect
    | PlatformId.MsStream
    | PlatformId.AmazonLive
    | PlatformId.AzureMediaServices
    | PlatformId.SwitchboardLive
    | PlatformId.ChurchStreamingTv
    | PlatformId.Caast
    | PlatformId.Instafeed;

export type PlatformActions = {
    [key in PlatformActionKeys]: PlatformAction;
};

export type PlatformAction = {
    link?<T>(optionalArgs?: T): Promise<void>;
    unlink?: () => Promise<void>;
    // NOT ANY TYPE THIS AS INITIAL STATE OF <T> (T=PLATFORM)
    init?: (destinationInfo?: {
        destinationType: string;
        destinationId: string | number;
    }) => Promise<PlatformInitialState>;
    getPlatform?: () => Promise<StreamingProvider>;
};

export type PlatformInitialState = {
    name: string;
    imgSrc: string;
    existingStreams?: any[];
    hasAccess: boolean;
};

export type ShopifyLinkArgs = {
    shop: string;
    finalReturnUrl?: string;
};

export type ICreateStreamForm = {
    title: string;
    description: string;
    scheduled: boolean;
    scheduledTime?: Date;
    streamQuality: "1080p" | "720p" | "480p" | "360p" | "240p";
    platformOptions: {
        [PlatformId.Facebook]: IFacebookFormSection;
        [PlatformId.Youtube]: IYoutubeFormSection;
        [PlatformId.Twitch]: ITwitchFormSection;
    };
};

export type IFacebookFormSection = {
    eventPrivacy: FacebookEventPrivacy;
    destinationId: string;
};

export type IYoutubeFormSection = {
    isForKids: boolean;
    allowEmbedding: boolean;
    eventPrivacy: YoutubeEventPrivacy;
};

export type ITwitchFormSection = object;
