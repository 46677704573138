import {
    Link as RouterLink,
    LinkProps as RouterLinkProps
} from "react-router-dom";
import styles from "./index.module.scss";

export interface LinkProps extends RouterLinkProps {
    variant?: "link" | "underline" | "button";
    disabled?: boolean;
}

/**
 * A styled wrapper for react-router-dom <Link> component
 */
export const Link = (props: LinkProps) => (
    <RouterLink
        {...props}
        className={`${styles[props.variant || "link"]} ${
            props.disabled ? styles["disabled"] : ""
        }`}
        aria-disabled={props.disabled}
    >
        {props.children}
    </RouterLink>
);
