import { useEffect, useCallback, useState } from "react";
import { trackEvent } from "helpers/analyticsHelpers";
import { ChannelSetting } from "views/page-content/platforms/platform/platform-helper";
import SpeedTestWorker from "../helpers/speedtest.worker?worker";

export enum SpeedTestStatus {
    Waiting = "waiting",
    InProgress = "inprogress",
    Completed = "completed",
    Error = "error"
}
export interface SpeedTestResults {
    upload?: number;
    error?: {
        message: string;
    };
}

export const convertBpsToMbps = (bps: number): number => {
    // Convert bps to Mbps (to two decimal places)
    return Math.round((bps / 1e6) * 100) / 100;
};

export function useSpeedTest() {
    const [speedTestStatus, setSpeedTestStatus] = useState<SpeedTestStatus>(
        SpeedTestStatus.Waiting
    );
    const [results, setResults] = useState<SpeedTestResults | undefined>(
        undefined
    );

    const onFinish = useCallback((results: SpeedTestResults) => {
        setResults(results);
        setSpeedTestStatus(SpeedTestStatus.Completed);

        trackEvent("Speed test completed", {
            testResult: results,
            source: "cloudflare"
        });
    }, []);

    const onError = useCallback((error: { message: string }) => {
        setResults({ error });
        setSpeedTestStatus(SpeedTestStatus.Error);

        // Do we want to report/track speed test errors or just relay to user?
    }, []);

    useEffect(() => {
        const worker = new SpeedTestWorker();

        worker.onmessage = (event) => {
            const { type, results, error } = event.data;

            if (type === "finished") {
                onFinish(results);
            } else if (type === "error") {
                onError(error);
            }
        };

        worker.postMessage({ type: "start" });
        setSpeedTestStatus(SpeedTestStatus.InProgress);

        return () => worker.terminate();
    }, [onFinish, onError, setSpeedTestStatus, setResults]);

    const createChannelSetting = useCallback(
        (
            testResults: SpeedTestResults,
            showFullHD: boolean
        ): ChannelSetting => {
            const { upload: uploadSpeedInBps } = testResults;
            const uploadSpeed = convertBpsToMbps(uploadSpeedInBps || 0);

            /**
             * The upload settings are pulled from the recommendation from Cloudflare: https://developers.cloudflare.com/stream/faq/#what-are-the-recommended-upload-settings-for-video-uploads
             */
            if (showFullHD && uploadSpeed >= 8) {
                return new ChannelSetting(
                    1920,
                    1080,
                    6000000,
                    2,
                    128000,
                    48000
                );
            }

            if (uploadSpeed >= 4.8) {
                return new ChannelSetting(
                    1280,
                    720,
                    showFullHD ? 3200000 : 2000000,
                    2,
                    128000,
                    48000
                );
            }

            if (uploadSpeed >= 2.4) {
                return new ChannelSetting(960, 540, 1200000, 2, 128000, 48000);
            }

            if (uploadSpeed >= 1) {
                return new ChannelSetting(640, 360, 800000, 1, 64000, 48000);
            }

            // Default setting if none match, should not be reached if all cases are covered
            return new ChannelSetting(480, 270, 500000, 1, 64000, 48000);
        },
        []
    );

    return {
        createChannelSetting,
        speedTestStatus,
        results
    };
}
