import { useEffect, useRef } from "react";

export interface UseIntervalOptions {
    /** disable the interval */
    disable?: boolean;
}

export function useInterval(
    callback: () => void,
    delay: number,
    { disable }: UseIntervalOptions = { disable: false }
) {
    const intervalId = useRef<NodeJS.Timeout>(null);
    const savedCallback = useRef<() => void>(null);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (!disable && delay !== null) {
            intervalId.current = setInterval(tick, delay);
        }
        return () => clearInterval(intervalId.current);
    }, [delay, disable]);

    return intervalId.current;
}
