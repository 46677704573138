import React from "react";
import { Link } from "react-router-dom";
import { PlatformIcon } from "components/icons/PlatformIcon";
import styles from "./index.module.scss";
import { PlatformId } from "views/page-content/platforms/types";
import { PlatformsTableProps } from "components/platforms/PlatformsTable";
import { useTranslation, Trans } from "react-i18next";

export const PlatformsTableV2: React.FC<PlatformsTableProps> = ({
    platforms,
    connectText,
    detailsText,
    disconnectText,
    disconnectAction
}: PlatformsTableProps) => {
    const { t } = useTranslation();

    const platformSubheader = (platformId: string) => {
        switch (platformId) {
            case PlatformId.Facebook:
                return t("platforms:facebook-subheader");
            case PlatformId.Twitch:
                return undefined;
            case PlatformId.Youtube:
                return (
                    <Trans
                        i18nKey={t("platforms:youtube-subheader")}
                        components={{
                            link1: (
                                <Link
                                    to={
                                        "https://support.google.com/youtube/answer/2474026?hl=en&ref_topic=9257984&sjid=8139861282208377633-NA"
                                    }
                                    title="YouTube Live Streaming & Premieres"
                                    className={styles["link"]}
                                    target="_blank"
                                />
                            )
                        }}
                    />
                );
            default:
                return "";
        }
    };

    return (
        <table className="table table-sm">
            <tbody>
                {platforms.map((p) => {
                    const subheader = platformSubheader(p?.Id);
                    return (
                        <tr key={p.Id} className={styles["platform-row"]}>
                            <td>
                                {!p.IsLinked ? (
                                    <div
                                        className={`${styles["platform-link"]} ${styles["disconnected"]}`}
                                    >
                                        <PlatformIcon
                                            platformId={p.Id || ""}
                                            useNewIcon={true}
                                        />
                                        <div
                                            className={styles["platform-info"]}
                                        >
                                            {p.Name}
                                            {subheader && (
                                                <small>
                                                    <p className="mb-2 text-muted">
                                                        {subheader}
                                                    </p>
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        className={`${styles["platform-link"]} ${styles["connected"]}`}
                                    >
                                        <PlatformIcon
                                            platformId={p.Id || ""}
                                            useNewIcon={true}
                                        />
                                        <div
                                            className={styles["platform-info"]}
                                        >
                                            {p.Name}
                                            {subheader && (
                                                <small>
                                                    <p className="mb-2 text-muted">
                                                        {subheader}
                                                    </p>
                                                </small>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </td>
                            <td
                                className={`${styles["text-right"]} text-right`}
                            >
                                {p.IsLinked ? (
                                    p.CanManage ? (
                                        <Link
                                            className={
                                                "btn btn-outline-tertiary"
                                            }
                                            to={p.DashboardUrl}
                                        >
                                            {detailsText}
                                        </Link>
                                    ) : (
                                        <button
                                            className="btn btn-outline-tertiary"
                                            onClick={async () => {
                                                await disconnectAction(
                                                    p.Id as PlatformId
                                                );
                                            }}
                                        >
                                            {disconnectText}
                                        </button>
                                    )
                                ) : (
                                    <Link
                                        className={"btn btn-primary"}
                                        to={`/platforms/connect/${p.Id}`}
                                    >
                                        {connectText}
                                    </Link>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
