import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import {
    FacebookContentTag,
    FacebookDestination,
    FacebookForm,
    FacebookProfile,
    GeneralPlatformForm,
    PlatformState,
    TwitchForm,
    TwitchCategory,
    YouTubeForm,
    VideoPlayerForm,
    UpdateSelectedStreamPayload,
    GetTwitchUserAndChannelPayload
} from "./types";
import {
    getTwitchUserAndChannel,
    getTwitchIngestServers,
    searchTwitchCategories,
    updateTwitchChannel,
    getFacebookUserProfile,
    selectFacebookDestination,
    searchFBTags,
    createFacebookStream,
    updateFacebookStream,
    createYouTubeEvent,
    updateYoutubeEvent
} from "./thunks";
import { ChannelSetting } from "views/page-content/platforms/platform/platform-helper";
import { ScheduledOptions } from "views/page-content/platforms/platform/forms/types";
import {
    TwitchIngestServer
    // SwitcherStreamSetting,
    // FaceBookSwitcherStreamSetting,
    // YouTubeSwitcherStreamSetting,
    // TwitchSwitcherStreamSetting
} from "@switcherstudio/switcher-api-client";

export const availableQualities = [
    {
        name: "270p",
        setting: new ChannelSetting(480, 270, 500000, 1, 64000, 48000)
    },
    {
        name: "360p",
        setting: new ChannelSetting(640, 360, 800000, 1, 64000, 48000)
    },
    {
        name: "540p",
        setting: new ChannelSetting(960, 540, 1200000, 2, 128000, 48000)
    },
    {
        name: "720p",
        setting: new ChannelSetting(1280, 720, 3000000, 2, 128000, 48000)
    },
    {
        name: "1080p",
        setting: new ChannelSetting(1920, 1080, 6000000, 2, 128000, 48000)
    }
];

export const platforms = createSlice({
    name: "platforms",
    initialState: {
        general: {
            formState: ScheduledOptions.Now,
            availableQualities: availableQualities,
            selectedStreams: {
                facebook: null,
                youtube: null
            },
            generalForm: {
                title: "",
                description: "",
                enableRecording: false,
                selectedQuality: availableQualities[3],
                scheduledStartTime: new Date(),
                scheduledImageFile: undefined as File,
                scheduledImageFileUrl: "",
                isInvalid: false,
                selectedSimulcastDestinations: []
            }
        },
        facebook: {
            profileInfo: {
                id: "",
                name: "",
                picture: {
                    data: {
                        height: -1,
                        is_silhouette: false,
                        url: "",
                        width: -1
                    }
                },
                edge: "me"
            },
            selectedDestination: {
                id: "-1",
                edge: "me"
            } as FacebookDestination,
            facebookForms: {},
            contentTags: [] as Array<FacebookContentTag>,
            commentModerationOptions: [
                {
                    id: "FOLLOWER",
                    name: "Follower",
                    description:
                        "Only your followers will be able to leave comments."
                },
                {
                    id: "SLOW",
                    name: "Slow",
                    description:
                        "Commentors will only be able to leave a comment every 10 seconds"
                },
                {
                    id: "DISCUSSION",
                    name: "Discussion",
                    description:
                        "Only comments over 100 characters will be shown."
                },
                {
                    id: "RESTRICTED",
                    name: "Restricted",
                    description:
                        "Commentors must have accounts that are at least 2 weeks old."
                }
            ],
            postOptions: [
                // change name/description to key for translations
                {
                    type: "me",
                    name: "My Timeline",
                    permissions: ["publish_video"]
                },
                {
                    type: "accounts",
                    name: "Page",
                    permissions: [
                        "publish_video",
                        "pages_show_list",
                        "pages_messaging",
                        "pages_read_user_content"
                    ]
                }
            ],
            privacyOptions: [
                { value: "EVERYONE", name: "Everyone" },
                { value: "ALL_FRIENDS", name: "Friends" },
                { value: "SELF", name: "Only Me" }
            ]
        },
        twitch: {
            channelInfo: {},
            user: {},
            ingestServers: [],
            games: [],
            twitchForm: {
                delay: 0,
                selectedGame: "",
                ingestServer: null
            }
        },
        youtube: {
            youtubeForm: {
                isForKids: false,
                allowEmbedding: false,
                selectedPrivacy: {
                    name: "",
                    value: ""
                }
            }
        },
        videoplayer: {}
    } as PlatformState,
    reducers: {
        resetGeneralForm: (state) => {
            state.general.formState = ScheduledOptions.Now;
            state.general.generalForm = {
                title: "",
                description: "",
                selectedQuality: { ...state.general.availableQualities[3] },
                scheduledStartTime: new Date(),
                scheduledImageFile: undefined as File,
                scheduledImageFileUrl: "",
                isInvalid: false,
                selectedSimulcastDestinations: [],
                enableRecording: false
            };
        },
        updateFormState: (
            state,
            { payload }: PayloadAction<ScheduledOptions>
        ) => {
            state.general.formState = payload;
        },
        updateSelectedStream: (
            state,
            { payload }: PayloadAction<UpdateSelectedStreamPayload>
        ) => {
            state.general.selectedStreams = {
                ...state.general.selectedStreams,
                [payload.id]: payload.stream
            };
        },
        updateGeneralForm: (
            state,
            { payload }: PayloadAction<Partial<GeneralPlatformForm>>
        ) => {
            state.general.generalForm = {
                ...state.general.generalForm,
                ...payload
            };
        },
        updateQuality: (
            state,
            {
                payload
            }: PayloadAction<{ name: string; setting: ChannelSetting }>
        ) => {
            state.general.generalForm.selectedQuality = payload;
        },
        resetFacebookForm: (state, { payload }) => {
            state.general.generalForm.selectedQuality =
                state.general.availableQualities[3];
            state.facebook.facebookForms[payload] = {
                enableModeration: false,
                enableCrossposting: false,
                enableBrandedContent: false,
                selectedCrosspostingOptions: [] as Array<any>,
                selectedLocation: [] as Array<any>,
                selectedSponsor: [] as Array<any>,
                brandedContentSelection: "",
                privacy: state.facebook.privacyOptions[0],
                selectedCommentModerationOptions: [],
                selectedTags: [] as Array<FacebookContentTag>
            };
        },
        setFacebookContentTags: (
            state,
            { payload }: PayloadAction<Array<FacebookContentTag>>
        ) => {
            state.facebook.contentTags = payload;
        },
        updateFacebookForm: (
            state,
            {
                payload
            }: PayloadAction<{ [destinationId: string]: Partial<FacebookForm> }>
        ) => {
            const destId = Object.keys(payload)[0];
            const updated = {
                ...state.facebook.facebookForms[destId],
                ...payload[destId]
            };

            state.facebook.facebookForms = {
                ...state.facebook.facebookForms,
                [destId]: updated
            };
        },
        updateVideoPlayerForm: (
            state,
            { payload }: PayloadAction<Partial<VideoPlayerForm>>
        ) => {
            state.videoplayer.videoPlayerForm = {
                ...state.videoplayer.videoPlayerForm,
                ...payload
            };
        },
        resetVideoPlayerForm: (
            state,
            { payload }: PayloadAction<Partial<VideoPlayerForm>>
        ) => {
            const defaults = {
                enableLiveShopping: false,
                playlistIds: [],
                creatorProductId: null
            };
            state.videoplayer.videoPlayerForm = {
                ...defaults,
                ...payload
            };
        },
        resetTwitchForm: (state) => {
            state.general.generalForm.selectedQuality =
                state.general.availableQualities[3];

            state.twitch.twitchForm = {
                delay: 0,
                selectedGame: !!state.twitch.channelInfo
                    ? state.twitch.channelInfo.game_id
                    : "",
                ingestServer: !!state.twitch.ingestServers.length
                    ? state.twitch.ingestServers[0]
                    : null
            };
        },
        updateTwitchForm: (
            state: PlatformState,
            { payload }: PayloadAction<Partial<TwitchForm>>
        ) => {
            state.twitch.twitchForm = {
                ...state.twitch.twitchForm,
                ...payload
            };
        },
        resetYoutubeForm: (state: PlatformState) => {
            state.general.generalForm.selectedQuality =
                state.general.availableQualities[3];

            state.youtube.youtubeForm = {
                isForKids: false,
                allowEmbedding: false,
                selectedPrivacy: {
                    name: "",
                    value: ""
                }
            };
        },
        updateYoutubeForm: (
            state: PlatformState,
            { payload }: PayloadAction<Partial<YouTubeForm>>
        ) => {
            state.youtube.youtubeForm = {
                ...state.youtube.youtubeForm,
                ...payload
            };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(
            getFacebookUserProfile.fulfilled,
            (state, { payload }: PayloadAction<FacebookProfile>) => {
                state.facebook.profileInfo = payload;
            }
        );

        builder.addCase(
            selectFacebookDestination.fulfilled,
            (state, { payload }: PayloadAction<FacebookDestination>) => {
                state.facebook.selectedDestination = payload;
            }
        );

        // builder.addCase(
        //     createFacebookStream.fulfilled,
        //     (state, { payload }: PayloadAction<SwitcherStreamSetting>) => {}
        // );

        // builder.addCase(
        //     updateFacebookStream.fulfilled,
        //     (
        //         state,
        //         { payload }: PayloadAction<FaceBookSwitcherStreamSetting>
        //     ) => {}
        // );

        // builder.addCase(
        //     createYouTubeEvent.fulfilled,
        //     (
        //         state,
        //         { payload }: PayloadAction<YouTubeSwitcherStreamSetting>
        //     ) => {}
        // );

        // builder.addCase(
        //     updateYoutubeEvent.fulfilled,
        //     (state, { payload }: PayloadAction<void>) => {}
        // );

        builder.addCase(
            searchFBTags.fulfilled,
            (state, { payload }: PayloadAction<Array<FacebookContentTag>>) => {
                state.facebook.contentTags = payload;
            }
        );

        builder.addCase(
            getTwitchUserAndChannel.fulfilled,
            (
                state,
                { payload }: PayloadAction<GetTwitchUserAndChannelPayload>
            ) => {
                state.twitch.user = payload.user;
                state.twitch.channelInfo = payload.channelInfo;
                state.general.generalForm.title = payload.channelInfo.title;
            }
        );

        builder.addCase(
            getTwitchIngestServers.fulfilled,
            (state, { payload }: PayloadAction<Array<TwitchIngestServer>>) => {
                state.twitch.ingestServers = payload;
                state.twitch.twitchForm.ingestServer = payload[0];
            }
        );

        builder.addCase(
            searchTwitchCategories.fulfilled,
            (state, { payload }: PayloadAction<Array<TwitchCategory>>) => {
                state.twitch.games = payload;
            }
        );

        // builder.addCase(
        //     updateTwitchChannel.fulfilled,
        //     (
        //         state,
        //         { payload }: PayloadAction<TwitchSwitcherStreamSetting>
        //     ) => {}
        // );

        builder.addCase(getFacebookUserProfile.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(selectFacebookDestination.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(createFacebookStream.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(updateFacebookStream.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(createYouTubeEvent.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(updateYoutubeEvent.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(searchFBTags.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(getTwitchUserAndChannel.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(getTwitchIngestServers.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(searchTwitchCategories.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(updateTwitchChannel.rejected, (_, { error }) => {
            throw error;
        });
    }
});

export const {
    resetGeneralForm,
    updateSelectedStream,
    updateGeneralForm,
    updateFormState,
    updateQuality,
    resetFacebookForm,
    setFacebookContentTags,
    updateFacebookForm,
    resetTwitchForm,
    updateTwitchForm,
    resetYoutubeForm,
    updateYoutubeForm,
    updateVideoPlayerForm,
    resetVideoPlayerForm
} = platforms.actions;

export default platforms.reducer as Reducer<PlatformState>;
