import {
    CreatorProduct,
    CreatorProductPrice,
    VideoPlayerResponse
} from "@switcherstudio/switcher-api-client";
import { useCallback, useMemo } from "react";
import styles from "../index.module.scss";
import PencilIcon from "assets/icons/pencil-simple.svg?react";
import { useTranslation } from "react-i18next";
import { PricingModalTypes } from "components/modal/GatedContentModal";
import { GatedContentModalCallbacks } from "../../../../views/page-content/gated-content/PricingDetails/GatedContentTable";
import { displayAmount } from "helpers/stripe";

export interface AttentionModalCellProps extends GatedContentModalCallbacks {
    player: VideoPlayerResponse;
}

export const AttentionModalCell: React.FC<AttentionModalCellProps> = ({
    player,
    updateModal
}) => {
    const { t } = useTranslation();
    const product: CreatorProduct =
        player?.VideoPlayerEntitlements?.ProductEntitlements[0]?.Product;

    const videoPlayerName = useMemo(
        () =>
            player?.VideoPlayer?.Name != null
                ? player?.VideoPlayer?.Name
                : t("players-select-modal:untitled"),
        [t, player]
    );

    const monthlyPrice = useMemo<CreatorProductPrice>(
        () =>
            product?.Prices.find(
                (p) => p.IsRecurring && p.RecurringInterval === "month"
            ),
        [product]
    );

    const yearlyPrice = useMemo<CreatorProductPrice>(
        () =>
            product?.Prices.find(
                (p) => p.IsRecurring && p.RecurringInterval === "year"
            ),
        [product]
    );

    const oneTimePrice = useMemo<CreatorProductPrice>(
        () => product?.Prices.find((p) => !p.IsRecurring),
        [product]
    );

    const price = useMemo<CreatorProductPrice>(
        () => oneTimePrice ?? monthlyPrice ?? yearlyPrice,
        [monthlyPrice, oneTimePrice, yearlyPrice]
    );

    const handleDisplayAmount = useCallback(
        (amount: number) =>
            displayAmount(amount, { signed: true, compact: false }),
        []
    );

    if (!price) return <></>;

    return (
        <tr className={styles["p-row"]}>
            <td className={`${styles["p-td"]}`}>{price.Name}</td>
            <td>{videoPlayerName}</td>
            <td>
                {price.IsRecurring ? (
                    <span>
                        {monthlyPrice && (
                            <p
                                className={`${styles["undo-mb"]}`}
                            >{`${handleDisplayAmount(monthlyPrice.Amount)}/${t(
                                "gated-content-page:month"
                            )}`}</p>
                        )}
                        {yearlyPrice && (
                            <p
                                className={`${styles["undo-mb"]}`}
                            >{`${handleDisplayAmount(yearlyPrice.Amount)}/${t(
                                "gated-content-page:year"
                            )}`}</p>
                        )}
                    </span>
                ) : (
                    handleDisplayAmount(price.Amount)
                )}
            </td>
            <td className={styles["edit-btn"]}>
                <PencilIcon
                    className={styles["p-btn"]}
                    onClick={() =>
                        updateModal(true, PricingModalTypes.Update, product)
                    }
                />
            </td>
        </tr>
    );
};
