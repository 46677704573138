import {
    CreatorProduct,
    CreatorProductPrice
} from "@switcherstudio/switcher-api-client";
import { useCallback, useMemo } from "react";
import styles from "../GatedContentTable.module.scss";
import TrashIcon from "assets/icons/trash-sm.svg?react";
import PencilIcon from "assets/icons/pencil-simple.svg?react";
import { useTranslation } from "react-i18next";
import { PricingModalTypes } from "components/modal/GatedContentModal";
import { GatedContentModalCallbacks } from ".";
import { displayAmount } from "helpers/stripe";
import { useClaimCheck } from "hooks/useClaimCheck";

export interface GatedContentTableCellProps extends GatedContentModalCallbacks {
    product: CreatorProduct;
    subscribers: number;
}

export const GatedContentTableCell: React.FC<GatedContentTableCellProps> = ({
    product,
    subscribers,
    updateModal,
    updateDeleteModal
}) => {
    const { t } = useTranslation();
    const hasCatalogClaim = useClaimCheck("catalog");
    const oneTimePrice = useMemo<CreatorProductPrice>(
        () => product?.Prices.find((p) => !p?.IsRecurring),
        [product]
    );

    const monthlyPrice = useMemo<CreatorProductPrice>(
        () =>
            product?.Prices.find(
                (p) => p?.IsRecurring && p?.RecurringInterval === "month"
            ),
        [product]
    );

    const yearlyPrice = useMemo<CreatorProductPrice>(
        () =>
            product?.Prices.find(
                (p) => p?.IsRecurring && p?.RecurringInterval === "year"
            ),
        [product]
    );

    const price = useMemo<CreatorProductPrice>(
        () => oneTimePrice ?? monthlyPrice ?? yearlyPrice,
        [monthlyPrice, oneTimePrice, yearlyPrice]
    );

    const _displayAmount = useCallback(
        (amount: number) =>
            displayAmount(amount, {
                signed: true,
                compact: false,
                roundUp: false
            }),
        []
    );

    const purchases = useMemo<number>(
        () =>
            product.PriceRevenueSummaryResponses.map((p) =>
                p?.DateSummary.map((p) => p?.Sales ?? 0).reduce(
                    (a, b) => a + b,
                    0
                )
            ).reduce((a, b) => a + b, 0),
        [product]
    );

    const { collectionAssignments, videoAssignemnts } = useMemo(() => {
        return {
            collectionAssignments:
                product.CreatorProductEntitlements?.filter(
                    // @ts-expect-error - Discriminator type is not used in this response
                    (e) => !!e?.VideoPlayerId
                )?.length ?? 0,
            videoAssignemnts:
                product.CreatorProductEntitlements?.filter(
                    // @ts-expect-error - Discriminator type is not used in this response
                    (e) => !!e?.VideoPlayerPlaylistBroadcastId
                )?.length ?? 0
        };
    }, [product]);

    if (!price) return <></>;

    return (
        <tr className={styles["p-row"]}>
            {/* Name */}
            <td className={`${styles["p-td"]}`}>{price.Name}</td>

            {/* Type */}
            <td>
                {price?.IsRecurring
                    ? t("gated-content-page:type-recurring")
                    : t("gated-content-page:type-one-time")}
            </td>

            {/* Price */}
            <td>
                {price?.IsRecurring ? (
                    <span>
                        {monthlyPrice && (
                            <p>{`${_displayAmount(monthlyPrice.Amount)} / ${t(
                                "gated-content-page:month"
                            )}`}</p>
                        )}
                        {yearlyPrice && (
                            <p>{`${_displayAmount(yearlyPrice.Amount)} / ${t(
                                "gated-content-page:year"
                            )}`}</p>
                        )}
                    </span>
                ) : oneTimePrice && oneTimePrice.EntitledDuration > 0 ? (
                    <p>{`${_displayAmount(oneTimePrice.Amount)} / ${
                        oneTimePrice.EntitledDuration
                    } ${t(
                        `gated-content-page:timed-access-units:${
                            oneTimePrice.EntitledDuration === 1
                                ? {
                                      hours: "hour",
                                      days: "day",
                                      weeks: "week"
                                  }[
                                      oneTimePrice.EntitledDurationUnit.toLowerCase()
                                  ]
                                : oneTimePrice.EntitledDurationUnit.toLowerCase()
                        }`
                    )}`}</p>
                ) : (
                    _displayAmount(price?.Amount)
                )}
            </td>

            {/* Assignments */}
            <td>
                {price?.IsRecurring ? (
                    <p>
                        {collectionAssignments === 0
                            ? t("gated-content-page:none")
                            : `${collectionAssignments} ${
                                  collectionAssignments === 1
                                      ? hasCatalogClaim
                                          ? t("gated-content-page:collection")
                                          : t("gated-content-page:player")
                                      : hasCatalogClaim
                                      ? t("gated-content-page:collections")
                                      : t("gated-content-page:players")
                              }`}
                    </p>
                ) : (
                    <p>
                        {collectionAssignments === 0 &&
                        videoAssignemnts === 0 ? (
                            t("gated-content-page:none")
                        ) : (
                            <>
                                {collectionAssignments > 0 && (
                                    <>
                                        {`${collectionAssignments} ${
                                            collectionAssignments === 1
                                                ? t(
                                                      "gated-content-page:collection"
                                                  )
                                                : t(
                                                      "gated-content-page:collections"
                                                  )
                                        }
                                        `}
                                        <br />
                                    </>
                                )}
                                {videoAssignemnts > 0 && (
                                    <>
                                        {`${videoAssignemnts} ${
                                            videoAssignemnts === 1
                                                ? t("gated-content-page:video")
                                                : t("gated-content-page:videos")
                                        }
                                        `}
                                    </>
                                )}
                            </>
                        )}
                    </p>
                )}
            </td>

            {/* Purchases */}
            <td>{purchases}</td>

            {/* Active Subs */}
            <td>{price?.IsRecurring ? subscribers : "N/A"}</td>

            {/* Created At */}
            <td>{new Date(price.CreatedAt).toLocaleDateString()}</td>

            {/* Edit */}
            <td className={styles["edit-btn"]}>
                <PencilIcon
                    className={styles["p-btn"]}
                    onClick={() =>
                        updateModal(true, PricingModalTypes.Update, product)
                    }
                />
            </td>

            {/* Delete */}
            <td>
                <TrashIcon
                    className={styles["p-btn"]}
                    onClick={() => updateDeleteModal(true, product)}
                />
            </td>
        </tr>
    );
};
