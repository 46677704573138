import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { UserFeature, Client } from "@switcherstudio/switcher-api-client";
import { useSwitcherClient } from "./useSwitcherClient";

export function useUserFeatures(featureId?: string) {
    const { userInfo } = useSelector((state: RootState) => state.user);
    const userId = userInfo?.UserId || "";
    const [userFeatures, setUserFeatures] = useState<UserFeature[]>([]);

    const { dispatchApiRequest: getAllFeaturesRequest } = useSwitcherClient(
        (client: Client) => client.userFeatures_GetUserFeatures,
        {
            args: [userId],
            requestImmediately: true,
            onSuccess: (data) => setUserFeatures(data)
        }
    );

    const { dispatchApiRequest: getFeatureByIdRequest } = useSwitcherClient(
        (client: Client) => client.userFeatures_GetUserFeature,
        {
            args: [featureId, userId],
            requestImmediately: !!featureId,
            onSuccess: (data) => setUserFeatures([data])
        }
    );

    const { dispatchApiRequest: updateFeatureRequest } = useSwitcherClient(
        (client: Client) => client.userFeatures_PutUserFeature,
        {
            onError: () => {
                setUserFeatures((prev) =>
                    prev.map((f) =>
                        f.Id === featureId
                            ? { ...f, IsClaimed: !f.IsClaimed }
                            : f
                    )
                );
            }
        }
    );

    const updateFeature = useCallback(
        async (feature: UserFeature) => {
            const updatedFeature = {
                ...feature,
                IsClaimed: !feature.IsClaimed
            };

            setUserFeatures((prev) =>
                prev.map((f) => (f.Id === feature.Id ? updatedFeature : f))
            );

            await updateFeatureRequest([
                updatedFeature.Id || "",
                updatedFeature,
                userId
            ]);
        },
        [updateFeatureRequest, userId]
    );

    const hasFeatures = useMemo(() => {
        return userFeatures.length > 0;
    }, [userFeatures]);

    return {
        userFeatures,
        hasFeatures,
        updateFeature,
        getAllFeaturesRequest,
        getFeatureByIdRequest
    };
}
