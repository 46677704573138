import { AnalyticsDateRangeOptions } from "views/page-content/analytics/AnalyticsPage";

export enum AnalyticsViewsCards {
    Views = "views",
    WatchTime = "watchTime",
    UniqueViewers = "uniqueViewers"
}

export enum AnalyticsRevenuesCards {
    Revenue = "revenue",
    Sales = "sales",
    Customers = "customers"
}

export interface SidebarDropdownSelection {
    videoCMS: boolean;
    creationTools: boolean;
    accountSettings: boolean;
    help: boolean;
}

export interface ViewInitialState {
    analyticsViewsCardOrder: AnalyticsViewsCards[];
    analyticsRevenueCardOrder: AnalyticsRevenuesCards[];
    sidebarDropdownSelection: SidebarDropdownSelection;
    analyticsDateRangeSelection: AnalyticsDateRangeOptions;
    analyticsCustomDateRangeSelection: AnalyticsCustomDateRangeSelection | null;
}

export interface AnalyticsCustomDateRangeSelection {
    start: string | null;
    end: string | null;
}
