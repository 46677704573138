import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { UserFeature } from "@switcherstudio/switcher-api-client";
import { Toggle } from "components/inputs/toggle/Toggle";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { refreshToken, getUserInfo } from "store/user/thunks";
import styles from "./ExperimentalFeaturesPage.module.scss";
import { useTranslation } from "react-i18next";
import { DisabledFeature } from "components/disabled-feature/DisabledFeature";
import { useHasAccess } from "hooks/useHasAccess";
import {
    mustBeSubscriptionOwner,
    useRedirectIfDisallowed
} from "hooks/useRedirectIfDisallowed";
import { useParams } from "hooks/useParams";
import { useUserFeatures } from "hooks/useUserFeatures";

export const ExperimentalFeaturesPage: React.FC = () => {
    useRedirectIfDisallowed(mustBeSubscriptionOwner);
    const { featureId } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const hasAccess = useHasAccess();
    const { userFeatures, updateFeature } = useUserFeatures(featureId);

    const onToggle = useCallback(
        async (feature: UserFeature) => {
            await updateFeature(feature);
            await dispatch(refreshToken());
            await dispatch(getUserInfo());

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: "messages:toggle-success"
                })
            );
        },
        [updateFeature, dispatch]
    );

    return !hasAccess ? (
        <DisabledFeature />
    ) : (
        <section className={`${styles["experimental-features"]}`}>
            <div className="row">
                <div className="alert alert-danger">
                    {t("experimental-features:main-description")}
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <table
                            className={`${styles["experimental-features-table"]}`}
                        >
                            <thead>
                                <tr>
                                    <th>{t("misc:description")}</th>
                                    <th>{t("misc:status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userFeatures.map((f) => {
                                    return (
                                        <tr key={f.Id}>
                                            <td>{f.Description}</td>
                                            <td>
                                                <Toggle
                                                    on={f.IsClaimed ?? false}
                                                    onToggle={() => onToggle(f)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};
