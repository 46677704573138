import { AppDispatch } from "store/store";
import { AspectRatio } from "views/page-content/cloud/upload/types";
import {
    Broadcast,
    BroadcastResponse as FrameworkBroadcastResponse,
    CloudflareVideo,
    CreatorProduct,
    CreatorProductEntitlement,
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductEntitlementsRequest,
    PlaylistItemResponse,
    SwitcherStreamSettingDetailsResponseFacebookEdge,
    VideoPlayer,
    VideoPlayerPlaylistBroadcast,
    VideoPlayerPlaylistBroadcastUpdateRequest,
    WebLinkRequest,
    WebLinkRequestType,
    BroadcastDetailsResponseBroadcastStatus,
    BroadcastStatus,
    SwitcherStreamSetting
} from "@switcherstudio/switcher-api-client";
import {
    BroadcastStatuses,
    BroadcastResponse as CoreBroadcastResponse
} from "@switcherstudio/api-core-client";
import { BroadcastThumbnail } from "components/thumbnails/BroadcastThumbnail";
import { isInFuture } from "helpers/time";
import { BroadcastDetailsForm } from "components/forms/BroadcastDetailsForm";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import rollbar from "helpers/rollbar";
import { useDispatch } from "react-redux";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { VideoPlaybackModal } from "components/modal/VideoPlaybackModal";
import { exists } from "helpers/booleans";
import { useInterval } from "hooks/useInterval";
import { VideoUploadStatus } from "store/uploads/types";
import { useVideoUpload } from "hooks/useVideoUpload";
import { DisabledVariant } from "components/entity-details/BroadcastDetails";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { useThumbnailUpload } from "hooks/useThumbnailUpload";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { Toggle } from "components/inputs/toggle/Toggle";
import { GatedContentStatus } from "hooks/useUserStripeData";
import { useCreatorProductEntitlement } from "hooks/useCreatorProductEntitlement";
import { useLocation } from "react-router-dom";
import { useClaimCheck } from "hooks/useClaimCheck";
import { PricingSelectModal } from "components/modal/PricingSelectModal";
import { PasswordGatingToggle } from "components/inputs/toggle/PasswordGatingToggle";
import { isValidGatedContentPassword } from "helpers/gatedContent";
import { useUserStripeData } from "hooks/useUserStripeData";
import {
    updateScheduledFacebookStream,
    updateScheduledYouTubeStream
} from "store/platforms/thunks";
import { resizeThumbnail } from "../cloud/upload/upload-helpers/thumbnail-helpers";
import { StripeConnectWrapper } from "components/stripe/StripeConnectWrapper";
import { LoadingOverlay } from "components/loading-overlay";
import { isCoreBroadcastResponse } from "helpers/broadcastHelpers";
import { mapBroadcastResponseToBroadcast } from "helpers/mappers/mapBroadcastResponseToBroadcast";
import {
    mapCategoryResponseToCategory,
    mapCategoryToFrameworkCategoryResponse,
    mapCategoryToCoreCategoryResponse
} from "helpers/mappers/mapCategoryResponseToCategory";
import {
    mapBroadcastResponseStatusToBroadcastStatus,
    mapBroadcastStatusToCoreBroadcastStatus,
    mapBroadcastStatusToFrameworkBroadcastStatus
} from "helpers/mappers/mapBroadcastResponseStatusToBroadcastStatus";
import { ExternalDestinations } from "./ExternalDestinations";
import { mapWebLinkResponseToWebLink } from "helpers/mappers/mapWeblinkResponseToWeblink";
import {
    getStreamQualityFromBroadcastResponse,
    getStreamQualityFromSwitcherStreamSettingsResponse,
    StreamQualityOption
} from "helpers/resolution";
import { mapStreamSettingResponseToStreamSetting } from "helpers/mappers/mapStreamSettingResponseToStreamSetting";

export interface VideoDetailsPageProps {
    broadcast: FrameworkBroadcastResponse | CoreBroadcastResponse;
    /** the playable video from Cloudflare - used in the preview */
    video: CloudflareVideo;
    /** used in the video library to manipulate to which collections a video belongs. Not needed on the VideoLibrary and Livestream Details page. Still needed for Collection Videos Details Page. */
    players?: VideoPlayer[];
    /** has the entitlements specific to the broadcast within a collection */
    playlistBroadcast?: PlaylistItemResponse;
    /** refetch that broadcast! */
    refetchBroadcast?: () => void;
    /** for conditional rendering of location-specific components */
    location: "video-library" | "collection";
    /** used to edit the entitlements */
    collectionId?: string;
    /** is the broadcast data loading while returning from the API? */
    broadcastLoading?: boolean;
    /** is the broadcast a premiere? */
    isPremiere?: boolean;
    /** is the broadcast a scheduled livestream? */
    isScheduledLivestream?: boolean;
    disablePlayerSelect?: boolean;
}
export const VideoDetailsPage = ({
    broadcast,
    video,
    players,
    playlistBroadcast,
    refetchBroadcast,
    location,
    collectionId,
    broadcastLoading,
    isPremiere = false,
    isScheduledLivestream = false,
    disablePlayerSelect = false
}: VideoDetailsPageProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const isCoreBroadcast = isCoreBroadcastResponse(broadcast);

    const videoUploadStatus = useMemo(
        () =>
            (broadcast as FrameworkBroadcastResponse)?.Videos?.result?.[0]
                ?.status.state ??
            (broadcast as CoreBroadcastResponse)?.Videos?.[0]?.Details.Status,
        [broadcast]
    );

    const { getUploadStatus } = useVideoUpload(
        broadcast?.Details?.Id,
        videoUploadStatus as VideoUploadStatus
    );

    const { t } = useTranslation();
    const { pathname } = useLocation();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const hasPasswordProtectedContentClaim = useClaimCheck(
        "gatedcontent.password"
    );
    const hasEmailProtectedContentClaim = useClaimCheck("gatedcontent.email");
    const hasGatedContentAccess = useClaimCheck("gatedcontent");

    const [localEntitlement, setLocalEntitlement] =
        useState<CreatorProductEntitlementsRequest>();
    const [pricingSelectModalOpen, setPricingSelectModalOpen] =
        useState<boolean>(false);
    const [isEmailGatingEnabled, setIsEmailGatingEnabled] = useState<boolean>(
        playlistBroadcast?.Details?.IsEmailGatingEnabled
    );
    const productHasEntitlement = useMemo<boolean>(
        () => exists(playlistBroadcast?.Entitlements?.ProductEntitlements),
        [playlistBroadcast?.Entitlements?.ProductEntitlements]
    );
    const [links, setLinks] = useState(broadcast?.WebLinks);
    const [initialLinks, setInitialLinks] = useState(broadcast?.WebLinks);

    const [videoPlaybackModalIsOpen, setVideoPlaybackModalIsOpen] =
        useState(false);

    /** only set existing entitlement if it exists */
    useEffect(() => {
        productHasEntitlement &&
            setExistingEntitlement(
                playlistBroadcast?.Entitlements?.ProductEntitlements?.[0]
            );
    }, [
        productHasEntitlement,
        playlistBroadcast?.Entitlements?.ProductEntitlements
    ]);

    const [existingEntitlement, setExistingEntitlement] =
        useState<CreatorProductEntitlement>(
            playlistBroadcast?.Entitlements?.ProductEntitlements?.[0]
        );

    const [passwordLocal, setPasswordLocal] = useState<string>(
        playlistBroadcast?.Details?.Password
    );

    const [isPasswordGatingEnabled, setIsPasswordGatingEnabled] =
        useState<boolean>(playlistBroadcast?.Details?.IsPasswordGatingEnabled);

    const [hasPasswordValidationError, setHasPasswordValidationError] =
        useState(false);

    const [broadcastStatus, setBroadcastStatus] = useState<
        BroadcastDetailsResponseBroadcastStatus | BroadcastStatuses
    >(broadcast?.Details?.BroadcastStatus);

    const [broadcastStartsAt, setBroadcastStartsAt] = useState<string>(
        broadcast?.Details?.StartsAt
    );

    const [categories, setCategories] = useState(broadcast?.Categories ?? []);

    const [description, setDescription] = useState<string>(
        broadcast?.Details?.Description
    );

    const [streamQuality, setStreamQuality] = useState<StreamQualityOption>(
        getStreamQualityFromBroadcastResponse(
            broadcast as CoreBroadcastResponse
        )
    );

    const [showInCatalog, setShowInCatalog] = useState<boolean>(
        broadcast?.Details?.ShowInCatalog
    );

    const [showCountdownOverThumbnail, setShowCountdownOverThumbnail] =
        useState<boolean>(broadcast?.Details?.ShowPremiereCountdown);

    const [thumbnailFile, setThumbnailFile] = useState<File>();
    /** this is the thumbnail the user has uploaded */
    const [customThumbnailImageURL, setCustomThumbnailImageURL] =
        useState<string>();

    const existingPlayers: string[] = useMemo<string[]>(
        () =>
            isCoreBroadcast
                ? (broadcast as CoreBroadcastResponse)?.Collections?.map(
                      (c) => c.Details.Id
                  )
                : players?.map((c) => c.Id),
        [broadcast, isCoreBroadcast, players]
    );

    const [addToPlayers, setAddToPlayers] = useState<string[]>(existingPlayers);
    interface PlatformDetails {
        Facebook?: {
            edge: SwitcherStreamSettingDetailsResponseFacebookEdge;
            edgeId: string;
            videoId: string;
        };
        YouTube?: string;
        Switcher?: string;
        Other?: string;
    }

    const [platformDetails, setPlatformDetails] = useState<PlatformDetails>({});

    useEffect(() => {
        if (playlistBroadcast?.Details?.IsEmailGatingEnabled !== undefined) {
            setIsEmailGatingEnabled(
                playlistBroadcast?.Details.IsEmailGatingEnabled
            );
        }
    }, [playlistBroadcast]);

    const { addCreatorProductEntitlement, deleteCreatorProductEntitlement } =
        useCreatorProductEntitlement(collectionId, {
            suppressNotifications: true,
            onAdd: () => setExistingEntitlement(null),
            onDelete: () => setExistingEntitlement(null)
        });

    const { dispatchApiRequest: updateStreamSettings } = useSwitcherClient(
        (client) => client.switcherStreamSettings_PutSwitcherStreamSettings,
        {
            requestImmediately: false
        }
    );

    // Fetch stream settings and set platform details in state - for FrameworkResponse only = StreamSettings are available on CoreBroadcastResponse
    const { data: streamSettings, dispatchApiRequest: refetchStreamSettings } =
        useSwitcherClient((client) => client.broadcasts_GetStreamSettings, {
            requestImmediately: true,
            args: [broadcast?.Details?.Id],
            onSuccess: (data) => {
                if (!data?.StreamSettings) return;

                const newPlatforms = data.StreamSettings.reduce(
                    (acc, setting) => {
                        const type =
                            setting?.Details?.SwitcherStreamSettingType;
                        switch (type) {
                            case "SimulcastSwitcherStreamSetting":
                                // ignore this one
                                break;
                            case "FaceBookSwitcherStreamSetting":
                                acc["Facebook"] = {
                                    edge: setting?.Details?.FacebookEdge,
                                    edgeId: setting?.Details?.FacebookEdgeId,
                                    videoId: setting?.Details?.FacebookVideoId
                                };
                                break;
                            case "YouTubeSwitcherStreamSetting":
                                acc["YouTube"] =
                                    setting?.Details?.YouTubeBroadcastId;
                                break;
                            case "VideoPlayerSwitcherStreamSetting":
                                acc["Switcher"] = setting?.Details?.Id;
                                break;
                            default:
                                acc["Other"] = setting?.Details?.Id;
                                break;
                        }
                        return acc;
                    },
                    {} as PlatformDetails
                );

                setStreamQuality(
                    getStreamQualityFromSwitcherStreamSettingsResponse(data)
                );

                setPlatformDetails(newPlatforms);
            }
        });

    const {
        accountData,
        productData,
        loading: stripeDataLoading
    } = useUserStripeData({
        requestImmediately: true,
        includeProducts: true,
        redirectPath: pathname
    });

    const stripeConnected =
        accountData?.gatedContentStatus === GatedContentStatus.READY;
    const userHasPasses = productData?.oneTimeProducts?.length > 0;

    const handlePassAssignment = useCallback(
        async (creatorProduct: CreatorProduct) => {
            if (creatorProduct) {
                // create new product entitlement
                setLocalEntitlement({
                    ProductEntitlements: [
                        {
                            ProductId: creatorProduct.Id,
                            VideoPlayerPlaylistBroadcastId:
                                playlistBroadcast?.Details?.Id,
                            Discriminator:
                                CreatorProductEntitlementsBindingModelDiscriminator._2
                        }
                    ]
                });
            }
            setPricingSelectModalOpen(false);
        },
        [playlistBroadcast]
    );

    const selectPass = useCallback(
        async (selection) => {
            // do nothing if it's the same as what was selected before
            if (
                selection?.Id === existingEntitlement?.Product?.Id ||
                !selection
            ) {
                setLocalEntitlement(selection);
            }

            handlePassAssignment(selection);
        },
        [existingEntitlement, handlePassAssignment]
    );

    const { dispatchApiRequest: getBroadcastWebLinks } = useSwitcherClient(
        (client) => client.webLink_GetBroadcastWebLinks
    );
    const { dispatchApiRequest: postWebLinks } = useSwitcherClient(
        (client) => client.webLink_Post,
        {
            requestImmediately: false
        }
    );

    useEffect(() => {
        if (localEntitlement) {
            setIsEmailGatingEnabled(true);
        } else {
            setIsEmailGatingEnabled(
                playlistBroadcast?.Details?.IsEmailGatingEnabled
            );
        }
    }, [localEntitlement, playlistBroadcast?.Details?.IsEmailGatingEnabled]);

    useEffect(() => {
        if (playlistBroadcast?.Details?.IsPasswordGatingEnabled !== undefined) {
            setIsPasswordGatingEnabled(
                playlistBroadcast?.Details.IsPasswordGatingEnabled
            );
            setPasswordLocal(playlistBroadcast?.Details.Password);
        }
    }, [playlistBroadcast]);

    const validatePassword = useCallback(() => {
        if (
            isPasswordGatingEnabled &&
            !isValidGatedContentPassword(passwordLocal)
        ) {
            setHasPasswordValidationError(true);
            return false;
        } else {
            setHasPasswordValidationError(false);
            return true;
        }
    }, [isPasswordGatingEnabled, passwordLocal]);

    const fetchLinks = useCallback(async () => {
        try {
            const response = await getBroadcastWebLinks([
                [broadcast?.Details?.Id]
            ]);
            setInitialLinks(response);
            setLinks(response);
        } catch (e) {
            rollbar.error(e);
        }
    }, [broadcast, getBroadcastWebLinks]);

    // TODO: Reconcile the success and error states for the client requests on this page
    const { dispatchApiRequest: updatePlaylistBroadcast } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylistBroadcast_UpdateVideoPlayerPlaylistBroadcast,
        {
            requestImmediately: false
        }
    );

    const handleEmailToggleChange = useCallback(() => {
        setIsEmailGatingEnabled((prev) => !prev);
    }, []);

    const showGatingOptions = useMemo(() => {
        return (
            location === "collection" &&
            playlistBroadcast &&
            hasGatedContentAccess
        );
    }, [location, playlistBroadcast, hasGatedContentAccess]);

    /** initialize state once the data exists */
    useEffect(() => {
        if (!!broadcast) {
            setTitle(broadcast?.Details?.Title);
            setDescription(broadcast?.Details?.Description);

            if (isScheduledLivestream) {
                setStreamQuality(
                    getStreamQualityFromBroadcastResponse(
                        broadcast as CoreBroadcastResponse
                    )
                );
            }

            setCustomThumbnailImageURL(
                isCoreBroadcast
                    ? (broadcast as CoreBroadcastResponse)?.Thumbnail?.Details
                          ?.Url
                    : (broadcast as FrameworkBroadcastResponse)?.Thumbnail?.Url
            );
            setCategories(broadcast?.Categories ?? []);
            setBroadcastStatus(broadcast?.Details?.BroadcastStatus);
            setBroadcastStartsAt(broadcast?.Details?.StartsAt);
            setShowInCatalog(broadcast?.Details?.ShowInCatalog);
            setShowCountdownOverThumbnail(
                broadcast?.Details?.ShowPremiereCountdown
            );
            setLinks(broadcast?.WebLinks ?? []);
            setAddToPlayers(existingPlayers);
            setLocalEntitlement(
                productHasEntitlement
                    ? {
                          ProductEntitlements: [
                              {
                                  ProductId:
                                      playlistBroadcast?.Entitlements
                                          ?.ProductEntitlements?.[0]?.ProductId,
                                  VideoPlayerPlaylistBroadcastId:
                                      playlistBroadcast?.Details?.Id,
                                  Discriminator:
                                      CreatorProductEntitlementsBindingModelDiscriminator._2
                              }
                          ]
                      }
                    : undefined
            );
            fetchLinks();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [broadcast, existingPlayers]);

    useEffect(() => {
        setStreamQuality(
            isCoreBroadcast
                ? getStreamQualityFromBroadcastResponse(
                      broadcast as CoreBroadcastResponse
                  )
                : getStreamQualityFromSwitcherStreamSettingsResponse(
                      streamSettings
                  )
        );
    }, [broadcast, isCoreBroadcast, streamSettings]);

    /** this is the thumbnail automatically chosen on upload */
    const activeThumbnailImageURL = useMemo(() => {
        if (isCoreBroadcast) {
            const _broadcast = broadcast as CoreBroadcastResponse;

            return (
                _broadcast?.Thumbnail?.Details?.Url ??
                _broadcast?.Videos?.[0]?.Details?.Thumbnail
            );
        } else {
            const _broadcast = broadcast as FrameworkBroadcastResponse;

            return (
                _broadcast?.Thumbnail?.Url ??
                _broadcast?.Videos?.result?.[0]?.thumbnail
            );
        }
    }, [broadcast, isCoreBroadcast]);

    const [thumbnailRemoved, setThumbnailRemoved] = useState<boolean>(false);
    const [timerEndTime, setTimerEndTime] = useState<Date>(
        new Date(broadcast?.Details?.StartsAt)
    );

    const [timeRemaining, setTimeRemaining] = useState<number>(
        isInFuture(timerEndTime)
            ? timerEndTime?.getTime() - new Date().getTime()
            : 0
    );

    const [title, setTitle] = useState<string>(broadcast?.Details?.Title);
    const { handleUpdateThumbnail, handleDeleteThumbnail } = useThumbnailUpload(
        thumbnailFile,
        customThumbnailImageURL
    );

    const isLive = useMemo<boolean>(() => {
        return (
            (broadcast?.Details?.InputId !== null &&
                broadcast?.Details?.ActiveAt === null) ||
            broadcast?.Details?.EndedAt === null
        );
    }, [broadcast]);

    // This will initialize the timer for the scheduled event when timerEndTime is set.
    useInterval(
        () => {
            calcTimeRemaining(timerEndTime);
        },
        timeRemaining > 0 ? 1000 : null
    );

    // Used to trigger refreshing some data (IE: thumbnails)
    // when the when the scheduled event timer reaches 0.
    useEffect(() => {
        if (timeRemaining >= 0) return;

        refetchBroadcast();
    }, [refetchBroadcast, timeRemaining]);

    // This is used to calculate the time remaining for the scheduled event timer.
    const calcTimeRemaining = useCallback((timeEnd: Date) => {
        if (!timeEnd) return;
        const now = new Date().getTime();
        const distance = timeEnd.getTime() - now;
        setTimeRemaining(distance > 0 ? distance : 0);
    }, []);

    // Initial run of calcuation before interval begins.
    useEffect(() => {
        calcTimeRemaining(timerEndTime);
    }, [calcTimeRemaining, timerEndTime]);

    useEffect(() => {
        const isScheduled = isPremiere || isScheduledLivestream;
        if (isScheduled && isInFuture(broadcast?.Details?.StartsAt)) {
            setTimerEndTime(new Date(broadcast?.Details?.StartsAt));
        }
    }, [isScheduledLivestream, isPremiere, broadcast?.Details?.StartsAt]);

    const saveLinks = useCallback((links) => {
        setLinks(links);
    }, []);

    const { dispatchApiRequest: putBroadcast } = useSwitcherClient(
        (client) => client.broadcasts_PutBroadcast,
        {
            requestImmediately: false
        }
    );

    const { dispatchApiRequest: addToPlayer } = useSwitcherClient(
        (client) => client.videoPlayerPlaylist_PostVideoPlayerPlaylistBroadcast,
        {
            requestImmediately: false
        }
    );

    const { dispatchApiRequest: removeFromPlayer } = useSwitcherClient(
        (client) =>
            client.videoPlayerPlaylist_DeleteVideoPlayerPlaylistBroadcast,
        {
            requestImmediately: false
        }
    );

    const videoState = useMemo<VideoUploadStatus>(() => {
        const isInitialStatusProcessingOrQueued =
            video?.status?.state === VideoUploadStatus.Processing ||
            video?.status?.state === VideoUploadStatus.Queued;
        if (isInitialStatusProcessingOrQueued)
            return getUploadStatus({ broadcastId: broadcast?.Details?.Id });

        return VideoUploadStatus.Success;
    }, [video?.status?.state, getUploadStatus, broadcast?.Details?.Id]);

    const videoIsProcessing = useMemo<boolean>(
        () => videoState !== VideoUploadStatus.Success,
        [videoState]
    );

    const { dispatchApiRequest: getVideoPlayer, loading: videoPlayerLoading } =
        useSwitcherClient((client) => client.videoPlayers_GetVideoPlayer, {
            requestImmediately: false
        });

    /**
     * Filter out specific error messages from third party streaming platforms
     */
    const filterThirdPartyStreamError = useCallback(
        (error: string) => {
            const genericErrorMessage = t("playlist-page:edit-error");

            // Do not show Facebook errors targeted at developers
            const facebookDevErrorRegex = /developers.facebook.com/;
            if (facebookDevErrorRegex.test(error)) {
                return genericErrorMessage;
            }

            // Do not show platform errors that are longer than 300 characters
            const maxErrorLength = 300;
            if (error.length > maxErrorLength) {
                return genericErrorMessage;
            }

            // If we've made it this far, allow the original error through
            return error;
        },
        [t]
    );

    const updateThirdPartyStreams = useCallback(
        async (
            oldBroadcast: Broadcast,
            newBroadcast: Broadcast,
            thumbnailFile?: File
        ): Promise<{ success: boolean; error?: string }> => {
            const errorTable = {
                "cannot-update-start-for-completed": t(
                    "errors:cannot-update-start-for-completed"
                ),
                "no-broadcast-found": t("errors:no-broadcast-found")
            };

            try {
                // Only "Ready" status is eligible for updating third party streams
                if (oldBroadcast.BroadcastStatus !== BroadcastStatus._1)
                    return { success: true };

                const streamNeedsToBeUpdated =
                    oldBroadcast.Title !== title ||
                    oldBroadcast.Description !== description ||
                    oldBroadcast.StartsAt !== broadcastStartsAt ||
                    (!!oldBroadcast.ThumbnailAssetId && thumbnailRemoved) ||
                    thumbnailFile !== undefined;

                if (!streamNeedsToBeUpdated) return { success: true };

                let scheduledStartTimeShouldUpdate =
                    oldBroadcast.StartsAt !== broadcastStartsAt;

                const thirdPartyUpdatePromises = [];

                // Resize the thumbnail to meet size and aspect ratio requirements before updating third party streams
                if (thumbnailFile) {
                    const { file: resizedFile } = await resizeThumbnail(
                        thumbnailFile
                    );
                    thumbnailFile = resizedFile;
                }

                if (platformDetails.Facebook) {
                    thirdPartyUpdatePromises.push(
                        dispatch(
                            updateScheduledFacebookStream({
                                facebookVideoId:
                                    platformDetails.Facebook.videoId,
                                facebookEdgeId: platformDetails.Facebook.edgeId,
                                newBroadcast,
                                scheduledStartTimeShouldUpdate
                            })
                        )
                            .unwrap()
                            .catch((e) => {
                                const message =
                                    errorTable[e.message] || e.message;

                                return {
                                    error: t(
                                        "errors:platform-specific-edit-livestream-error",
                                        {
                                            platform: "Facebook",
                                            message: message
                                        }
                                    )
                                };
                            })
                    );
                }
                if (platformDetails.YouTube) {
                    thirdPartyUpdatePromises.push(
                        dispatch(
                            updateScheduledYouTubeStream({
                                youtubeBroadcastId: platformDetails.YouTube,
                                newBroadcast,
                                scheduledStartTimeShouldUpdate,
                                thumbnailFile
                            })
                        )
                            .unwrap()
                            .catch((e) => {
                                const message =
                                    errorTable[e.message] || e.message;
                                return {
                                    error: t(
                                        "errors:platform-specific-edit-livestream-error",
                                        {
                                            platform: "YouTube",
                                            message: message
                                        }
                                    )
                                };
                            })
                    );
                }

                const thirdPartyUpdates = await Promise.allSettled(
                    thirdPartyUpdatePromises
                );

                const combinedErrors = thirdPartyUpdates
                    .map((u) => (u?.status === "fulfilled" ? u.value : null))
                    .filter((u) => !!u)
                    .reduce((acc, curr, i) => {
                        if (curr?.error) {
                            const filteredError = filterThirdPartyStreamError(
                                curr.error
                            );
                            acc += filteredError;
                        }
                        if (
                            thirdPartyUpdates.length > 1 &&
                            i < thirdPartyUpdates.length - 1
                        )
                            acc += "\\n";
                        return acc;
                    }, "");

                // If there are no errors, return success object
                if (!combinedErrors) return { success: true };

                // If there are errors, log and return failure object
                rollbar.error("Error updating third party streams", {
                    combinedErrors,
                    platformDetails,
                    oldBroadcast,
                    newBroadcast
                });

                return {
                    success: false,
                    error: combinedErrors
                };
            } catch (e) {
                // If something fails unexpectedly, log it
                rollbar.error("Unknown error updating third party streams", e);

                // Err on the side of allowing the update to continue
                return {
                    success: true
                };
            }
        },
        [
            platformDetails,
            title,
            description,
            broadcastStartsAt,
            thumbnailRemoved,
            dispatch,
            t,
            filterThirdPartyStreamError
        ]
    );

    /** the Big Mama, who updates all Broadcast values */
    const handleSubmit = useCallback(async () => {
        if (!validatePassword()) {
            return;
        }

        setIsSubmitting(true);
        const promises = [];

        const webLinkRequest: WebLinkRequest = {
            Id: broadcast?.Details?.Id,
            webLinks: links?.map(mapWebLinkResponseToWebLink) ?? [],
            Type: WebLinkRequestType.Broadcast
        };

        promises.push(postWebLinks([webLinkRequest]));

        const _broadcast = mapBroadcastResponseToBroadcast(broadcast);

        try {
            let updatedBroadcast: Broadcast = {
                ..._broadcast,
                Title: title,
                Description: description,
                Categories:
                    categories?.map(mapCategoryResponseToCategory) ?? [],
                ShowInCatalog: showInCatalog,
                ShowPremiereCountdown: showCountdownOverThumbnail,
                BroadcastStatus:
                    mapBroadcastResponseStatusToBroadcastStatus(
                        broadcastStatus
                    ),
                StartsAt: broadcastStartsAt
                    ? new Date(broadcastStartsAt).toISOString()
                    : new Date().toISOString()
            };

            if (exists(broadcastStartsAt)) {
                updatedBroadcast.ActiveAt = new Date(
                    broadcastStartsAt
                ).toISOString();
            } else {
                updatedBroadcast.ActiveAt = new Date().toISOString();
            }

            if (video?.duration) {
                if (exists(broadcastStartsAt)) {
                    updatedBroadcast.EndedAt = new Date(
                        Math.round(
                            (new Date(broadcastStartsAt).getTime() +
                                video?.duration * 1000) /
                                1000
                        ) * 1000
                    ).toISOString();
                } else {
                    updatedBroadcast.EndedAt = new Date(
                        Math.round(
                            (new Date().getTime() + video?.duration * 1000) /
                                1000
                        ) * 1000
                    ).toISOString();
                }
            }

            if (isScheduledLivestream && streamQuality) {
                let updatedStreamSettings: SwitcherStreamSetting[];

                if (isCoreBroadcast) {
                    updatedStreamSettings =
                        updatedBroadcast.SwitcherStreamSettings.map(
                            (setting): SwitcherStreamSetting => {
                                return {
                                    ...setting,
                                    ...streamQuality.setting
                                };
                            }
                        );
                } else {
                    updatedStreamSettings = streamSettings.StreamSettings.map(
                        (setting): SwitcherStreamSetting => {
                            return {
                                ...mapStreamSettingResponseToStreamSetting(
                                    setting
                                ),
                                ...streamQuality.setting
                            };
                        }
                    );
                }

                await updateStreamSettings([updatedStreamSettings]);
            }

            if (thumbnailFile) {
                const thumbnail = await handleUpdateThumbnail(
                    broadcast?.Details
                );

                updatedBroadcast.ThumbnailAssetId = thumbnail.Id;
                updatedBroadcast.ThumbnailAsset = thumbnail;
            } else if (thumbnailRemoved) {
                handleDeleteThumbnail(broadcast?.Details?.ThumbnailAssetId);
                updatedBroadcast.ThumbnailAsset = undefined;
                updatedBroadcast.ThumbnailAssetId = undefined;
            }

            const thirdPartyUpdates = await updateThirdPartyStreams(
                mapBroadcastResponseToBroadcast(broadcast),
                updatedBroadcast,
                thumbnailFile
            );

            if (!thirdPartyUpdates.success) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message:
                            thirdPartyUpdates.error.length > 0
                                ? thirdPartyUpdates.error
                                : t("playlist-page:edit-error")
                    })
                );
            }

            if (!!addToPlayers) {
                for (const player of addToPlayers) {
                    if (!existingPlayers.includes(player)) {
                        const playerResponse = await getVideoPlayer([player]);
                        const playlist =
                            playerResponse.VideoPlayer
                                .VideoPlayerPlaylists?.[0];
                        promises.push(
                            addToPlayer([
                                playlist.Id,
                                {
                                    PlaylistBroadcast: {
                                        BroadcastId: broadcast?.Details?.Id,
                                        VideoPlayerPlaylistId: playlist?.Id
                                    }
                                }
                            ])
                        );
                    }
                }
            }

            if (!!existingPlayers) {
                for (const player of existingPlayers) {
                    if (!addToPlayers.includes(player)) {
                        const playerResponse = await getVideoPlayer([player]);
                        const playlist =
                            playerResponse.VideoPlayer
                                .VideoPlayerPlaylists?.[0];
                        promises.push(
                            removeFromPlayer([
                                playlist.Id,
                                playlist.VideoPlayerPlaylistBroadcasts.find(
                                    (pb) =>
                                        pb.BroadcastId ===
                                        broadcast?.Details?.Id
                                )
                            ])
                        );
                    }
                }
            }

            if (playlistBroadcast && location === "collection") {
                const updatedPlaylistBroadcast: VideoPlayerPlaylistBroadcast = {
                    VideoPlayerPlaylistId:
                        playlistBroadcast?.Details?.VideoPlayerPlaylistId,
                    BroadcastId: playlistBroadcast?.Details?.BroadcastId,
                    IsEmailGatingEnabled: isEmailGatingEnabled,
                    IsPasswordGatingEnabled: isPasswordGatingEnabled,
                    OrdinalRank: {
                        Ordinal: playlistBroadcast?.Details?.Ordinal,
                        Rank: playlistBroadcast?.Details?.Rank
                    },
                    Password: passwordLocal,
                    Id: playlistBroadcast?.Details?.Id,
                    CreatedAt: playlistBroadcast?.Details?.CreatedAt,
                    UpdatedAt: playlistBroadcast?.Details?.UpdatedAt
                };

                const updateRequest: VideoPlayerPlaylistBroadcastUpdateRequest =
                    {
                        PlaylistBroadcast: updatedPlaylistBroadcast
                    };

                promises.push(
                    updatePlaylistBroadcast([
                        updateRequest,
                        playlistBroadcast?.Details?.Id
                    ])
                );
            }

            promises.push(
                putBroadcast([
                    broadcast?.Details?.Id,
                    updatedBroadcast,
                    false,
                    false
                ])
            );

            if (localEntitlement) {
                await addCreatorProductEntitlement([localEntitlement]);
            } else if (!!existingEntitlement) {
                await deleteCreatorProductEntitlement([
                    existingEntitlement?.Id
                ]);
            }

            await Promise.all(promises);

            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: t("playlist-page:edit-success")
                })
            );

            // clear thumbnail state
            setThumbnailFile(undefined);
            setThumbnailRemoved(false);

            refetchBroadcast();
            refetchStreamSettings();
        } catch (e) {
            rollbar.error("Error editing broadcast", e);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("playlist-page:edit-error")
                })
            );
        } finally {
            setIsSubmitting(false);
        }
    }, [
        validatePassword,
        broadcast,
        links,
        postWebLinks,
        title,
        description,
        categories,
        showInCatalog,
        showCountdownOverThumbnail,
        broadcastStatus,
        broadcastStartsAt,
        video?.duration,
        isScheduledLivestream,
        streamQuality,
        thumbnailFile,
        thumbnailRemoved,
        updateThirdPartyStreams,
        addToPlayers,
        existingPlayers,
        playlistBroadcast,
        location,
        putBroadcast,
        localEntitlement,
        existingEntitlement,
        dispatch,
        t,
        refetchBroadcast,
        refetchStreamSettings,
        isCoreBroadcast,
        updateStreamSettings,
        streamSettings,
        handleUpdateThumbnail,
        handleDeleteThumbnail,
        getVideoPlayer,
        addToPlayer,
        removeFromPlayer,
        isEmailGatingEnabled,
        isPasswordGatingEnabled,
        passwordLocal,
        updatePlaylistBroadcast,
        addCreatorProductEntitlement,
        deleteCreatorProductEntitlement
    ]);

    /** check if any fields on the broadcast form have changed */
    const hasChanges = useMemo(() => {
        if (!broadcast) return false;

        const broadcastIsInitialized =
            !!title ||
            !!description ||
            !!showInCatalog ||
            !!showCountdownOverThumbnail ||
            !!broadcastStatus ||
            !!broadcastStartsAt ||
            !!streamQuality;

        const weblinksAreInitialized = links !== undefined;
        const initialLinksAreInitialized = initialLinks !== undefined;

        if (
            !broadcastIsInitialized ||
            !weblinksAreInitialized ||
            !initialLinksAreInitialized
        ) {
            return false;
        }

        const broadcastHasChanges =
            broadcast?.Details.Title !== title ||
            broadcast?.Details.Description !== description ||
            broadcast?.Details.ShowInCatalog !== showInCatalog ||
            broadcast?.Details.ShowPremiereCountdown !==
                showCountdownOverThumbnail ||
            broadcast?.Details.BroadcastStatus !== broadcastStatus ||
            broadcast?.Details.StartsAt !== broadcastStartsAt;

        const streamQualityHasChanges = isCoreBroadcast
            ? streamQuality !==
              getStreamQualityFromBroadcastResponse(
                  broadcast as CoreBroadcastResponse
              )
            : streamQuality !==
              getStreamQualityFromSwitcherStreamSettingsResponse(
                  streamSettings
              );

        const thumbnailHasChanges =
            (!!broadcast?.Details?.ThumbnailAssetId && thumbnailRemoved) ||
            thumbnailFile !== undefined;

        const categoriesHaveChanges =
            JSON.stringify(broadcast?.Categories) !==
            JSON.stringify(categories);

        const playersHaveChanges =
            JSON.stringify(existingPlayers) !== JSON.stringify(addToPlayers);

        const emailGatingHasChanges =
            hasEmailProtectedContentClaim &&
            location === "collection" &&
            isEmailGatingEnabled !==
                playlistBroadcast?.Details?.IsEmailGatingEnabled;

        const passwordGatingHasChanges =
            hasPasswordProtectedContentClaim &&
            location === "collection" &&
            (isPasswordGatingEnabled !==
                playlistBroadcast?.Details?.IsPasswordGatingEnabled ||
                passwordLocal !== playlistBroadcast?.Details?.Password);

        const hasWeblinkChanges =
            JSON.stringify(links) !== JSON.stringify(initialLinks);

        const passHasChanges =
            existingEntitlement?.ProductId !==
            localEntitlement?.ProductEntitlements?.[0]?.ProductId;

        return (
            broadcastHasChanges ||
            streamQualityHasChanges ||
            thumbnailHasChanges ||
            categoriesHaveChanges ||
            playersHaveChanges ||
            emailGatingHasChanges ||
            passwordGatingHasChanges ||
            hasWeblinkChanges ||
            passHasChanges
        );
    }, [
        broadcast,
        title,
        description,
        showInCatalog,
        showCountdownOverThumbnail,
        broadcastStatus,
        broadcastStartsAt,
        streamQuality,
        links,
        initialLinks,
        isCoreBroadcast,
        streamSettings,
        thumbnailRemoved,
        thumbnailFile,
        categories,
        existingPlayers,
        addToPlayers,
        hasEmailProtectedContentClaim,
        location,
        isEmailGatingEnabled,
        playlistBroadcast,
        hasPasswordProtectedContentClaim,
        isPasswordGatingEnabled,
        passwordLocal,
        existingEntitlement?.ProductId,
        localEntitlement?.ProductEntitlements
    ]);

    /** this needed to be set in state because we don't PUT
     * the new pass til we click the save button, but we need the name
     * to be correct once the user has selected a new pass in the modal */
    const passName = useMemo<string>(() => {
        if (localEntitlement?.ProductEntitlements?.[0] !== undefined) {
            return productData?.oneTimeProducts?.find(
                (otp) =>
                    otp.Id ===
                    localEntitlement?.ProductEntitlements?.[0]?.ProductId
            )?.Name;
        } else if (localEntitlement === undefined) {
            return t("video-player-settings:add-pricing");
        }
        return t("video-player-settings:add-pricing");
    }, [localEntitlement, productData?.oneTimeProducts, t]);

    /** tell the user they've got unsaved changes */
    useBeforeUnload(hasChanges, null, true);

    /** pagewide loading */
    const loading = useMemo(
        () => broadcastLoading || videoPlayerLoading || isSubmitting,
        [broadcastLoading, videoPlayerLoading, isSubmitting]
    );

    return (
        <>
            <LoadingOverlay
                isLoading={loading}
                targetInteractiveCssSelelector={".broadcast-details-container"}
                transparentOnLoad={true}
                inlineLoading={false}
            >
                <div
                    className={`broadcast-details-container ${styles["broadcast-details-container"]}`}
                >
                    <div className={styles["broadcast-details-form-column"]}>
                        <BroadcastDetailsForm
                            broadcastId={broadcast?.Details?.Id}
                            title={title}
                            setTitle={setTitle}
                            description={description}
                            setDescription={setDescription}
                            streamQuality={streamQuality}
                            setStreamQuality={setStreamQuality}
                            setThumbnailFile={setThumbnailFile}
                            thumbnailImageURL={customThumbnailImageURL}
                            setThumbnailImageURL={setCustomThumbnailImageURL}
                            onRemoveThumbnail={() => setThumbnailRemoved(true)}
                            selectedCategories={
                                categories?.map(
                                    mapCategoryResponseToCategory
                                ) ?? []
                            }
                            setSelectedCategories={
                                isCoreBroadcast
                                    ? (selectedCategories) =>
                                          setCategories(
                                              selectedCategories?.map(
                                                  mapCategoryToCoreCategoryResponse
                                              ) ?? []
                                          )
                                    : (selectedCategories) =>
                                          setCategories(
                                              selectedCategories?.map(
                                                  mapCategoryToFrameworkCategoryResponse
                                              ) ?? []
                                          )
                            }
                            broadcastStatus={mapBroadcastResponseStatusToBroadcastStatus(
                                broadcastStatus
                            )}
                            setBroadcastStatus={(status) =>
                                setBroadcastStatus(
                                    isCoreBroadcast
                                        ? mapBroadcastStatusToCoreBroadcastStatus(
                                              status
                                          )
                                        : mapBroadcastStatusToFrameworkBroadcastStatus(
                                              status
                                          )
                                )
                            }
                            startsAt={broadcastStartsAt}
                            setStartsAt={setBroadcastStartsAt}
                            links={
                                links?.map(mapWebLinkResponseToWebLink) ?? []
                            }
                            onLinkSave={saveLinks}
                            liveBroadcast={isLive}
                            showThumbnailUploader
                            showCategories
                            showInCatalog={showInCatalog}
                            setShowInCatalog={setShowInCatalog}
                            isUpload={false}
                            orientation={
                                video?.input?.width >= video?.input?.height
                                    ? AspectRatio.horizontal
                                    : AspectRatio.vertical
                            }
                            players={addToPlayers}
                            setAddToPlayers={setAddToPlayers}
                            location={"video-details"}
                            isPremiere={isPremiere}
                            isScheduledLivestream={isScheduledLivestream}
                            showCountdownOverThumbnail={
                                showCountdownOverThumbnail
                            }
                            setShowCountdownOverThumbnail={
                                setShowCountdownOverThumbnail
                            }
                            showCategoryDropdownWhenEmpty={true}
                            disablePlayerSelect={disablePlayerSelect}
                        />
                        {showGatingOptions && (
                            <StripeConnectWrapper
                                inlineLoading
                                loading={stripeDataLoading}
                                gatedContentStatus={
                                    accountData?.gatedContentStatus
                                }
                                href={accountData?.link?.href}
                                showLinkToManageGatedContentPasses={
                                    !userHasPasses
                                }
                            >
                                <div
                                    className={
                                        styles["gating-options-container"]
                                    }
                                >
                                    {" "}
                                    <h6
                                        className={
                                            !stripeConnected
                                                ? styles["remove-mb"]
                                                : ""
                                        }
                                    >
                                        {t("video-details-page:gating-options")}
                                    </h6>
                                    {/* 3 potential states.*/}
                                    {hasPasswordProtectedContentClaim && (
                                        <PasswordGatingToggle
                                            isEnabled={isPasswordGatingEnabled}
                                            setIsEnabled={
                                                setIsPasswordGatingEnabled
                                            }
                                            password={passwordLocal}
                                            setPassword={setPasswordLocal}
                                            hasEmailOrPurchaseGating={
                                                isEmailGatingEnabled
                                            }
                                            hasValidationError={
                                                hasPasswordValidationError
                                            }
                                        />
                                    )}
                                    {hasEmailProtectedContentClaim && (
                                        <Toggle
                                            on={isEmailGatingEnabled}
                                            label={t(
                                                "collection-page:toggle-email"
                                            )}
                                            disabled={
                                                videoIsProcessing ||
                                                !!localEntitlement
                                            }
                                            reverseLayout
                                            onToggle={handleEmailToggleChange}
                                        />
                                    )}
                                    <Button
                                        disabled={
                                            accountData?.gatedContentStatus !==
                                            GatedContentStatus.READY
                                        }
                                        type="badge"
                                        isActive={!!localEntitlement}
                                        onClick={() => {
                                            setPricingSelectModalOpen(true);
                                        }}
                                    >
                                        {passName}
                                    </Button>
                                </div>
                            </StripeConnectWrapper>
                        )}
                        {isScheduledLivestream && isCoreBroadcast && (
                            <ExternalDestinations
                                destinations={
                                    (broadcast as CoreBroadcastResponse)
                                        ?.StreamSettings
                                }
                            />
                        )}
                    </div>
                    <div className={styles["broadcast-thumbnail-column"]}>
                        <div
                            className={
                                styles["broadcast-thumbnail-sticky-container"]
                            }
                        >
                            <div className={styles["save-button"]}>
                                <Button
                                    disabled={!hasChanges}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    {t("buttons:save-changes")}
                                </Button>
                            </div>
                            <div className={styles["video-thumbnail"]}>
                                <BroadcastThumbnail
                                    thumbnailImageURL={activeThumbnailImageURL}
                                    allowVideoPlaybackOnThumbnailClick={
                                        !videoIsProcessing
                                    }
                                    handlePlayVideo={() =>
                                        setVideoPlaybackModalIsOpen(true)
                                    }
                                    isScheduledUpload={isPremiere}
                                    isScheduledLive={isScheduledLivestream}
                                    scheduledSecondsRemaining={
                                        timeRemaining ?? 0
                                    }
                                    disabled={videoIsProcessing}
                                    disabledVariant={
                                        videoIsProcessing
                                            ? DisabledVariant.Processing
                                            : DisabledVariant.Disabled
                                    }
                                    showPremiereCountdown={
                                        showCountdownOverThumbnail
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <VideoPlaybackModal
                        broadcastId={broadcast?.Details?.Id}
                        isOpen={videoPlaybackModalIsOpen}
                        setIsOpen={setVideoPlaybackModalIsOpen}
                    />

                    <PricingSelectModal
                        isOpen={pricingSelectModalOpen}
                        setIsOpen={setPricingSelectModalOpen}
                        buttonText={t("buttons:done")}
                        handleSelect={(selection) => selectPass(selection)}
                        products={productData?.oneTimeProducts}
                        selected={
                            localEntitlement?.ProductEntitlements?.[0]
                                ?.ProductId
                        }
                        discriminator={
                            CreatorProductEntitlementsBindingModelDiscriminator._2
                        }
                    />
                </div>
            </LoadingOverlay>
        </>
    );
};
