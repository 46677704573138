import React, { Dispatch, SetStateAction } from "react";
import { ModalBase } from "components/modal/ModalBase";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import {
    Broadcast,
    CloudflareVideo,
    CreatorProductVideoPlayerEntitlement,
    CreatorProductVideoPlayerPlaylistBroadcastEntitlement,
    EntitySummaryResponse,
    VideoPlayer
} from "@switcherstudio/switcher-api-client";
import { BroadcastResponse } from "@switcherstudio/api-core-client";
import { AddVideosLegacy } from "components/add-videos/AddVideosLegacy";
import { AddVideos } from "components/add-videos/AddVideos";

export interface AddVideosModalProps {
    isMultiple: boolean;
    allowAdditional?: boolean;
    onSelect: (broadcastIds: string[]) => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    previouslySelectedBroadcastIds: string[];
    buttonText?: string;
    modalTitle?: string;
    onClose?: (params: any) => any;
    showUnpublished?: boolean;
    playerId: string;
    isOpen: boolean;
    useAddVideosLegacy?: boolean;
}

interface HashBase {
    players?: VideoPlayer[];
    playlistBroadcastEntitlements?: CreatorProductVideoPlayerPlaylistBroadcastEntitlement[];
    playerEntitlements?: CreatorProductVideoPlayerEntitlement[];
    videoResponse: CloudflareVideo;
    subsequentVideoResponses: CloudflareVideo[];
    metrics: EntitySummaryResponse;
    selected: boolean;
    disabled: boolean;
}
export interface BroadcastsHashItem extends HashBase {
    broadcast: Broadcast;
}

export interface BroadcastResponsesHashItem extends HashBase {
    broadcast: BroadcastResponse;
}

export interface BroadcastsHash {
    [key: string]: BroadcastsHashItem;
}

export const AddVideosModal = ({
    playerId,
    isMultiple,
    onSelect,
    isOpen,
    setIsOpen,
    previouslySelectedBroadcastIds,
    buttonText,
    showUnpublished = true,
    onClose,
    modalTitle,
    useAddVideosLegacy = false
}: AddVideosModalProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles["add-videos-modal"]}>
            <ModalBase
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={modalTitle || t("video-player-page:add-videos")}
                onDismiss={() => setIsOpen(false)}
            >
                <div className={styles["multi-select-container"]}>
                    {useAddVideosLegacy ? (
                        <AddVideosLegacy
                            setIsOpen={setIsOpen}
                            isMultiple={isMultiple}
                            buttonText={buttonText}
                            onSelect={onSelect}
                            playerId={playerId}
                            onClose={onClose}
                            previouslySelectedBroadcastIds={
                                previouslySelectedBroadcastIds
                            }
                            showUnpublished={showUnpublished}
                        />
                    ) : (
                        <AddVideos
                            setIsOpen={setIsOpen}
                            onSelect={onSelect}
                            onClose={onClose}
                            previouslySelectedIds={
                                previouslySelectedBroadcastIds
                            }
                        />
                    )}
                </div>
            </ModalBase>
        </div>
    );
};
