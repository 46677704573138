import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDebounce } from "hooks/useDebounce";
import { isInFuture, sortByDate } from "helpers/time";
import { FilterOptions, SearchSortBar, SortOptions } from ".";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { CategoryType } from "@switcherstudio/switcher-api-client";
import { exists } from "helpers/booleans";
import { useTranslation } from "react-i18next";
import { useClaimCheck } from "hooks/useClaimCheck";
import { BroadcastResponse } from "@switcherstudio/api-core-client";
import { SearchSortOptions } from "components/generic-multiselect/types";

export interface BroadcastResponsesSearchSortBarProps {
    broadcasts: BroadcastResponse[];
    handleSort: (broadcasts: BroadcastResponse[]) => void;
    searchSortOptions: SearchSortOptions;
}

export const BroadcastResponsesSearchSortBar = ({
    broadcasts,
    handleSort,
    searchSortOptions: { location, defaultSortOption, hiddenSortOptions }
}: BroadcastResponsesSearchSortBarProps) => {
    const { t } = useTranslation("broadcasts-multi-select");

    const [selectedSortOption, setSelectedSortOption] =
        useState<SortOptions>(defaultSortOption);

    const [selectedCategoryOption, setSelectedCategoryOption] =
        useState<string>("all");

    const [selectedFilterOption, setSelectedFilterOption] = useState<string>(
        FilterOptions.All
    );

    const [query, setQuery] = useState("");
    const debouncedQuery = useDebounce(query, 500);

    const userInfo = useSelector((s: RootState) => s.user?.userInfo);
    const hasCatalogClaim = useClaimCheck("catalog");

    const { data: userCategories, dispatchApiRequest: categoriesRequest } =
        useSwitcherClient((client) => client.categories_GetCategories, {
            requestImmediately: true,
            args: [userInfo?.ProjectId, CategoryType._0]
        });

    const categoryOptions = useMemo(() => {
        const all = {
            value: "all",
            text: hasCatalogClaim
                ? t("broadcasts-multi-select:all-tags")
                : t("broadcasts-multi-select:all-categories")
        };
        return !exists(userCategories)
            ? [all]
            : [
                  all,
                  ...userCategories?.Categories?.map((category) => {
                      return { value: category.Id, text: category.Name };
                  })
              ];
    }, [t, userCategories, hasCatalogClaim]);

    useEffect(() => {
        categoriesRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [broadcasts]);

    const sortedBroadcasts = useMemo(() => {
        if (!broadcasts) return [];
        return [...broadcasts].sort((a, b) => {
            const viewsA = a?.Analytics?.Details?.TotalViews ?? 0;
            const viewsB = b?.Analytics?.Details?.TotalViews ?? 0;

            const broadcastA = a;
            const broadcastB = b;

            switch (selectedSortOption) {
                case SortOptions.NewestFirst:
                    return sortByDate(
                        broadcastA?.Details?.CreatedAt,
                        broadcastB?.Details?.CreatedAt,
                        { descending: true }
                    );
                case SortOptions.OldestFirst:
                    return sortByDate(
                        broadcastA?.Details?.CreatedAt,
                        broadcastB?.Details?.CreatedAt,
                        { descending: false }
                    );

                case SortOptions.Next:
                    return sortByDate(
                        broadcastA?.Details?.StartsAt,
                        broadcastB?.Details?.StartsAt,
                        { descending: false }
                    );
                case SortOptions.Last:
                    return sortByDate(
                        broadcastA?.Details?.StartsAt,
                        broadcastB?.Details?.StartsAt,
                        { descending: true }
                    );
                case SortOptions.TitleAZ:
                    return broadcastA?.Details?.Title.toLocaleLowerCase() >
                        broadcastB?.Details?.Title?.toLocaleLowerCase()
                        ? 1
                        : -1;
                case SortOptions.TitleZA:
                    return broadcastA?.Details?.Title.toLocaleLowerCase() <
                        broadcastB?.Details?.Title?.toLocaleLowerCase()
                        ? 1
                        : -1;
                case SortOptions.MostViews:
                    return viewsA < viewsB ? 1 : -1;
                case SortOptions.LeastViews:
                    return viewsA > viewsB ? 1 : -1;
                default:
                    return sortByDate(
                        broadcastA?.Details?.CreatedAt,
                        broadcastB?.Details?.CreatedAt,
                        { descending: true }
                    );
            }
        });
    }, [broadcasts, selectedSortOption]);

    const filterBroadcasts = useCallback(
        (broadcast: BroadcastResponse) => {
            switch (selectedFilterOption) {
                // case FilterOptions.Gated:
                //     return (
                //         broadcast?.playerEntitlements.length ||
                //         broadcast?.playlistBroadcastEntitlements.length
                //     );

                // case FilterOptions.NotGated:
                //     return (
                //         !broadcast?.playerEntitlements.length &&
                //         !broadcast?.playlistBroadcastEntitlements.length
                //     );
                case FilterOptions.InPlayer:
                    return broadcast?.Collections.length;

                case FilterOptions.NotInPlayer:
                    return !broadcast?.Collections.length;

                case FilterOptions.Shoppable:
                    return (
                        broadcast?.Details?.EnableLiveShopping.valueOf() ===
                        true
                    );

                case FilterOptions.NotShoppable:
                    return (
                        broadcast?.Details?.EnableLiveShopping.valueOf() ===
                        false
                    );

                case FilterOptions.Published:
                    return (
                        broadcast?.Details?.BroadcastStatus !== "Archived" &&
                        !(
                            isInFuture(broadcast?.Details?.StartsAt) ||
                            broadcast?.Details?.ActiveAt === null
                        )
                    );

                case FilterOptions.Unpublished:
                    return broadcast?.Details?.BroadcastStatus === "Archived";

                case FilterOptions.Scheduled:
                    return (
                        isInFuture(broadcast?.Details?.StartsAt) ||
                        broadcast?.Details?.ActiveAt === null
                    );

                default:
                    return true;
            }
        },
        [selectedFilterOption]
    );

    const _broadcasts = useMemo(() => {
        const broadcasts = sortedBroadcasts;

        if (
            !debouncedQuery &&
            selectedCategoryOption === "all" &&
            selectedFilterOption === FilterOptions.All
        ) {
            return broadcasts;
        }

        return broadcasts.filter(
            (b) =>
                (!debouncedQuery ||
                    b?.Details?.Title?.toLocaleLowerCase().includes(
                        debouncedQuery?.toLocaleLowerCase()
                    )) &&
                (selectedCategoryOption === "all" ||
                    b?.Categories?.some(
                        (c) => c?.Details?.Id === selectedCategoryOption
                    )) &&
                filterBroadcasts(b)
        );
    }, [
        selectedFilterOption,
        sortedBroadcasts,
        debouncedQuery,
        selectedCategoryOption,
        filterBroadcasts
    ]);

    useEffect(() => {
        // Sort on broadcasts load/change
        handleSort(_broadcasts);

        // Do not include handleSort in dependencies to prevent infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_broadcasts]);

    return (
        <SearchSortBar
            categoryOptions={categoryOptions}
            setSelectedCategoryOption={setSelectedCategoryOption}
            selectedCategoryOption={selectedCategoryOption}
            setSelectedSortOption={setSelectedSortOption}
            selectedSortOption={selectedSortOption}
            setQuery={setQuery}
            query={query}
            showFilters={location === "video-library"}
            selectedFilterOption={selectedFilterOption}
            setSelectedFilterOption={setSelectedFilterOption}
            onClear={() => setQuery("")}
            hiddenSortOptions={hiddenSortOptions}
        />
    );
};
