import { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "store/store";
import { postEvents } from "store/events/thunks";
import rollbar from "helpers/rollbar";
import { v4 as uuidv4 } from "uuid";
import { formatForGatingInput } from "helpers/stripe";
import { GatedContentPassFormProps } from "components/modal/GatedContentModal";
import {
    CatalogResponse,
    CreatorProductEntitlementsBindingModelDiscriminator,
    CreatorProductPricesBindingModel,
    Catalog,
    CatalogRequest,
    CreatorProductPricesBindingModelEntitledDurationUnit,
    CreatorProduct,
    CreatorProductPrice
} from "@switcherstudio/switcher-api-client";
import { exists } from "helpers/booleans";
import { sortByDate } from "helpers/time";
import type { StringDictionary } from "types";
import { closeCurrentModal, setActiveModal } from "store/modal/slice";
import { Modals } from "store/modal/types";
import { AttentionModal } from "components/modal/AttentionModal";
import { isValidGatedContentPassword } from "helpers/gatedContent";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useAliasedApi } from "./useAliasedApi";
import { useUserStripeData } from "hooks/useUserStripeData";

export interface useCatalogGatingProps {
    catalog: CatalogResponse;
}

export interface CatalogGatingFields extends GatedContentPassFormProps {
    isEmailGatingEnabled?: boolean;
    isPasswordGatingEnabled?: boolean;
    password?: string;
    isPurchaseGatingEnabled?: boolean;
    /* EXTENDED PROPS
    name: string;
    description: string;
    selectedPassType: "one-time" | "recurring";
    recurringMonthlyPriceSelected: boolean;
    recurringAnnualPriceSelected: boolean;
    oneTimePriceSelected?: boolean;
    oneTimePrice: string;
    oneTimeDurationSelected: boolean;
    oneTimeDuration?: string;
    oneTimeDurationUnits?: CreatorProductPriceEntitledDurationUnit;
    recurringMonthlyPrice: string;
    recurringAnnualPrice: string;
    recurringApplySubscriptionUpdatesNewOnly: boolean;
    */
}

export const useCatalogGating = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation("subscription-page");

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    // Set local state for errors
    const [priceErrors, setPriceErrors] = useState<StringDictionary>({});
    const [hasPasswordValidationError, setHasPasswordValidationError] =
        useState(false);

    const {
        accountData: { account },
        loading: stripeLoading
    } = useUserStripeData({
        requestImmediately: true,
        includeProducts: false
    });

    // Get all required data and aliased API functions
    const {
        catalog,
        entitlementAndPrices,
        stripeDetails,
        link,
        gatedContentStatus,
        loading: aliasLloading,
        updateCatalog,
        postProduct,
        postProductPrices,
        putProductPrices,
        postEntitlement,
        getEntitlementAndPrices,
        removeActiveSubscriptions
    } = useAliasedApi();

    // Manage state for local form values
    const [formValues, setFormValues] = useState<CatalogGatingFields>(null);
    const [initialFormValues, setInitialFormValues] =
        useState<CatalogGatingFields>(null);

    const [oldProduct, setOldProduct] = useState<CreatorProduct>(null);
    const [oldRecurringMonthlyPrice, setOldRecurringMonthlyPrice] =
        useState<CreatorProductPrice>(null);
    const [oldRecurringAnnualPrice, setOldRecurringAnnualPrice] =
        useState<CreatorProductPrice>(null);
    const [oldOneTimePrice, setOldOneTimePrice] =
        useState<CreatorProductPrice>(null);

    const untimedOTPInitiallySelected = useMemo(() => {
        return oldOneTimePrice?.Active && !oldOneTimePrice?.EntitledDuration;
    }, [oldOneTimePrice]);

    // Memoized booleans for form state quick reference
    const thereIsAnOldProduct = useMemo(() => exists(oldProduct), [oldProduct]);

    const catalogHasNoChanges = useMemo(() => {
        if (!initialFormValues) return true;
        return JSON.stringify(formValues) === JSON.stringify(initialFormValues);
    }, [formValues, initialFormValues]);

    // This effect runs each time data is fetched for catalog/entitlements/prices
    // It sets the initial values for the form based on the fetched data
    useEffect(() => {
        if (!entitlementAndPrices || !catalog?.Details) return;
        const oldProduct =
            entitlementAndPrices?.ProductEntitlements[0]?.Product;

        // Get the most recent prices
        const sortedPrices = oldProduct?.Prices?.sort((a, b) =>
            sortByDate(a.UpdatedAt, b.UpdatedAt, { descending: true })
        );

        let oldRecurringMonthlyPrice = sortedPrices?.find(
            (p) => p.RecurringInterval === "month" && p.Active
        );

        let oldRecurringAnnualPrice = sortedPrices?.find(
            (p) => p.RecurringInterval === "year" && p.Active
        );

        let oldOneTimePrice = sortedPrices?.find(
            (p) => !p.RecurringInterval && p.Active
        );

        // For each price, if there is no active price, find the first inactive price
        if (!oldRecurringMonthlyPrice) {
            oldRecurringMonthlyPrice = sortedPrices?.find(
                (p) => p.RecurringInterval === "month"
            );
        }

        if (!oldRecurringAnnualPrice) {
            oldRecurringAnnualPrice = sortedPrices?.find(
                (p) => p.RecurringInterval === "year"
            );
        }

        if (!oldOneTimePrice) {
            oldOneTimePrice = sortedPrices?.find((p) => !p.RecurringInterval);
        }

        // Set local state
        setOldProduct(oldProduct);
        setOldRecurringMonthlyPrice(oldRecurringMonthlyPrice);
        setOldRecurringAnnualPrice(oldRecurringAnnualPrice);
        setOldOneTimePrice(oldOneTimePrice);

        // Set the values for the form based on the fetched data (or defaults)
        const isMonthlyActive = oldRecurringMonthlyPrice?.Active || false;
        const isAnnualActive = oldRecurringAnnualPrice?.Active || false;
        const isOneTimeActive = oldOneTimePrice?.Active || false;
        const oneTimeDurationSelected =
            isOneTimeActive && oldOneTimePrice?.EntitledDuration > 0;
        const oneTimeDuration =
            oldOneTimePrice?.EntitledDuration?.toString() || null;
        const oneTimeDurationUnits =
            oldOneTimePrice?.EntitledDurationUnit || null;
        const recurringMonthlyPrice = oldRecurringMonthlyPrice
            ? formatForGatingInput(oldRecurringMonthlyPrice?.Amount)
            : "10.00";
        const recurringAnnualPrice = oldRecurringAnnualPrice
            ? formatForGatingInput(oldRecurringAnnualPrice?.Amount)
            : "100.00";
        const oneTimePrice = oldOneTimePrice
            ? formatForGatingInput(oldOneTimePrice?.Amount)
            : "2.00";

        const selectedPassType: "recurring" | "one-time" = isOneTimeActive
            ? "one-time"
            : "recurring";

        const isPurchaseGatingEnabled = oldProduct?.Prices?.some(
            (p) => p.Active
        );

        // Set the initial form values
        const newValues: CatalogGatingFields = {
            name: "Catalog Pass",
            description: "",
            selectedPassType,
            recurringMonthlyPriceSelected: isMonthlyActive,
            recurringAnnualPriceSelected: isAnnualActive,
            oneTimePriceSelected: isOneTimeActive,
            oneTimePrice,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            recurringApplySubscriptionUpdatesNewOnly: true,
            oneTimeDurationSelected,
            oneTimeDuration,
            oneTimeDurationUnits,
            isPurchaseGatingEnabled,
            isEmailGatingEnabled: catalog?.Details?.IsEmailGatingEnabled,
            isPasswordGatingEnabled: catalog?.Details?.IsPasswordGatingEnabled,
            password: catalog?.Details?.Password ?? "",
            oldProduct,
            oldRecurringMonthlyPrice,
            oldRecurringAnnualPrice,
            oldOneTimePrice
        };

        setInitialFormValues(newValues);
        setFormValues(newValues);
        setIsFirstLoad(false);
    }, [entitlementAndPrices, catalog?.Details]);

    const updatePrices = useCallback(async () => {
        // Destructure form values
        const {
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            oneTimePrice,
            oneTimeDurationSelected,
            oneTimeDuration,
            oneTimeDurationUnits,
            recurringApplySubscriptionUpdatesNewOnly,
            selectedPassType,
            isPurchaseGatingEnabled
        } = formValues;

        // Build the array for the PUT request
        // Monthly Recurring Price
        const monthlyActive =
            recurringMonthlyPriceSelected &&
            selectedPassType === "recurring" &&
            isPurchaseGatingEnabled;
        const monthlyRecurringPriceObject = {
            "Name": "Catalog Pass",
            "RecurringInterval": "month",
            "Id": oldRecurringMonthlyPrice?.Id,
            "ProductId": oldRecurringMonthlyPrice?.ProductId ?? oldProduct?.Id,
            "Amount": parseFloat(recurringMonthlyPrice) * 100,
            "IsActive": monthlyActive,
            "Active": monthlyActive,
            "IsPublic": monthlyActive,
            "IsRecurring": true,
            "RecurringIntervalCount": 1,
            "RecurringUsageType": "licensed",
            "BillingScheme": "per_unit"
        } as CreatorProductPricesBindingModel;
        // Yearly Recurring Price
        const yearlyActive =
            recurringAnnualPriceSelected &&
            selectedPassType === "recurring" &&
            isPurchaseGatingEnabled;
        const yearlyRecurringPriceObject = {
            "Name": "Catalog Pass",
            "RecurringInterval": "year",
            "Id": oldRecurringAnnualPrice?.Id,
            "ProductId": oldRecurringAnnualPrice?.ProductId ?? oldProduct?.Id,
            "Amount": parseFloat(recurringAnnualPrice) * 100,
            "IsActive": yearlyActive,
            "Active": yearlyActive,
            "IsPublic": yearlyActive,
            "IsRecurring": true,
            "RecurringIntervalCount": 1,
            "RecurringUsageType": "licensed",
            "BillingScheme": "per_unit"
        } as CreatorProductPricesBindingModel;
        // One-Time Price
        const oneTimeActive =
            selectedPassType === "one-time" && isPurchaseGatingEnabled;
        const oneTimeDurationActive = oneTimeDurationSelected && oneTimeActive;
        const newOneTimeDurationUnits = oneTimeDurationActive
            ? CreatorProductPricesBindingModelEntitledDurationUnit[
                  // if units are null, default to "Hours"
                  oneTimeDurationUnits || "Hours"
              ]
            : null;
        const oneTimePriceObject = {
            "Name": "Catalog Pass",
            "Id": oldOneTimePrice?.Id,
            "IsActive": oneTimeActive,
            "Active": oneTimeActive,
            "IsPublic": oneTimeActive,
            "ProductId": oldOneTimePrice?.ProductId ?? oldProduct?.Id,
            "Amount": parseFloat(oneTimePrice) * 100,
            "EntitledDuration": oneTimeDurationActive
                ? parseFloat(oneTimeDuration)
                : null,
            "EntitledDurationUnit": newOneTimeDurationUnits
        } as CreatorProductPricesBindingModel;

        const pricesArray = [
            monthlyRecurringPriceObject,
            yearlyRecurringPriceObject,
            oneTimePriceObject
        ];

        try {
            await putProductPrices([
                account?.Id,
                oldProduct?.Id,
                {
                    Prices: pricesArray,
                    UpdateExistingCustomersToPrice:
                        !recurringApplySubscriptionUpdatesNewOnly
                }
            ]);
        } catch (e) {
            rollbar.error("Error updating catalog pass", e);
        } finally {
            getEntitlementAndPrices();
        }
    }, [
        oldProduct,
        oldRecurringMonthlyPrice,
        oldRecurringAnnualPrice,
        oldOneTimePrice,
        account,
        getEntitlementAndPrices,
        putProductPrices,
        formValues
    ]);

    const validateFields = useCallback(
        ({
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringAnnualPrice,
            recurringMonthlyPrice,
            oneTimeDurationSelected,
            oneTimeDuration
        }: CatalogGatingFields) => {
            let errors: StringDictionary = {};
            const validatePrice = (id: string, price: string) => {
                const baseError = t(
                    "gated-content-modal:errors:price-min-error"
                );
                if (!price) {
                    errors[id] = t("gated-content-modal:errors:price-error");
                }

                const minPrices = {
                    recurringAnnualPrice: import.meta.env
                        .VITE_MINIMUM_ANNUAL_PRICE,
                    recurringMonthlyPrice: import.meta.env
                        .VITE_MINIMUM_MONTHLY_PRICE,
                    oneTimePrice: import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE
                };

                if (parseFloat(price) < parseFloat(minPrices[id])) {
                    errors[id] = `${baseError} $${minPrices[id]}`;
                }
            };

            if (recurringMonthlyPriceSelected) {
                validatePrice("recurringMonthlyPrice", recurringMonthlyPrice);
            }
            if (recurringAnnualPriceSelected) {
                validatePrice("recurringAnnualPrice", recurringAnnualPrice);
            }

            // Validate duration if one-time pass is selected and duration is selected
            if (
                oneTimeDurationSelected &&
                (!oneTimeDuration || oneTimeDuration === "0")
            ) {
                errors.oneTimeDuration = t(
                    "gated-content-modal:errors:duration-error"
                );
            }

            setPriceErrors(errors);
            return errors;
        },
        [t]
    );

    const handlePriceChange = useCallback(
        (key: string, val: any) => {
            setFormValues({ ...formValues, [key]: val });
        },
        [formValues, setFormValues]
    );

    /* Form submission logic */
    const onSubmit = useCallback(async () => {
        if (catalogHasNoChanges) return;

        const {
            name,
            selectedPassType,
            oneTimePrice,
            oneTimeDurationSelected,
            oneTimeDuration,
            oneTimeDurationUnits,
            recurringMonthlyPriceSelected,
            recurringAnnualPriceSelected,
            recurringMonthlyPrice,
            recurringAnnualPrice,
            isPurchaseGatingEnabled,
            isEmailGatingEnabled,
            isPasswordGatingEnabled,
            password
        } = formValues;

        const apiObject: CatalogRequest = {
            Catalog: {
                ...(catalog?.Details as unknown as Catalog),
                IsEmailGatingEnabled: isEmailGatingEnabled,
                IsPasswordGatingEnabled: isPasswordGatingEnabled,
                Password: password
            }
        };

        // Validate fields
        const priceErrors = validateFields(formValues);
        if (Object.keys(priceErrors).length > 0) return;

        /* Update Catalog Entity */
        try {
            // Validate the password if password gating is enabled
            if (
                isPasswordGatingEnabled &&
                !isValidGatedContentPassword(password)
            ) {
                setHasPasswordValidationError(true);
                return;
            } else {
                setHasPasswordValidationError(false);
            }

            /* Update Catalog Purchase Gating */
            if (!thereIsAnOldProduct) {
                try {
                    if (isPurchaseGatingEnabled) {
                        const res = await postProduct([
                            account?.Id,
                            {
                                Products: [
                                    {
                                        Id: uuidv4(),
                                        Name: "Catalog Pass",
                                        StripeAccountId: account?.Id,
                                        IsActive: true
                                    }
                                ]
                            }
                        ]);

                        const prices = [];
                        if (selectedPassType === "one-time") {
                            const duration = oneTimeDurationSelected
                                ? oneTimeDuration
                                : null;
                            const durationUnits = oneTimeDurationSelected
                                ? oneTimeDurationUnits
                                : "Hours";
                            const createObject = {
                                Id: uuidv4(),
                                Name: name,
                                IsActive: true,
                                IsPublic: true,
                                ProductId: res?.Products?.[0]?.Id,
                                Amount: parseFloat(oneTimePrice) * 100,
                                BillingScheme: "per_unit",
                                ...(duration && {
                                    EntitledDuration: parseFloat(duration)
                                }),
                                ...(durationUnits && {
                                    EntitledDurationUnit:
                                        CreatorProductPricesBindingModelEntitledDurationUnit[
                                            durationUnits
                                        ]
                                })
                            } as CreatorProductPricesBindingModel;
                            prices.push(createObject);
                        } else {
                            if (recurringMonthlyPriceSelected) {
                                const createObject = {
                                    Id: uuidv4(),
                                    Name: name,
                                    IsActive: true,
                                    IsPublic: true,
                                    ProductId: res?.Products?.[0]?.Id,
                                    Amount:
                                        parseFloat(recurringMonthlyPrice) * 100,
                                    BillingScheme: "per_unit",
                                    RecurringUsageType: "licensed",
                                    IsRecurring: true,
                                    RecurringInterval: "month",
                                    RecurringIntervalCount: 1
                                } as CreatorProductPricesBindingModel;
                                prices.push(createObject);
                            }

                            if (recurringAnnualPriceSelected) {
                                const createObject = {
                                    Id: uuidv4(),
                                    Name: name,
                                    IsActive: true,
                                    IsPublic: true,
                                    ProductId: res?.Products?.[0]?.Id,
                                    Amount:
                                        parseFloat(recurringAnnualPrice) * 100,
                                    BillingScheme: "per_unit",
                                    RecurringUsageType: "licensed",
                                    IsRecurring: true,
                                    RecurringInterval: "year",
                                    RecurringIntervalCount: 1
                                } as CreatorProductPricesBindingModel;
                                prices.push(createObject);
                            }
                        }

                        postProductPrices([
                            account?.Id,
                            res?.Products?.[0]?.Id,
                            {
                                Prices: prices
                            }
                        ]);

                        postEntitlement([
                            {
                                ProductEntitlements: [
                                    {
                                        ProductId: res?.Products[0]?.Id,
                                        Discriminator:
                                            CreatorProductEntitlementsBindingModelDiscriminator._3,
                                        CatalogId: catalog?.Details?.Id
                                    }
                                ]
                            }
                        ]);

                        getEntitlementAndPrices();

                        dispatch(postEvents({ "created-pass": true }));
                    }

                    await updateCatalog([catalog?.Details?.Id, apiObject]);

                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t("catalog-page:catalog-update-success")
                        })
                    );
                } catch (e) {
                    rollbar.error("Error creating catalog pass", e);
                }
            } else if (thereIsAnOldProduct) {
                const hasActiveSubscriptions =
                    oldProduct?.ActiveSubscriptions > 0;

                const hasNoRecurringPricesSelected =
                    (!recurringMonthlyPriceSelected &&
                        !recurringAnnualPriceSelected) ||
                    selectedPassType !== "recurring" ||
                    !isPurchaseGatingEnabled;

                // If deactivating prices with active subscriptions
                if (hasActiveSubscriptions && hasNoRecurringPricesSelected) {
                    // Get user's confirmation to cancel with modal
                    dispatch(
                        setActiveModal({
                            id: Modals.AttentionModal,
                            type: Modals.AttentionModal,
                            component: (
                                <AttentionModal
                                    isOpen={true}
                                    setIsOpen={() =>
                                        dispatch(closeCurrentModal())
                                    }
                                    handleCancel={() =>
                                        dispatch(closeCurrentModal())
                                    }
                                    hasContinueButton={true}
                                    handleContinue={async () => {
                                        postEntitlement([
                                            {
                                                ProductEntitlements: [
                                                    {
                                                        ProductId:
                                                            oldProduct.Id,
                                                        Discriminator:
                                                            CreatorProductEntitlementsBindingModelDiscriminator._3,
                                                        CatalogId:
                                                            catalog?.Details?.Id
                                                    }
                                                ]
                                            }
                                        ]);

                                        updatePrices();
                                        await removeActiveSubscriptions([
                                            entitlementAndPrices
                                                ?.ProductEntitlements?.[0]
                                                ?.ProductId
                                        ]);

                                        updateCatalog([
                                            catalog?.Details?.Id,
                                            apiObject
                                        ]);

                                        dispatch(closeCurrentModal());
                                    }}
                                    continueText={t("buttons:continue")}
                                >
                                    <p>{t("catalog-cancellation-warning")}</p>
                                </AttentionModal>
                            )
                        })
                    );
                } else {
                    await postEntitlement([
                        {
                            ProductEntitlements: [
                                {
                                    ProductId: oldProduct.Id,
                                    Discriminator:
                                        CreatorProductEntitlementsBindingModelDiscriminator._3,
                                    CatalogId: catalog?.Details?.Id
                                }
                            ]
                        }
                    ]);

                    await updatePrices();
                    await updateCatalog([catalog?.Details?.Id, apiObject]);

                    dispatch(
                        addNotification({
                            type: NotificationType.Success,
                            message: t("catalog-page:catalog-update-success")
                        })
                    );
                }
            }
        } catch (error) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("catalog-page:catalog-update-error")
                })
            );
        }
    }, [
        catalog,
        updateCatalog,
        catalogHasNoChanges,
        dispatch,
        t,
        postProduct,
        postProductPrices,
        postEntitlement,
        updatePrices,
        account,
        oldProduct,
        thereIsAnOldProduct,
        formValues,
        getEntitlementAndPrices,
        validateFields,
        removeActiveSubscriptions,
        entitlementAndPrices
    ]);

    return {
        formValues,
        priceErrors,
        handlePriceChange,
        catalogHasNoChanges,
        stripeDetails,
        link,
        loading: aliasLloading || stripeLoading || isFirstLoad,
        gatedContentStatus,
        account,
        thereIsAnOldProduct,
        entitlementAndPrices,
        hasPasswordValidationError,
        setHasPasswordValidationError,
        onSubmit,
        untimedOTPInitiallySelected
    };
};
