import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { GatedContentModalCallbacks } from "views/page-content/gated-content/PricingDetails/GatedContentTable";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";

interface AttentionModalProps extends GatedContentModalCallbacks {
    isOpen: boolean;
    setIsOpen?: Dispatch<SetStateAction<boolean>>;
    /** callback function to handle actions following cancelation of modal */
    handleCancel?: () => void;
    handleContinue?: () => void;
    children: React.ReactNode;
    hasContinueButton?: boolean;
    continueText?: string;
    cancelText?: string;
}

export const AttentionModal: React.FC<AttentionModalProps> = ({
    isOpen,
    setIsOpen,
    handleCancel,
    handleContinue,
    children,
    hasContinueButton = true,
    continueText,
    cancelText
}: AttentionModalProps) => {
    const { t } = useTranslation();
    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dismissButton={cancelText ?? t("buttons:cancel")}
            onDismiss={handleCancel}
            successButton={hasContinueButton ? continueText : undefined}
            onSuccess={handleContinue}
            header={t("gated-content-page:attention")}
            variant={"warning"}
        >
            <div className={`${styles["content"]} ${styles["undo-mb"]}`}>
                {children}
            </div>
        </ModalBase>
    );
};
