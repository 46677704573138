import { useCallback, useState } from "react";
import styles from "./index.module.scss";
import { usePageHeader } from "hooks/usePageHeader";
import { Tabs } from "components/tabs/Tabs";
import { VideoLibraryBroadcastList } from "./VideoLibraryBroadcastList";
import { useTranslation } from "react-i18next";
import { VideoUploadModalEntryPoint } from "components/video-upload/VideoUploadModalEntryPoint";
import { v4 as uuidv4 } from "uuid";
import { Link } from "components/buttons/Link";
import { useGetPagedBroadcastByFilter } from "hooks/useGetPagedBroadcastByFilter";
import { SearchSortOptions } from "components/generic-multiselect/types";
import { useNavigate } from "react-router-dom";
import { BroadcastResponse } from "@switcherstudio/api-core-client";

export type VideoLibraryTabs = "on-demand" | "upcoming";

export const VideoLibraryPageV2 = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    usePageHeader({
        subTitle: (
            <p>
                {t("video-library:sub-title")},{" "}
                <Link
                    to="https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t("video-library:sub-title-link")}
                </Link>
            </p>
        )
    });

    const [activeTab, setActiveTab] = useState<VideoLibraryTabs>("on-demand");

    const [uploadSessionId, setUploadSessionId] = useState<string>(uuidv4());
    const [uploadModalKey, setUploadModalKey] = useState<number>(0);

    const {
        existingBroadcasts,
        scheduledBroadcasts,
        unpublishedBroadcasts,
        refetchVideos,
        firstLoading
    } = useGetPagedBroadcastByFilter({
        videoStatusType: "Library",
        preFetch: () => {
            setUploadModalKey((prev) => prev + 1);
        }
    });

    const searchSortOptions: SearchSortOptions = {
        showSearchSort: true,
        implementationType: "broadcast-response-search-sort",
        location: "video-library"
    };

    const onBroadcastEdit = useCallback(
        (broadcast: BroadcastResponse) => {
            navigate(`/video-library/video/${broadcast.Details.Id}`);
        },
        [navigate]
    );

    return (
        <div className={styles["video-library-page"]}>
            <VideoUploadModalEntryPoint
                sessionId={uploadSessionId}
                setSessionId={setUploadSessionId}
                onUploadComplete={() => refetchVideos(null)}
                refreshKey={uploadModalKey}
                allowMultipleUploads
                isVideoLibrary
                showVideoStorageBar
            />

            <Tabs<VideoLibraryTabs>
                tabs={[
                    {
                        id: "on-demand",
                        label: t("video-library:on-demand"),
                        component: (
                            <VideoLibraryBroadcastList
                                broadcasts={[
                                    ...existingBroadcasts,
                                    ...unpublishedBroadcasts
                                ]}
                                refetchVideos={refetchVideos}
                                firstLoading={firstLoading}
                                emptyStateCopy={t(
                                    "video-library:no-videos-match"
                                )}
                                searchSortOptions={searchSortOptions}
                                onEdit={onBroadcastEdit}
                                showDownload
                            />
                        )
                    },
                    {
                        id: "upcoming",
                        label: t("video-library:upcoming"),
                        component: (
                            <VideoLibraryBroadcastList
                                broadcasts={scheduledBroadcasts}
                                refetchVideos={refetchVideos}
                                firstLoading={firstLoading}
                                emptyStateCopy={t(
                                    "video-library:no-videos-match"
                                )}
                                searchSortOptions={searchSortOptions}
                                onEdit={onBroadcastEdit}
                                showDownload
                            />
                        )
                    }
                ]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
        </div>
    );
};
