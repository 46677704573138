import { useMemo } from "react";
import { ComponentMap } from "components/utility/ComponentMap";
import { ChevronIcon } from "components/icons/ChevronIcon";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { isGuid } from "helpers/strings";
export interface BreadcrumbsProps {
    /** The current pathname */
    pathname: string;
    /** The fallback text to display if the path is not found in the i18n file */
    labels?: string[];
    /** Hard code the breadcrumb labels and to where they link. */
    customCrumbs?: CustomCrumb[];
}

export interface Crumb {
    /** The unique id of the crumb taken from the path */
    id: string;
    /** Accumulates the path up to this crumb */
    path: string;
    /** The displayed text of the crumb */
    label: string;
}

export interface CustomCrumb {
    /** The label to display */
    label: string;
    /** The entire route to which the label will link */
    route: string;
}

/**
 * A component to display breadcrumbs in the page content header. The component already exists in the header, to add it to a page, mark the "showBreadcrumbs" prop in the header state to true, using the usePageHeader hook.
 * To add a translation or specific text to a breadcrumb, use the "breadcrumbs" namespace in the i18n file, matching the id of the crumb to the text to display. For paths with a dynamic key, like a guid, use the "breadcrumbFallback" prop.
 *
 * Should not be used with the back navigation prop.
 */
export const Breadcrumbs = ({
    pathname,
    labels,
    customCrumbs
}: BreadcrumbsProps) => {
    const navigate = useNavigate();

    const crumbs = useMemo<Crumb[]>(() => {
        let route = pathname.slice(1);
        let nodes = route.split("/");

        // If node is a guid, add it to previous node
        let reducedNodes: string[] = nodes.reduce((acc, val) => {
            if (isGuid(val)) {
                acc[acc.length - 1] += "/" + val;
            } else {
                acc.push(val);
            }
            return acc;
        }, []);

        let path = "";
        let crumbs: Crumb[];

        if (!!customCrumbs && customCrumbs.length > 0) {
            crumbs = customCrumbs.map(({ label, route }) => {
                return {
                    id: label,
                    path: route,
                    label: label
                };
            });
        } else if (!!labels && labels.length > 0) {
            crumbs = reducedNodes.map((node, index) => {
                path += "/" + node;

                return {
                    id: node,
                    path,
                    label: labels[index]
                };
            });
        }

        return crumbs;
    }, [labels, pathname, customCrumbs]);

    return (
        <ComponentMap
            className={styles["breadcrumbs"]}
            items={crumbs}
            element={({ path, label }, { isLast }) => {
                if (isLast) {
                    // The last segment is the current page, so it is not a link
                    return <span>{label}</span>;
                } else {
                    return (
                        <Button type="link" onClick={() => navigate(path)}>
                            {label}
                        </Button>
                    );
                }
            }}
            separator={<ChevronIcon direction="right" />}
        />
    );
};
