import {
    Broadcast,
    BroadcastStatus,
    BroadcastResponse as FrameworkBroadcastResponse
} from "@switcherstudio/switcher-api-client";
import { BroadcastResponse as CoreBroadcastResponse } from "@switcherstudio/api-core-client";
import { isInFuture } from "./time";

export const isBroadcastResponse = (
    broadcast: Broadcast | FrameworkBroadcastResponse
): boolean => {
    return (broadcast as FrameworkBroadcastResponse)?.Details !== undefined;
};

export const isFrameworkBroadcastResponse = (
    broadcast: CoreBroadcastResponse | FrameworkBroadcastResponse
) => {
    return (
        (broadcast as FrameworkBroadcastResponse)?.MetricsSummary !== undefined
    );
};

export const isCoreBroadcastResponse = (
    broadcast: CoreBroadcastResponse | FrameworkBroadcastResponse
) => {
    return !isFrameworkBroadcastResponse(broadcast);
};

export const checkBroadcastType = (
    broadcast: Broadcast | CoreBroadcastResponse
): {
    isPremiere: boolean;
    isScheduledLivestream: boolean;
    isExistingContent: boolean;
    isUnpublished: boolean;
} => {
    // Get the required data based on the type of broadcast
    let coreBroadcast = broadcast as CoreBroadcastResponse;
    let frameworkBroadcast = broadcast as Broadcast;

    const broadcastData = {
        StartsAt:
            frameworkBroadcast?.StartsAt || coreBroadcast?.Details?.StartsAt,
        BroadcastStatus:
            frameworkBroadcast?.BroadcastStatus ||
            coreBroadcast?.Details?.BroadcastStatus
    };

    // Check if the broadcast is a premiere, livestream, or existing content
    const startsInFuture =
        broadcastData?.StartsAt && isInFuture(broadcastData?.StartsAt);

    const hasEnded =
        broadcastData?.BroadcastStatus === BroadcastStatus._3 ||
        broadcastData?.BroadcastStatus === "Ended";

    const isUnpublished =
        broadcastData?.BroadcastStatus === BroadcastStatus._5 ||
        broadcastData?.BroadcastStatus === "Unpublished";

    const isPremiere = hasEnded && startsInFuture;

    const isScheduledLivestream =
        startsInFuture &&
        (broadcastData?.BroadcastStatus === BroadcastStatus._1 ||
            broadcastData?.BroadcastStatus === "Ready");

    const isExistingContent = !startsInFuture && hasEnded;

    return {
        isPremiere,
        isScheduledLivestream,
        isExistingContent,
        isUnpublished
    };
};
