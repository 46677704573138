import {
    Broadcast,
    BroadcastResponse as FrameworkBroadcastResponse,
    BroadcastStatus,
    WebLinkResponse,
    Category,
    SwitcherStreamSetting
} from "@switcherstudio/switcher-api-client";
import { BroadcastResponse as CoreBroadcastResponse } from "@switcherstudio/api-core-client";
import { isCoreBroadcastResponse } from "helpers/broadcastHelpers";
import { mapCoreCategoryTypeToCategoryType } from "./mapCategoryResponseToCategory";
import { mapBroadcastResponseStatusToBroadcastStatus } from "./mapBroadcastResponseStatusToBroadcastStatus";
/**
 * Maps a broadcast response to a Broadcast object. Can take either a CoreBroadcastResponse or a FrameworkBroadcastResponse.
 * @param broadcastResponse
 * @returns Broadcast
 */
export const mapBroadcastResponseToBroadcast = (
    broadcastResponse: CoreBroadcastResponse | FrameworkBroadcastResponse
): Broadcast =>
    isCoreBroadcastResponse(broadcastResponse)
        ? mapCoreBroadcastResponseToBroadcast(
              broadcastResponse as CoreBroadcastResponse
          )
        : mapFrameworkBroadcastResponseToBroadcast(
              broadcastResponse as FrameworkBroadcastResponse
          );

export const mapFrameworkBroadcastResponseToBroadcast = (
    response: FrameworkBroadcastResponse
): Broadcast => {
    if (!response) {
        return null;
    }

    const mappedBroadcast: Broadcast = {
        Id: response?.Details?.Id,
        Title: response?.Details?.Title,
        Description: response?.Details?.Description,
        CreatedAt: response?.Details?.CreatedAt,
        CreatedBy: response?.Details?.CreatedBy,
        UpdatedAt: response?.Details?.UpdatedAt,
        StartsAt: response?.Details?.StartsAt,
        ActiveAt: response?.Details?.ActiveAt,
        EndedAt: response?.Details?.EndedAt,
        ProjectId: response?.Details?.ProjectId,
        InputId: response?.Details?.InputId,
        VideoId: response?.Details?.VideoId,
        IsProtected: response?.Details?.IsProtected,
        InputEffectiveDeletionDate:
            response?.Details?.InputEffectiveDeletionDate,
        BroadcastStatus: response?.Details
            ?.BroadcastStatus as any as BroadcastStatus,
        EnableRecording: response?.Details?.EnableRecording,
        EnableSwitcherPlayer: response?.Details?.EnableSwitcherPlayer,
        EnableLiveShopping: response?.Details?.EnableLiveShopping,
        ThumbnailAssetId: response?.Details?.ThumbnailAssetId,
        ThumbnailAsset: {
            SignedUrl: response?.Thumbnail?.Url,
            Id: response?.Details?.ThumbnailAssetId
        },
        Duration: response?.Details?.Duration,
        IsDirectToProvider: response?.Details?.IsDirectToProvider,
        ShareLink: response?.Details?.ShareLink,
        EmbedCode: response?.Details?.EmbedCode,
        ShowInCatalog: response?.Details?.ShowInCatalog,
        ShowPremiereCountdown: response?.Details?.ShowPremiereCountdown,
        BroadcastWebLinks: response?.WebLinks?.map((link) => ({
            Link: link?.Link,
            Title: link?.Title,
            Sequence: link?.Sequence,
            VideoPlayerId: link?.VideoPlayerId,
            BroadcastId: link?.BroadcastId,
            PurchaseRequired: link?.PurchaseRequired,
            Id: link?.Id,
            CreatedAt: link?.CreatedAt,
            UpdatedAt: link?.UpdatedAt
        })),
        Categories: response?.Categories?.map((category) => ({
            Id: category?.Category?.Id,
            Name: category?.Category?.Name,
            ProjectId: category?.Category?.ProjectId,
            CategoryType: category?.Category?.CategoryType,
            Sequence: category?.Category?.Sequence,
            InsertedAt: category?.Category?.InsertedAt,
            CreatedAt: category?.Category?.CreatedAt,
            UpdatedAt: category?.Category?.UpdatedAt
        })),
        SwitcherStreamSettings: []
    };

    return mappedBroadcast;
};

export const mapCoreBroadcastResponseToBroadcast = (
    response: CoreBroadcastResponse
): Broadcast => {
    if (!response) {
        return null;
    }

    const mappedBroadcast: Broadcast = {
        Id: response?.Details?.Id,
        Title: response?.Details?.Title,
        Description: response?.Details?.Description,
        CreatedAt: response?.Details?.CreatedAt,
        CreatedBy: response?.Details?.CreatedBy,
        StartsAt: response?.Details?.StartsAt,
        ActiveAt: response?.Details?.ActiveAt,
        EndedAt: response?.Details?.EndedAt,
        ProjectId: response?.Details?.ProjectId,
        InputId: response?.Details?.InputId,
        VideoId: response?.Details?.VideoId,
        IsProtected: response?.Details?.IsProtected,
        InputEffectiveDeletionDate:
            response?.Details?.InputEffectiveDeletionDate,
        BroadcastStatus: mapBroadcastResponseStatusToBroadcastStatus(
            response?.Details?.BroadcastStatus
        ),
        EnableRecording: response?.Details?.EnableRecording,
        EnableSwitcherPlayer: response?.Details?.EnableSwitcherPlayer,
        EnableLiveShopping: response?.Details?.EnableLiveShopping,
        ThumbnailAssetId: response?.Details?.ThumbnailAssetId,
        ThumbnailAsset: {
            SignedUrl: response?.Thumbnail?.Details?.Url,
            Id: response?.Details?.ThumbnailAssetId
        },
        Duration: response?.Details?.Duration,
        ShareLink: response?.MetaData?.ShareUrl,
        EmbedCode: response?.MetaData?.EmbedCode,
        ShowInCatalog: response?.Details?.ShowInCatalog,
        ShowPremiereCountdown: response?.Details?.ShowPremiereCountdown,
        BroadcastWebLinks: response?.WebLinks?.map(
            (link): WebLinkResponse => ({
                Link: link?.Details?.Link,
                Title: link?.Details?.Title,
                Sequence: link?.Details?.Sequence,
                VideoPlayerId: link?.Details?.VideoPlayerId,
                BroadcastId: link?.Details?.BroadcastId,
                PurchaseRequired: link?.Details?.PurchaseRequired,
                Id: link?.Details?.Id
            })
        ),
        Categories: response?.Categories?.map(
            (category): Category => ({
                Id: category?.Details?.Id,
                Name: category?.Details?.Name,
                CategoryType: mapCoreCategoryTypeToCategoryType(
                    category?.Details?.CategoryType
                ),
                Sequence: category?.Details?.Sequence,
                CreatedAt: category?.Details?.CreatedAt,
                UpdatedAt: category?.Details?.UpdatedAt
            })
        ),
        SwitcherStreamSettings: response?.StreamSettings?.map(
            (setting): SwitcherStreamSetting => ({
                "audio-bit-rate": setting?.Details?.AudioBitRate,
                "audio-channel-count": setting?.Details?.AudioChannelCount,
                "audio-sample-rate": setting?.Details?.AudioSampleRate,
                "channel-name": setting?.Details?.ChannelName,
                "facebook-edge": setting?.Details?.FacebookEdge,
                "facebook-edge-id": setting?.Details?.FacebookEdgeId,
                "facebook-video-id": setting?.Details?.FacebookVideoId,
                "is-direct-to-provider": setting?.Details?.IsDirectToProvider,
                "permalink-url": setting?.Details?.PermalinkUrl,
                "rtmp-stream": setting?.Details?.RtmpStream,
                "switcher-stream-setting-id":
                    setting?.Details?.SwitcherStreamSettingId,
                "updated-on": setting?.Details?.UpdatedOn,
                "user-editable": setting?.Details?.UserEditable,
                "youtube-stream-id": setting?.Details?.YouTubeStreamId,
                "youtube-broadcast-id": setting?.Details?.YouTubeBroadcastId,
                "video-bit-rate": setting?.Details?.VideoBitRate,
                "video-frame-height": setting?.Details?.VideoFrameHeight,
                "video-frame-width": setting?.Details?.VideoFrameWidth,
                broadcastId: setting?.Details?.BroadcastId,
                discriminator: setting?.Details?.Discriminator,
                meta: setting?.Details?.Meta,
                StreamingProviderId: setting?.Details?.StreamingProviderId,
                url: setting?.Details?.RtmpStreamUrl,
                version: setting?.Details?.Version,
                "concurrency-token": setting?.Details?.RowVersion,
                "is-deleted": false, // Not mapped in the API
                "time-before-connection": null // not mapped in the API
            })
        )
    };

    return mappedBroadcast;
};
