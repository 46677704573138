import { StripeElement } from "components/stripe/StripeElement";
import { redirectTo } from "router/loaders";
import { SubscriptionPage } from "views/page-content/SubscriptionPage";

export const SubscriptionRoutes = [
    {
        path: "billing",
        loader: redirectTo("/subscription")
    },
    {
        path: "subscription",
        element: (
            <StripeElement>
                <SubscriptionPage />
            </StripeElement>
        )
    },
    {
        path: "subscription/subscribe",
        loader: redirectTo("/subscription#openModal")
    }
];
