import React, { useMemo } from "react";
import { EntitlementsWidgetProps } from "./types";
import BillingIcon from "assets/icons/billing.svg?react";
import { displayDate } from "helpers/time";
import { UpgradeButton } from "components/buttons/upgrade-button/UpgradeButton";
import { useTranslation } from "react-i18next";
import { useHasAccess } from "hooks/useHasAccess";
import { Widget } from "./Widget";

export const EntitlementWidget: React.FC<EntitlementsWidgetProps> = ({
    entitlements
}: EntitlementsWidgetProps) => {
    const { i18n, t } = useTranslation("widgets");
    const isLapsedOrPaused = useHasAccess(
        (userInfo) =>
            userInfo?.Roles.includes("Paused") ||
            userInfo?.Roles.includes("Lapsed")
    );

    const yourEntitlement = useMemo<string>(
        () => entitlements?.[0]?.LatestProduct.Name ?? "",
        [entitlements]
    );
    const expireDate = useMemo<string>(
        () => displayDate(entitlements?.[0]?.ExpiresAt ?? "", i18n.language),
        [entitlements, i18n]
    );
    const isSubscriptionEntitlement = useMemo<boolean>(
        () => entitlements?.[0]?.discriminator?.includes("Subscription"),
        [entitlements]
    );

    const details = [
        {
            title: t("your-pass"),
            infoText: yourEntitlement
        }
    ];

    if (!entitlements?.[0]?.IsExpired) {
        details.push({
            title: t("active-until"),
            infoText: expireDate.toString()
        });
    }

    return (
        <Widget
            loading={!yourEntitlement}
            icon={<BillingIcon />}
            title={
                isSubscriptionEntitlement
                    ? t("subscription").toUpperCase()
                    : t("entitlement").toUpperCase()
            }
            details={details}
            button={
                <UpgradeButton
                    needsUpgrade={false}
                    isLapsedOrPaused={isLapsedOrPaused}
                    alternateText={t("manage-account").toUpperCase()}
                    alternateRoute="/subscription"
                    buttonStyle="btn-outline-secondary"
                />
            }
        />
    );
};
