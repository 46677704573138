import {
    GatedContentPassFormProps,
    PricingModalTypes
} from "components/modal/GatedContentModal";
import { RadioCard, RadioCardsBlockContainer } from "..";
import { RecurringPassField } from "components/modal/GatedContentModal/RecurringPassField";
import { OneTimePassField } from "components/modal/GatedContentModal/OneTimePassField";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Errors } from "hooks/useForm";

interface GatedContentCardsProps {
    formValues: GatedContentPassFormProps;
    errors: Errors;
    type: PricingModalTypes;
    onPassChange?: (field: string, value: any) => void;
    setPlayerSelectModalIsOpen?: Dispatch<SetStateAction<boolean>>;
    buttonText?: string;
    loading?: boolean;
    buttonActive?: boolean;
    variant?: "catalog";
    creatorProductId?: string;
    selectionLocked?: boolean;
    shouldShowOneTimePassOption?: boolean;
    untimedOTPInitiallySelected?: boolean;
}

const GatedContentCards: React.FC<GatedContentCardsProps> = ({
    formValues,
    errors,
    onPassChange,
    loading,
    type,
    variant,
    selectionLocked = false,
    shouldShowOneTimePassOption = false,
    untimedOTPInitiallySelected = false
}) => {
    const { t } = useTranslation();

    const handlePassChange = useCallback(
        (newSelection: string) => {
            if (newSelection !== formValues.selectedPassType)
                onPassChange("selectedPassType", newSelection);
        },
        [onPassChange, formValues.selectedPassType]
    );

    return (
        <>
            <RadioCardsBlockContainer>
                <RadioCard
                    id="recurring"
                    content={
                        <div style={{ textAlign: "left" }}>
                            {t("misc:recurring")}
                        </div>
                    }
                    onSelect={handlePassChange}
                    selectedCardId={formValues?.selectedPassType}
                    name="Recurring"
                    expandedContent={
                        <RecurringPassField
                            values={formValues}
                            errors={errors}
                            onChange={onPassChange}
                            loading={loading}
                            type={type}
                            variant={variant}
                        />
                    }
                    checkboxPosition="start"
                    selectionLocked={selectionLocked}
                />
                {shouldShowOneTimePassOption && (
                    <RadioCard
                        id="one-time"
                        content={
                            <div style={{ textAlign: "left" }}>
                                {t("misc:one-time")}
                            </div>
                        }
                        onSelect={handlePassChange}
                        selectedCardId={formValues?.selectedPassType}
                        name="One Time"
                        expandedContent={
                            <OneTimePassField
                                values={formValues}
                                errors={errors}
                                onChange={onPassChange}
                                type={type}
                                untimedOTPInitiallySelected={
                                    untimedOTPInitiallySelected
                                }
                            />
                        }
                        checkboxPosition="start"
                        selectionLocked={selectionLocked}
                    />
                )}
            </RadioCardsBlockContainer>
        </>
    );
};

export default GatedContentCards;
