import {
    Entitlement,
    SilverSunnStripeCustomer,
    StripeInvoiceBindingModel
} from "@switcherstudio/switcher-api-client";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { SubscriptionWithPrice } from "./";
import { es, enUS } from "date-fns/locale";
import { PaymentBrandIcon } from "components/icons/PaymentBrandIcon";
import { Widget } from "components/widgets/Widget";
import { ManagePaymentDetails } from "components/modal/ManagePaymentDetails";
import { SetupIntentElement } from "components/stripe/SetupIntentElement";
import styles from "./index.module.scss";

export const NextPaymentOn = ({
    customer,
    refetchCustomer,
    isPastDue,
    activePlan,
    upcomingDraftInvoice,
    currentEntitlement
}: {
    customer: SilverSunnStripeCustomer;
    refetchCustomer: () => void;
    isPastDue: boolean;
    activePlan?: SubscriptionWithPrice;
    upcomingDraftInvoice?: StripeInvoiceBindingModel;
    currentEntitlement?: Entitlement;
}) => {
    const { t, i18n } = useTranslation();

    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const defaultCreditCard = useMemo(
        () => customer?.StripeCreditCards?.find((c) => c.Default === true),
        [customer]
    );

    const cardDescriptionComponent = useMemo(() => {
        if (!defaultCreditCard) {
            return null;
        }

        if (defaultCreditCard?.Expired) {
            return (
                <span className={styles.error}>
                    {t("errors:primary-payment-method-expired-error")}
                </span>
            );
        }

        const { Brand, LastFour } = defaultCreditCard;
        const description = `${Brand} ${t("misc:ending-in")} ${LastFour}`;
        const brandIcon = <PaymentBrandIcon brandSlug={Brand} />;

        return (
            <>
                {brandIcon}
                {description}
            </>
        );
    }, [defaultCreditCard, t]);

    const nextPaymentNode = useMemo(() => {
        if (!currentEntitlement && !activePlan?.subscription) return <></>;

        let nextPaymentString = "";
        let renewalText = "";

        if (!activePlan?.subscription) {
            renewalText = t("subscription:active-until");

            nextPaymentString = format(
                new Date(currentEntitlement?.ExpiresAt),
                "EEEE, MMMM d, yyyy",
                { locale: i18n.language === "es" ? es : enUS }
            );
        } else {
            // Determine the renewal text based on the subscription status
            const { CancelAtPeriodEnd, CanceledAt, TrialUntil, ActiveUntil } =
                activePlan?.subscription;

            if (isPastDue) {
                return (
                    <p className={styles.error}>{t("errors:past-due-error")}</p>
                );
            }

            renewalText = `${t("subscription:next-charge")}: `;

            if (TrialUntil === ActiveUntil) {
                renewalText = `${t("subscription:trial-ends")}: `;
            }

            if (CancelAtPeriodEnd || !!CanceledAt) {
                renewalText = `${t("subscription:cancellation")}: `;
            }

            if (defaultCreditCard?.Expired) {
                renewalText = `${t("subscription:update-before")}: `;
            }

            nextPaymentString = `${format(
                new Date(
                    upcomingDraftInvoice?.next_payment_attempt ??
                        activePlan?.subscription?.ActiveUntil
                ),
                "EEEE, MMMM d, yyyy",
                { locale: i18n.language === "es" ? es : enUS }
            )}`;
        }

        return (
            <p>
                <span>{renewalText}</span>
                {nextPaymentString}
            </p>
        );
    }, [
        activePlan,
        currentEntitlement,
        defaultCreditCard?.Expired,
        i18n.language,
        isPastDue,
        t,
        upcomingDraftInvoice
    ]);

    return (
        <>
            <Widget
                title={t("subscription-page:payment-details").toUpperCase()}
                details={[
                    {
                        title: cardDescriptionComponent,
                        children: <>{nextPaymentNode}</>
                    }
                ]}
                button={
                    <button
                        className={`btn ${
                            defaultCreditCard?.Expired
                                ? "btn-primary"
                                : "btn-outline-secondary"
                        }`}
                        onClick={() => setModalOpen(true)}
                    >
                        {t("subscription-page:update-payment-method")}
                    </button>
                }
                variant="billing"
            />
            <SetupIntentElement>
                {modalOpen && (
                    <ManagePaymentDetails
                        customer={customer}
                        isOpen={modalOpen}
                        setIsOpen={setModalOpen}
                        onUpdate={refetchCustomer}
                    />
                )}
            </SetupIntentElement>
        </>
    );
};
