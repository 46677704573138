import {
    BroadcastDetailsResponseBroadcastStatus as FrameworkBroadcastStatus,
    BroadcastStatus
} from "@switcherstudio/switcher-api-client";
import { BroadcastStatuses as CoreBroadcastStatus } from "@switcherstudio/api-core-client";

export const isCoreBroadcastStatus = (
    status: FrameworkBroadcastStatus | CoreBroadcastStatus
): boolean => {
    return typeof status === "string";
};

export const isFrameworkBroadcastStatus = (
    response: FrameworkBroadcastStatus | CoreBroadcastStatus
): boolean => !isCoreBroadcastStatus(response);

export const mapBroadcastResponseStatusToBroadcastStatus = (
    status: FrameworkBroadcastStatus | CoreBroadcastStatus
): BroadcastStatus =>
    isFrameworkBroadcastStatus(status)
        ? mapFrameworkBroadcastStatusToBroadcastStatus(
              status as FrameworkBroadcastStatus
          )
        : mapCoreBroadcastStatusToBroadcastStatus(
              status as CoreBroadcastStatus
          );

export const mapFrameworkBroadcastStatusToBroadcastStatus = (
    status: FrameworkBroadcastStatus
): BroadcastStatus => {
    if (!status) {
        return null;
    }

    return status as unknown as BroadcastStatus;
};

export const mapCoreBroadcastStatusToBroadcastStatus = (
    status: CoreBroadcastStatus
): BroadcastStatus => {
    if (!status) {
        return null;
    }

    switch (status) {
        case "Created":
            return BroadcastStatus._0;

        case "Ready":
            return BroadcastStatus._1;

        case "Active":
            return BroadcastStatus._2;

        case "Ended":
            return BroadcastStatus._3;

        case "Archived":
            return BroadcastStatus._4;

        case "Unpublished":
            return BroadcastStatus._5;
    }
};

export const mapBroadcastStatusToFrameworkBroadcastStatus = (
    status: BroadcastStatus
): FrameworkBroadcastStatus => status as any as FrameworkBroadcastStatus;

export const mapBroadcastStatusToCoreBroadcastStatus = (
    status: BroadcastStatus
): CoreBroadcastStatus => {
    switch (status) {
        case BroadcastStatus._0:
            return "Created";

        case BroadcastStatus._1:
            return "Ready";

        case BroadcastStatus._2:
            return "Active";

        case BroadcastStatus._3:
            return "Ended";

        case BroadcastStatus._4:
            return "Archived";

        case BroadcastStatus._5:
            return "Unpublished";
    }
};
