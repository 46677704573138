import { useGetPagedBroadcastByFilter } from "hooks/useGetPagedBroadcastByFilter";
import { usePageHeader } from "hooks/usePageHeader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VideoLibraryBroadcastList } from "../video-library-v2/VideoLibraryBroadcastList";
import { SearchSortOptions } from "components/generic-multiselect/types";
import { SortOptions } from "components/inputs/search-sort-bar";
import { useMemo } from "react";
import { Link } from "components/buttons/Link";
import { Button } from "components/buttons/Button";
import styles from "./index.module.scss";

export const LivestreamsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    usePageHeader({
        subTitle: (
            <>
                <p>
                    {t("livestreams-page:subtitle-line1")}{" "}
                    <Link
                        to="https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t("livestreams-page:subtitle-link")}
                    </Link>
                    <br />
                    {t("livestreams-page:subtitle-line2")}
                </p>
            </>
        )
    });

    const { refetchVideos, firstLoading, upcomingLivestreams } =
        useGetPagedBroadcastByFilter({
            videoStatusType: "Livestream"
        });

    const searchSortOptions = useMemo<SearchSortOptions>(
        () => ({
            showSearchSort: true,
            implementationType: "broadcast-response-search-sort",
            defaultSortOption: SortOptions.Next,
            hiddenSortOptions: [
                SortOptions.NewestFirst,
                SortOptions.OldestFirst,
                SortOptions.LeastViews,
                SortOptions.MostViews,
                SortOptions.TitleAZ,
                SortOptions.TitleZA
            ]
        }),
        []
    );

    return (
        <>
            <div className={styles["new-stream-button-container"]}>
                <Button
                    type="primary"
                    onClick={() =>
                        navigate("/platforms/simulcast/destinations")
                    }
                >
                    {t("platforms:schedule-new")}
                </Button>
            </div>
            <VideoLibraryBroadcastList
                broadcasts={upcomingLivestreams}
                refetchVideos={refetchVideos}
                firstLoading={firstLoading}
                searchSortOptions={searchSortOptions}
                emptyStateCopy={t("livestreams-page:no-livestreams")}
                onEdit={(broadcast) =>
                    navigate(
                        `/scheduled-livestreams/broadcast/${broadcast.Details.Id}`
                    )
                }
            />
        </>
    );
};
