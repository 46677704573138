import { Dispatch, SetStateAction } from "react";
import { ModalBase } from "../ModalBase";
import styles from "../CreateOrUpdateSwitcherPlayerModal/index.module.scss";
import mpstyles from "./index.module.scss";
import {
    CreatorProduct,
    CreatorProductPrice,
    CreatorProductPriceEntitledDurationUnit
} from "@switcherstudio/switcher-api-client";
import GatedContentCards from "components/radio-cards/gated-content-cards";
import { StringDictionary } from "types";
import { useTranslation } from "react-i18next";

export interface GatedContentPassFormProps {
    name: string;
    description: string;
    selectedPassType: "one-time" | "recurring";
    recurringMonthlyPriceSelected: boolean;
    recurringAnnualPriceSelected: boolean;
    oneTimePriceSelected?: boolean;
    oneTimePrice: string;
    oneTimeDurationSelected: boolean;
    oneTimeDuration?: string;
    oneTimeDurationUnits?: CreatorProductPriceEntitledDurationUnit;
    recurringMonthlyPrice: string;
    recurringAnnualPrice: string;
    recurringApplySubscriptionUpdatesNewOnly: boolean;
    oldProduct: CreatorProduct;
    oldRecurringMonthlyPrice: CreatorProductPrice;
    oldRecurringAnnualPrice: CreatorProductPrice;
    oldOneTimePrice: CreatorProductPrice;
}

export interface GatedContentModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    type: PricingModalTypes;
    onSubmit: () => void;
    processing: boolean;
    submitDisabled: boolean;
    values: GatedContentPassFormProps;
    errors: StringDictionary;
    onPriceChange: (key: string, val: any) => void;
    hasRecurringPaymentsClaim: boolean;
    untimedOTPInitiallySelected: boolean;
}

export enum PricingModalTypes {
    Create,
    Update
}

export const GatedContentModal = ({
    isOpen,
    setIsOpen,
    type,
    onSubmit,
    processing,
    submitDisabled,
    values,
    errors,
    onPriceChange,
    hasRecurringPaymentsClaim,
    untimedOTPInitiallySelected
}: GatedContentModalProps) => {
    const { t } = useTranslation();

    if (!values) return null;

    return (
        <>
            <ModalBase
                preventDismiss={processing}
                onDismiss={() => setIsOpen(false)}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={
                    type === PricingModalTypes.Create
                        ? t("gated-content-modal:create-title")
                        : t("gated-content-modal:edit-title")
                }
                successButton={
                    type === PricingModalTypes.Create
                        ? t("buttons:create")
                        : t("buttons:save")
                }
                onSuccess={() => onSubmit()}
                successDisabled={submitDisabled}
                shouldNotCloseOnSuccess
                dismissButton={t("buttons:cancel")}
            >
                <>
                    <div className={styles["content"]}>
                        <div className={styles["label-row"]}>
                            <label htmlFor="name">
                                {t("switcher-cloud:name")}
                            </label>

                            <small>{`${values.name?.length ?? 0} / 25`}</small>
                        </div>
                        <input
                            id="name"
                            type="text"
                            className={`${styles["name-input"]} form-control`}
                            value={values.name}
                            maxLength={25}
                            placeholder={t(
                                "gated-content-modal:name-placeholder"
                            )}
                            onChange={(e) => {
                                onPriceChange("name", e?.currentTarget?.value);
                            }}
                        />
                        {errors.name && (
                            <div className={mpstyles["sub-error"]}>
                                {errors.name}
                            </div>
                        )}
                        <div className={styles["label-row"]}>
                            <label htmlFor="description">
                                {t("gated-content-modal:description")}
                            </label>

                            <small>{`${
                                values.description?.length ?? 0
                            } / 500`}</small>
                        </div>
                        <textarea
                            id="description"
                            className={`${styles["description-input"]} form-control`}
                            value={values.description}
                            maxLength={500}
                            placeholder={t(
                                "gated-content-modal:description-placeholder"
                            )}
                            onChange={(e) => {
                                onPriceChange(
                                    "description",
                                    e?.currentTarget?.value
                                );
                            }}
                        />
                        <div className={styles["label-row"]}>
                            <label htmlFor="pass-type">
                                {t("gated-content-modal:pricing-type")}
                            </label>
                        </div>
                        {hasRecurringPaymentsClaim ? (
                            <GatedContentCards
                                formValues={values}
                                errors={errors}
                                onPassChange={onPriceChange}
                                type={type}
                                selectionLocked={
                                    type === PricingModalTypes.Update
                                }
                                shouldShowOneTimePassOption={true}
                                untimedOTPInitiallySelected={
                                    untimedOTPInitiallySelected
                                }
                            />
                        ) : (
                            <>
                                <div
                                    id="pricingType"
                                    className={`${mpstyles["pricing-upper"]}`}
                                >
                                    <div>
                                        <span
                                            className={mpstyles["pricing-type"]}
                                        >
                                            {t(
                                                "gated-content-modal:pay-per-view"
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div
                                    id="pricingType-lower"
                                    className={`${mpstyles["pricing-lower"]}`}
                                >
                                    <div className={styles["label-row"]}>
                                        <label htmlFor="name">{"Price"}</label>
                                    </div>
                                    <div className={mpstyles["price-row"]}>
                                        <span
                                            className={
                                                mpstyles["price-currency"]
                                            }
                                        >
                                            US$
                                        </span>
                                        <input
                                            id="price"
                                            type="number"
                                            min="2.00"
                                            className={`form-control ${mpstyles["price-input"]}`}
                                            value={values.oneTimePrice}
                                            placeholder={t(
                                                "gated-content-modal:price-placeholder"
                                            )}
                                            onChange={(e) => {
                                                onPriceChange(
                                                    "oneTimePrice",
                                                    e?.currentTarget?.value
                                                );
                                            }}
                                        />
                                    </div>
                                    {errors.oneTimePrice && (
                                        <div className={mpstyles["sub-error"]}>
                                            {errors.oneTimePrice}
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            </ModalBase>
        </>
    );
};
