import { Switch } from "components/utility/Switch";
import styles from "./index.module.scss";
import { Case } from "components/utility/Case";

export type SpinnerVariantOptions = "ring";

// SVG Source - https://github.com/n3r4zzurr0/svg-spinners/tree/main
export interface CircularProgressLoaderProps {
    variant?: SpinnerVariantOptions;
    size?: number;
    stroke?: string;
    strokeWidth?: number;
    alignment?: "center" | "left" | "right";
    progress?: number;
}
export const CircularProgressLoader = ({
    variant = "ring",
    size = 64,
    stroke = "#000",
    strokeWidth = 1,
    alignment = "center",
    progress = 0
}: CircularProgressLoaderProps) => {
    const radius = 9.5;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <div className={`spinner ${styles["container"]} ${styles[alignment]}`}>
            <svg
                stroke={stroke}
                width={size}
                height={size}
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Switch test={variant}>
                    <Case<SpinnerVariantOptions> value="ring">
                        <g className={styles["ring"]}>
                            {/* Background Circle */}
                            <circle
                                cx="12"
                                cy="12"
                                r={radius}
                                fill="none"
                                strokeWidth={strokeWidth}
                                stroke="lightgray"
                            ></circle>
                            {/* Progress Tracking Circle */}
                            <circle
                                cx="12"
                                cy="12"
                                r={radius}
                                fill="none"
                                strokeWidth={strokeWidth}
                                strokeDasharray={circumference}
                                strokeDashoffset={strokeDashoffset}
                            ></circle>
                        </g>
                    </Case>
                    <></>
                </Switch>
            </svg>
        </div>
    );
};
