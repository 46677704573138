import { useTranslation } from "react-i18next";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import mpstyles from "./index.module.scss";
import { CreatorProduct } from "@switcherstudio/switcher-api-client";
import { useMemo } from "react";
import { useUserStripeData } from "hooks/useUserStripeData";

interface ActiveSubscribersTableProps {
    creatorProduct: CreatorProduct;
}

export const ActiveSubscribersTable: React.FC<ActiveSubscribersTableProps> = ({
    creatorProduct
}) => {
    const { t } = useTranslation();

    const { accountData } = useUserStripeData({
        requestImmediately: true,
        includeProducts: false
    });

    const stripeAccountId = accountData?.account?.Id;

    const hasRecurringActivePrices = useMemo(() => {
        return creatorProduct?.Prices.some(
            (price) => price.IsRecurring && price.Active
        );
    }, [creatorProduct]);

    const { data } = useSwitcherClient(
        (client) =>
            client.creatorProducts_GetProductActiveSubscriberCountByPrice,
        {
            requestImmediately: true,
            args: [stripeAccountId, creatorProduct?.Id]
        }
    );

    const priceSubs = data?.PriceSubscriptions ?? [];

    const { monthly, annual } = priceSubs.reduce(
        (acc, { Interval, Subscriptions }) => {
            if (Interval === "month") {
                acc.monthly += Subscriptions;
            } else if (Interval === "year") {
                acc.annual += Subscriptions;
            }
            return acc;
        },
        { monthly: 0, annual: 0 }
    );

    const purchases = creatorProduct.Purchases ?? 0;

    const tableContent = useMemo(() => {
        if (hasRecurringActivePrices) {
            return (
                <>
                    <thead>
                        <tr>
                            <th>{t("subscription:active-monthly-subs")}</th>
                            <th>{t("subscription:active-annual-subs")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{monthly}</td>
                            <td>{annual}</td>
                        </tr>
                    </tbody>
                </>
            );
        }

        return (
            <>
                <thead>
                    <tr>
                        <th>{t("subscription:one-time-purchases")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{purchases}</td>
                    </tr>
                </tbody>
            </>
        );
    }, [hasRecurringActivePrices, t, monthly, annual, purchases]);

    return !stripeAccountId || !creatorProduct?.Id ? null : (
        <table className={mpstyles.subsTable}>{tableContent}</table>
    );
};
