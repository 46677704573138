import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useSwitcherClientCore } from "./useSwitcherClient";
import {
    BroadcastResponse,
    BroadcastsPagedResponse,
    VideoStatusType
} from "@switcherstudio/api-core-client";
import { checkBroadcastType } from "helpers/broadcastHelpers";

export interface useGetPagedBroadcastByFilterOptions {
    videoStatusType?: VideoStatusType;
    preFetch?: () => void;
    onSuccess?: (
        data: BroadcastsPagedResponse,
        transformedData: unknown
    ) => Promise<void> | void;
}

export const useGetPagedBroadcastByFilter = ({
    videoStatusType = "All",
    preFetch,
    onSuccess
}: useGetPagedBroadcastByFilterOptions) => {
    // Redux state
    const {
        userInfo: { ProjectId }
    } = useSelector((state: RootState) => state.user);

    // Fetch data
    const {
        dispatchApiRequest: refetchVideos,
        loading,
        error
    } = useSwitcherClientCore(
        (client) => client.broadcasts_GetPagedBroadcastsByFilter,
        {
            args: [ProjectId, 1, 0, "Project", videoStatusType],
            requestImmediately: true,
            preFetch,
            onSuccess: (res) => {
                setIsFirstLoad(false);
                setAllBroadcasts(res.Data[0].Broadcasts);
                onSuccess?.(res, res.Data[0].Broadcasts);
            }
        }
    );

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [allBroadcasts, setAllBroadcasts] = useState<BroadcastResponse[]>([]);

    // Local state
    const { firstLoading, reloading } = useMemo(
        () => ({
            firstLoading: loading && isFirstLoad,
            reloading: loading && !isFirstLoad
        }),
        [loading, isFirstLoad]
    );

    const {
        existingBroadcasts,
        scheduledBroadcasts,
        upcomingLivestreams,
        unpublishedBroadcasts
    } = useMemo((): {
        [key: string]: BroadcastResponse[];
    } => {
        const existingBroadcasts = [];
        const scheduledBroadcasts = [];
        const upcomingLivestreams = [];
        const unpublishedBroadcasts = [];

        if (!allBroadcasts || !allBroadcasts.length) {
            return {
                existingBroadcasts,
                scheduledBroadcasts,
                upcomingLivestreams,
                unpublishedBroadcasts
            };
        }

        for (const broadcast of allBroadcasts) {
            if (!!broadcast.Details.InputEffectiveDeletionDate) {
                continue;
            }

            const {
                isPremiere,
                isScheduledLivestream,
                isExistingContent,
                isUnpublished
            } = checkBroadcastType(broadcast);

            if (isScheduledLivestream) {
                upcomingLivestreams.push(broadcast);
            } else if (isPremiere) {
                scheduledBroadcasts.push(broadcast);
            } else if (isExistingContent) {
                existingBroadcasts.push(broadcast);
            } else if (isUnpublished) {
                unpublishedBroadcasts.push(broadcast);
            }
        }

        return {
            existingBroadcasts,
            scheduledBroadcasts,
            upcomingLivestreams,
            unpublishedBroadcasts
        };
    }, [allBroadcasts]);

    return {
        existingBroadcasts,
        scheduledBroadcasts,
        upcomingLivestreams,
        unpublishedBroadcasts,
        firstLoading,
        reloading,
        refetchVideos,
        error
    };
};
