import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { exists } from "helpers/booleans";

export interface UpgradeButtonProps {
    needsUpgrade?: boolean;
    isLapsedOrPaused?: boolean;
    alternateText?: string;
    alternateRoute?: string;
    buttonStyle?: "btn-primary" | "btn-outline-secondary";
    loading?: boolean;
}

export const UpgradeButton: React.FC<UpgradeButtonProps> = ({
    needsUpgrade = false,
    isLapsedOrPaused = false,
    alternateText,
    alternateRoute,
    buttonStyle = "btn-primary",
    loading = false
}: UpgradeButtonProps) => {
    const { t } = useTranslation("buttons");
    const navigate = useNavigate();

    const buttonText = useMemo<string>(() => {
        if (loading) {
            return t("loading").toUpperCase();
        }
        if (alternateText && !needsUpgrade) {
            return alternateText;
        }
        return t("upgrade-account").toUpperCase();
    }, [alternateText, needsUpgrade, t, loading]);

    const buttonRoute = useMemo<string>(() => {
        if (!needsUpgrade && alternateRoute) {
            return alternateRoute;
        }
        return "/subscription#openModal";
    }, [alternateRoute, needsUpgrade]);

    return (
        <button
            className={`btn ${buttonStyle}`}
            type="button"
            disabled={
                (!needsUpgrade || isLapsedOrPaused) && !exists(alternateRoute)
            }
            onClick={() => navigate(buttonRoute)}
        >
            {buttonText}
        </button>
    );
};
