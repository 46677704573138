import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetApiCache } from "store/api/slice";
import { resetEvents } from "store/events/slice";
import { resetNotifications } from "store/notification/slice";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { cancelUploads } from "store/uploads/slice";
import { logout } from "store/user/slice";
import { useSupportSystem } from "./useSupportSystem";
import { cancelUploadSessions } from "store/videoUploadSession/thunks";
import { resetModals } from "store/modal/slice";
import { resetDownloads } from "store/downloads/slice";

export interface useLogoutOptions {
    onLogout?: () => void;
}

/**
 * Custom hook to handle user logout process.
 *
 * This hook manages the logout workflow, including resetting various
 * parts of the application state, canceling ongoing uploads, and
 * navigating the user upon successful logout.
 *
 * @param {useLogoutOptions} options - Optional callbacks to execute on logout.
 * @returns {void}
 */
export const useLogout = (
    { onLogout }: useLogoutOptions = { onLogout: () => {} }
) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { supportSystemCommand } = useSupportSystem();

    const { uploadSessions } = useSelector(
        (state: RootState) => state.videoUploadSession
    );
    const pending = useSelector((state: RootState) => state.downloads.pending);

    /**
     * Determines if there are any active uploads in the current upload sessions.
     *
     * @returns {boolean} True if there is at least one active upload, otherwise false.
     */
    const hasActiveUpload = useMemo<boolean>(() => {
        for (const sessionId in uploadSessions) {
            const session = uploadSessions[sessionId];

            if (session?.uploadsStarted && !session?.allComplete) {
                return true;
            }
        }

        return false;
    }, [uploadSessions]);

    const hasDownloads = useMemo<boolean>(() => {
        return pending.length > 0;
    }, [pending.length]);

    const confirmLogout = useCallback(async () => {
        if (
            hasActiveUpload &&
            !window.confirm(t("warnings:pending-uploads-logout-warning"))
        ) {
            return false;
        } else if (
            hasDownloads &&
            !window.confirm(t("warnings:pending-downloads-logout-warning"))
        ) {
            return false;
        } else {
            return true;
        }
    }, [hasActiveUpload, hasDownloads, t]);

    /**
     * Handle the logout process.
     */
    const handleLogout = useCallback(async () => {
        if (!confirmLogout()) {
            return;
        }

        onLogout();

        // Reset the downloads state
        dispatch(resetDownloads());

        // Cancel all upload sessions and uploads
        await dispatch(cancelUploadSessions());
        dispatch(cancelUploads());

        // Support system command to shutdown
        supportSystemCommand("shutdown");

        // Reset the application state
        dispatch(resetNotifications());
        dispatch(resetApiCache());
        dispatch(resetEvents());
        dispatch(resetModals());
        dispatch(logout());

        navigate("/login");
    }, [dispatch, navigate, onLogout, supportSystemCommand, confirmLogout]);

    return {
        handleLogout
    };
};
