import { PayloadAction, Reducer, createSlice } from "@reduxjs/toolkit";
import { DownloadsState } from "./types";
import { DownloadProps } from "hooks/useVideoDownload";

const initialState: DownloadsState = {
    pending: [],
    ready: [],
    pendingDownload: [],
    downloadProgress: {}
};

export const downloads = createSlice({
    name: "downloads",
    initialState,
    reducers: {
        addPending: (state, { payload }: PayloadAction<DownloadProps>) => {
            if (!state.pending.find((dl) => dl.videoId === payload.videoId)) {
                state.pending = [...state.pending, payload];
            }
        },
        removePending: (state, { payload }: PayloadAction<DownloadProps>) => {
            state.pending = state.pending.filter(
                (dl) => dl.videoId !== payload.videoId
            );
        },
        moveToPendingDownload: (
            state,
            { payload }: PayloadAction<DownloadProps>
        ) => {
            state.pending = state.pending.filter(
                (dl) => dl.videoId !== payload.videoId
            );
            state.pendingDownload = [...state.pendingDownload, payload];
        },
        moveToReady: (state, { payload }: PayloadAction<DownloadProps>) => {
            state.pendingDownload = state.pendingDownload.filter(
                (dl) => dl.videoId !== payload.videoId
            );
            state.ready = [...state.ready, payload];
        },
        updateDownloadProgress: (
            state,
            {
                payload
            }: PayloadAction<{ videoId: string; percentComplete: number }>
        ) => {
            state.downloadProgress[payload.videoId] = payload.percentComplete;
        },
        resetDownloads: () => initialState
    }
});

export const {
    addPending,
    removePending,
    moveToPendingDownload,
    moveToReady,
    updateDownloadProgress,
    resetDownloads
} = downloads.actions;

export default downloads.reducer as Reducer<DownloadsState>;
