import { usePageHeader } from "hooks/usePageHeader";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import { Toggle } from "components/inputs/toggle/Toggle";
import { useCallback, useEffect, useState } from "react";
import { EmbedPreview } from "components/embed-preview";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import {
    FeaturedVideoType,
    SpecificVideo
} from "../../CollectionPage/CollectionDefaultDisplayPage/SpecificVideo";
import { useCatalogAccessBanner } from "../../hooks/useCatalogAccessBanner";
import { useParams } from "hooks/useParams";
import { useSettableMemo } from "hooks/useSettableMemo";
import {
    VideoPlayerAspectRatio,
    VideoPlayerEmbeddedDisplay,
    VideoPlayerIdleState
} from "@switcherstudio/switcher-api-client";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { AppDispatch } from "store/store";
import rollbar from "helpers/rollbar";
import { exists } from "helpers/booleans";

export const CollectionFeaturedTrailerPage: React.FC = () => {
    useCatalogAccessBanner();
    const { videoPlayerId } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation("featured-trailer-page");

    const { userInfo } = useSelector((s: RootState) => s.user);
    const [selectedBroadcastId, setSelectedBroadcastId] = useState<string>();
    const [embedPreviewKey, setEmbedPreviewKey] = useState(0);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const { data: selectedVideoPlayer } = useSwitcherClient(
        (client) => client.videoPlayers_GetVideoPlayer,
        {
            requestImmediately: true,
            args: [videoPlayerId]
        }
    );

    const [videoPlayer, setVideoPlayer] = useSettableMemo(
        () => selectedVideoPlayer?.VideoPlayer,
        [selectedVideoPlayer]
    );

    const [statusToggle, setStatusToggle] = useSettableMemo(
        () => videoPlayer?.IdleState,
        [videoPlayer]
    );

    const showFeaturedTrailer = statusToggle === VideoPlayerIdleState._3;

    const {
        loading: putVideoPlayerLoading,
        dispatchApiRequest: putVideoPlayer
    } = useSwitcherClient(
        (client) => client.projectsVideoPlayer_PutVideoPlayer,
        {
            requestImmediately: false,
            onSuccess: (data): void => {
                setVideoPlayer(data);
                setEmbedPreviewKey((prevKey) => prevKey + 1);
            }
        }
    );

    useEffect(() => {
        setIsSaving(putVideoPlayerLoading);
    }, [putVideoPlayerLoading]);

    usePageHeader({
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("collection-page:collections-settings"),
            t("featured-trailer")
        ],
        title: t("featured-trailer-page:featured-trailer"),
        autoSave: isSaving,
        subTitle: (
            <Trans
                i18nKey={t("collection-page:featured-trailer-description")}
                components={{
                    link1: (
                        <Link
                            to={"/catalog/featured-trailer"}
                            title="Featured Trailer"
                            className={styles["link"]}
                        />
                    )
                }}
            />
        )
    });

    const handleSelectBroadcast = useCallback(
        async (broadcastId: string) => {
            setSelectedBroadcastId(broadcastId);

            try {
                await putVideoPlayer([
                    videoPlayer?.Id,
                    {
                        VideoPlayer: {
                            ...videoPlayer,
                            IdleBroadcastId: broadcastId
                        }
                    },
                    userInfo?.ProjectId
                ]);
            } catch (e) {
                rollbar.error(
                    "Error updating video player status to next event",
                    e
                );
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("player-status-page:errors:next-live")
                    })
                );
            }
        },
        [
            dispatch,
            putVideoPlayer,
            setSelectedBroadcastId,
            t,
            userInfo?.ProjectId,
            videoPlayer
        ]
    );

    const handleChange = useCallback(
        async (_statusToggle: VideoPlayerIdleState) => {
            if (statusToggle === _statusToggle) return;

            setStatusToggle(_statusToggle);

            try {
                await putVideoPlayer([
                    videoPlayer?.Id,
                    {
                        VideoPlayer: exists(videoPlayer)
                            ? {
                                  ...videoPlayer,
                                  IdleState: _statusToggle
                              }
                            : {
                                  IdleState: _statusToggle,
                                  AspectRatio: videoPlayer?.AspectRatio
                              }
                    },
                    userInfo?.ProjectId
                ]);
            } catch (e) {
                rollbar.error(
                    "Error updating video player status to next event",
                    e
                );
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t("player-status-page:errors:next-live")
                    })
                );
            }
        },
        [
            dispatch,
            putVideoPlayer,
            setStatusToggle,
            statusToggle,
            t,
            userInfo?.ProjectId,
            videoPlayer
        ]
    );

    return (
        <>
            <div className="row">
                <div className="col-xl-4">
                    <Toggle
                        on={showFeaturedTrailer}
                        onToggle={() =>
                            handleChange(showFeaturedTrailer ? 2 : 3)
                        }
                        label={t(
                            "featured-trailer-page:featured-trailer-toggle"
                        )}
                        className={styles["toggle"]}
                        reverseLayout
                    />

                    <div className={styles["featured-trailer"]}>
                        <SpecificVideo
                            selectedBroadcastId={
                                selectedBroadcastId ||
                                videoPlayer?.IdleBroadcastId
                            }
                            onSelectBroadcastId={handleSelectBroadcast}
                            featuredVideoType={FeaturedVideoType.Catalog}
                            videoPlayerId={videoPlayerId}
                            disabled={!showFeaturedTrailer}
                        />
                    </div>
                </div>
                <div className="col-xl-8">
                    <EmbedPreview
                        embedString={selectedVideoPlayer?.EmbedCode || ""}
                        playerId={videoPlayerId}
                        key={embedPreviewKey}
                        isVertical={
                            selectedVideoPlayer?.VideoPlayer
                                ?.EmbeddedDisplay ===
                                VideoPlayerEmbeddedDisplay._0 &&
                            selectedVideoPlayer?.VideoPlayer?.AspectRatio ===
                                VideoPlayerAspectRatio.NineBySixteen
                        }
                        hasMaxHeight={
                            selectedVideoPlayer?.VideoPlayer?.AspectRatio ===
                            VideoPlayerAspectRatio.NineBySixteen
                        }
                    />
                </div>
            </div>
        </>
    );
};
