import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDebounce } from "hooks/useDebounce";
import { BroadcastsHashItem } from "components/modal/AddVideosModal";
import { isInFuture, sortByDate } from "helpers/time";
import { FilterOptions, SearchSortBar, SortOptions } from ".";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import {
    BroadcastStatus,
    CategoryType,
    VideoPlayerCloudflareResponse
} from "@switcherstudio/switcher-api-client";
import { exists } from "helpers/booleans";
import { HashItem } from "components/generic-multiselect/types";
import { useTranslation } from "react-i18next";
import { useClaimCheck } from "hooks/useClaimCheck";

export interface BroadcastsSearchSortBarProps {
    broadcasts:
        | BroadcastsHashItem[]
        | VideoPlayerCloudflareResponse[]
        | HashItem<VideoPlayerCloudflareResponse>[];
    handleSort: (
        broadcasts:
            | BroadcastsHashItem[]
            | VideoPlayerCloudflareResponse[]
            | HashItem<VideoPlayerCloudflareResponse>[]
    ) => void;
    location?: "video-library" | "player-playlist";
}

const isBroadcastHashItem = (
    item:
        | BroadcastsHashItem
        | VideoPlayerCloudflareResponse
        | HashItem<VideoPlayerCloudflareResponse>
): item is BroadcastsHashItem => {
    if ((item as BroadcastsHashItem)?.videoResponse) return true;
    return false;
};

const isGenericHashItem = (
    item:
        | HashItem<VideoPlayerCloudflareResponse>
        | BroadcastsHashItem
        | VideoPlayerCloudflareResponse
): item is HashItem<VideoPlayerCloudflareResponse> => {
    if ((item as HashItem<VideoPlayerCloudflareResponse>)?.item) return true;
    return false;
};

export const BroadcastsSearchSortBar = ({
    broadcasts,
    handleSort,
    location
}: BroadcastsSearchSortBarProps) => {
    const { t } = useTranslation("broadcasts-multi-select");
    const [selectedSortOption, setSelectedSortOption] =
        useState("newest-first");
    const [selectedCategoryOption, setSelectedCategoryOption] =
        useState<string>("all");
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>(
        FilterOptions.All
    );
    const [query, setQuery] = useState("");
    const debouncedQuery = useDebounce(query, 500);

    const userInfo = useSelector((s: RootState) => s.user?.userInfo);
    const hasCatalogClaim = useClaimCheck("catalog");

    const { data: userCategories, dispatchApiRequest: categoriesRequest } =
        useSwitcherClient((client) => client.categories_GetCategories, {
            requestImmediately: true,
            args: [userInfo?.ProjectId, CategoryType._0]
        });

    const categoryOptions = useMemo(() => {
        const all = {
            value: "all",
            text: hasCatalogClaim
                ? t("broadcasts-multi-select:all-tags")
                : t("broadcasts-multi-select:all-categories")
        };
        return !exists(userCategories)
            ? [all]
            : [
                  all,
                  ...userCategories?.Categories?.map((category) => {
                      return { value: category.Id, text: category.Name };
                  })
              ];
    }, [t, userCategories, hasCatalogClaim]);

    useEffect(() => {
        categoriesRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [broadcasts]);

    const sortedBroadcasts = useMemo(() => {
        if (!broadcasts) return [];
        return [...broadcasts].sort((a, b) => {
            const viewsA =
                // @ts-expect-error - Handle multiple types for views
                a.item?.baseObject?.entitySummary?.TotalViews ??
                // @ts-expect-error - Handle multiple types for views
                a.metrics?.TotalViews ??
                // @ts-expect-error - Handle multiple types for views
                a.entitySummary?.TotalViews ??
                0;
            const viewsB =
                // @ts-expect-error - Handle multiple types for views
                b.item?.baseObject?.entitySummary?.TotalViews ??
                // @ts-expect-error - Handle multiple types for views
                b.metrics?.TotalViews ??
                // @ts-expect-error - Handle multiple types for views
                b.entitySummary?.TotalViews ??
                0;

            const broadcastA = isGenericHashItem(a)
                ? a.item?.baseObject?.broadcast
                : a.broadcast;
            const broadcastB = isGenericHashItem(b)
                ? b.item?.baseObject?.broadcast
                : b.broadcast;

            switch (selectedSortOption) {
                case SortOptions.NewestFirst:
                    return sortByDate(
                        broadcastA?.CreatedAt,
                        broadcastB?.CreatedAt,
                        { descending: true }
                    );
                case SortOptions.OldestFirst:
                    return sortByDate(
                        broadcastA?.CreatedAt,
                        broadcastB?.CreatedAt,
                        { descending: false }
                    );
                case SortOptions.TitleAZ:
                    return broadcastA?.Title.toLocaleLowerCase() >
                        broadcastB?.Title?.toLocaleLowerCase()
                        ? 1
                        : -1;
                case SortOptions.TitleZA:
                    return broadcastA?.Title.toLocaleLowerCase() <
                        broadcastB?.Title?.toLocaleLowerCase()
                        ? 1
                        : -1;
                case SortOptions.MostViews:
                    return viewsA < viewsB ? 1 : -1;
                case SortOptions.LeastViews:
                    return viewsA > viewsB ? 1 : -1;
                default:
                    return sortByDate(
                        broadcastA?.CreatedAt,
                        broadcastB?.CreatedAt,
                        { descending: true }
                    );
            }
        });
    }, [broadcasts, selectedSortOption]);

    const filterBroadcasts = useCallback(
        (
            b:
                | BroadcastsHashItem
                | VideoPlayerCloudflareResponse
                | HashItem<VideoPlayerCloudflareResponse>
        ) => {
            const _b = isGenericHashItem(b) ? b.item.baseObject : b;
            switch (selectedFilterOption) {
                case FilterOptions.Gated:
                    return (
                        _b?.playerEntitlements.length ||
                        _b?.playlistBroadcastEntitlements.length
                    );

                case FilterOptions.NotGated:
                    return (
                        !_b?.playerEntitlements.length &&
                        !_b?.playlistBroadcastEntitlements.length
                    );

                case FilterOptions.InPlayer:
                    return _b?.players.length;

                case FilterOptions.NotInPlayer:
                    return !_b?.players.length;

                case FilterOptions.Shoppable:
                    return _b?.broadcast?.EnableLiveShopping.valueOf() === true;

                case FilterOptions.NotShoppable:
                    return (
                        _b?.broadcast?.EnableLiveShopping.valueOf() === false
                    );

                case FilterOptions.Published:
                    return (
                        _b?.broadcast?.BroadcastStatus !== BroadcastStatus._5 &&
                        !(
                            isInFuture(_b?.broadcast?.StartsAt) ||
                            _b?.broadcast?.ActiveAt === null
                        )
                    );

                case FilterOptions.Unpublished:
                    return (
                        _b?.broadcast?.BroadcastStatus === BroadcastStatus._5
                    );

                case FilterOptions.Scheduled:
                    return (
                        isInFuture(_b?.broadcast?.StartsAt) ||
                        _b?.broadcast?.ActiveAt === null
                    );

                default:
                    return true;
            }
        },
        [selectedFilterOption]
    );

    const _broadcasts = useMemo(() => {
        const isHashItem = isBroadcastHashItem(sortedBroadcasts?.[0]);
        const isGenericBroadcastHashItem = isGenericHashItem(
            sortedBroadcasts?.[0]
        );
        const broadcastHashItems = sortedBroadcasts as BroadcastsHashItem[];
        const genericBroadcastHashItems =
            sortedBroadcasts as HashItem<VideoPlayerCloudflareResponse>[];
        const videoPlayerCloudflareResponses =
            sortedBroadcasts as VideoPlayerCloudflareResponse[];

        if (
            !debouncedQuery &&
            selectedCategoryOption === "all" &&
            selectedFilterOption === FilterOptions.All
        ) {
            if (isHashItem) return broadcastHashItems;
            if (isGenericBroadcastHashItem) return genericBroadcastHashItems;
            return videoPlayerCloudflareResponses;
        }

        switch (true) {
            case isHashItem:
                return broadcastHashItems.filter(
                    (b) =>
                        (!debouncedQuery ||
                            b?.broadcast?.Title.toLocaleLowerCase().includes(
                                debouncedQuery.toLocaleLowerCase()
                            )) &&
                        (selectedCategoryOption === "all" ||
                            b?.broadcast?.Categories?.some(
                                (c) => c.Id === selectedCategoryOption
                            )) &&
                        filterBroadcasts(b)
                );
            case isGenericBroadcastHashItem:
                return genericBroadcastHashItems.filter(
                    (b) =>
                        (!debouncedQuery ||
                            b?.item?.baseObject?.broadcast?.Title.toLocaleLowerCase().includes(
                                debouncedQuery.toLocaleLowerCase()
                            )) &&
                        (selectedCategoryOption === "all" ||
                            b?.item?.baseObject?.broadcast?.Categories?.some(
                                (c) => c.Id === selectedCategoryOption
                            )) &&
                        filterBroadcasts(b)
                );
            default:
                return videoPlayerCloudflareResponses.filter((b) => {
                    const isQueryMatch =
                        !debouncedQuery ||
                        b?.broadcast?.Title.toLocaleLowerCase().includes(
                            debouncedQuery.toLocaleLowerCase()
                        );

                    const isCategoryMatch =
                        selectedCategoryOption === "all" ||
                        b?.broadcast?.Categories?.some(
                            (c) => c.Id === selectedCategoryOption
                        );

                    return (
                        isQueryMatch && isCategoryMatch && filterBroadcasts(b)
                    );
                });
        }
    }, [
        selectedFilterOption,
        sortedBroadcasts,
        debouncedQuery,
        selectedCategoryOption,
        filterBroadcasts
    ]);

    useEffect(() => {
        // Sort on broadcasts load/change
        handleSort(_broadcasts);

        // Do not include handleSort in dependencies to prevent infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_broadcasts]);

    return (
        <SearchSortBar
            categoryOptions={categoryOptions}
            setSelectedCategoryOption={setSelectedCategoryOption}
            selectedCategoryOption={selectedCategoryOption}
            setSelectedSortOption={setSelectedSortOption}
            selectedSortOption={selectedSortOption}
            setQuery={setQuery}
            query={query}
            showFilters={location === "video-library"}
            selectedFilterOption={selectedFilterOption}
            setSelectedFilterOption={setSelectedFilterOption}
            onClear={() => setQuery("")}
        />
    );
};
