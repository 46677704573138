import { exists } from "helpers/booleans";
import React from "react";
import styles from "./Select.module.scss";

interface SelectProps {
    label?: string;
    id: string;
    options: { [key: string]: string | number }[] | string[];
    optionKey?: string;
    optionLabel?: string;
    selected?: any;
    error?: string;
    className?: string;
    containerClassName?: string;
    parentClassName?: string;
    labelClassName?: string;
    horizontal?: boolean;
    disabled?: boolean;
    placeholder?: string;
    type?: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export const Select: React.FC<SelectProps> = ({
    label,
    id,
    options,
    optionLabel,
    optionKey,
    selected,
    error,
    className,
    containerClassName,
    parentClassName,
    labelClassName,
    horizontal,
    disabled,
    placeholder,
    type,
    onChange
}: SelectProps) => (
    <div
        className={`form-group select ${
            exists(label) && horizontal ? "row" : ""
        } ${containerClassName ? containerClassName : ""} ${
            containerClassName ? styles[containerClassName] : ""
        }`}
    >
        {exists(label) && (
            <label
                className={`${horizontal ? "col-xl-2 col-form-label" : ""} ${
                    labelClassName ? labelClassName : ""
                } ${styles["label"]}`}
                htmlFor={id}
            >
                {label}
            </label>
        )}
        <div
            className={`form-control-wrapper ${
                exists(label) && horizontal ? "col-xl-10" : ""
            } ${parentClassName ? parentClassName : ""} ${
                styles[parentClassName ? parentClassName : ""]
            } ${styles["dropdown"]}`}
        >
            <select
                className={`form-control ${className ?? ""} ${
                    styles["dropdown"]
                } ${type ? styles[type] : ""}`}
                id={id}
                value={selected ? selected : ""}
                onChange={onChange}
                disabled={disabled}
            >
                {placeholder && (
                    <option value="" disabled hidden>
                        {placeholder}
                    </option>
                )}
                {options?.map(
                    (
                        opt: string | { [key: string]: string | number },
                        idx: number
                    ) => {
                        const name =
                            typeof opt === "string"
                                ? opt
                                : optionLabel
                                ? opt[optionLabel]
                                : opt.text;
                        const value =
                            typeof opt === "string"
                                ? opt
                                : optionKey
                                ? opt[optionKey]
                                : opt.value;
                        return (
                            <option key={idx} value={value}>
                                {name}
                            </option>
                        );
                    }
                )}
            </select>
        </div>
        <small id={`${id}-help`} aria-describedby={id} className="form-text">
            {error && (
                <div className="alert alert-danger alert-info mb-0 p-2">
                    {error}
                </div>
            )}
        </small>
    </div>
);
