import React from "react";
import { CreateAccountState } from "./CreateAccountState";
import { IsSetupState } from "./IsSetupState";
import { StripeAccountsDetails } from "@switcherstudio/switcher-api-client";
import { GatedContentStatus, useUserStripeData } from "hooks/useUserStripeData";
import { useTranslation } from "react-i18next";

interface StripeConnectCardProps {
    details: StripeAccountsDetails;
    gatedContentStatus: GatedContentStatus;
    /** Enables or disables account connection */
    variant?: "Catalog" | "GatedContent";
}

/**
 * Allows user to either set up or access their Stripe Connect Account for payouts
 * Note: for testing, use `000000` for 2-Factor Authentication
 */
export const StripeConnectCard: React.FC<StripeConnectCardProps> = ({
    details,
    gatedContentStatus,
    variant = "GatedContent"
}) => {
    const { t } = useTranslation();

    const {
        accountData: {
            account,
            link: { href, target }
        }
    } = useUserStripeData({
        requestImmediately: true,
        includeProducts: false,
        redirectPath: variant === "Catalog" && "/catalog/catalog-subscription"
    });

    if (details?.Email) {
        return (
            <IsSetupState
                link={href}
                target={target}
                details={details}
                gatedContentStatus={gatedContentStatus}
            />
        );
    }

    if (!account?.DetailsSubmitted) {
        return (
            <CreateAccountState
                link={href}
                target={target}
                description={
                    variant === "Catalog"
                        ? t(
                              "subscription-page:start-getting-rich-catalog-description"
                          )
                        : t("subscription-page:start-getting-rich-description")
                }
            />
        );
    }
    return <></>;
};
