import React, { PropsWithChildren, useMemo } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import SwitcherIcon from "assets/icons/ss-transparent_new.svg?react";

export interface SidebarProps extends PropsWithChildren {
    isLastStep: boolean;
    isTrialing: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
    isLastStep,
    isTrialing,
    children
}) => {
    const { t } = useTranslation("getting-started");

    const title = useMemo(() => {
        if (isLastStep) {
            return (
                <h2>
                    {t("last-step-title")}{" "}
                    <strong>{t("last-step-title2")}</strong>
                </h2>
            );
        } else if (isTrialing) {
            return (
                <h2>
                    {t("title")} <strong>{t("title2")}</strong>
                </h2>
            );
        } else {
            return (
                <h2>
                    {t("title-no-trial")}{" "}
                    <strong>{t("title2-no-trial")}</strong>
                </h2>
            );
        }
    }, [isLastStep, isTrialing, t]);

    return (
        <div className={styles["container"]}>
            <div className={styles["title"]}>
                <SwitcherIcon className={styles["icon"]} />
                {title}
            </div>
            {children}
        </div>
    );
};
