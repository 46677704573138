import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../CatalogCustomizationPage/index.module.scss";
import { ColorPicker } from "components/inputs/colorpicker/ColorPicker";
import {
    CatalogDetailsResponse,
    VideoPlayerProfile
} from "@switcherstudio/switcher-api-client";
import { Errors } from "hooks/useForm";

declare global {
    interface Window {
        switcherPlayerApp: any;
    }
}

export interface StylesTabProps {
    errors: Errors;
    handleSettingsChange: (key: string, value: any) => void;
    catalogValues?: CatalogDetailsResponse;
    videoPlayerValues?: VideoPlayerProfile;
}

export const StylesTab: React.FC<StylesTabProps> = ({
    errors,
    handleSettingsChange,
    catalogValues,
    videoPlayerValues
}) => {
    const { t } = useTranslation("customization-page");
    const values = videoPlayerValues
        ? videoPlayerValues?.VideoPlayer
        : catalogValues;

    return (
        <>
            <div className={styles["form-content"]}>
                <ColorPicker
                    id="primaryColor"
                    label={t("btn-bkgds-links")}
                    value={
                        videoPlayerValues &&
                        videoPlayerValues.VideoPlayer?.PrimaryColor
                            ? videoPlayerValues.VideoPlayer.PrimaryColor.toLocaleUpperCase()
                            : catalogValues &&
                                catalogValues.ButtonBackgroundsLinksColor
                              ? catalogValues.ButtonBackgroundsLinksColor.toLocaleUpperCase()
                              : "#000000"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            videoPlayerValues
                                ? "PrimaryColor"
                                : "ButtonBackgroundsLinksColor",
                            e.target.value
                        );
                    }}
                    error={
                        errors?.VideoPlayerSettings?.PrimaryColor ||
                        errors?.VideoPlayerSettings?.ButtonBackgroundsLinksColor
                    }
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="secondaryColor"
                    label={t("btn-text")}
                    value={
                        videoPlayerValues &&
                        videoPlayerValues.VideoPlayer.SecondaryColor
                            ? videoPlayerValues.VideoPlayer.SecondaryColor.toLocaleUpperCase()
                            : catalogValues && catalogValues.ButtonTextColor
                              ? catalogValues.ButtonTextColor.toLocaleUpperCase()
                              : "#FFFFFF"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            videoPlayerValues
                                ? "SecondaryColor"
                                : "ButtonTextColor",
                            e.target.value
                        );
                    }}
                    error={
                        errors?.VideoPlayerSettings?.SecondaryColor ||
                        errors?.VideoPlayerSettings?.ButtonTextColor
                    }
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="embedBackgroundColor"
                    label={t("embed-bkd")}
                    value={
                        values?.EmbedBackgroundColor?.toLocaleUpperCase() ??
                        "#FFFFFF"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            "EmbedBackgroundColor",
                            e.target.value
                        );
                    }}
                    error={errors?.VideoPlayerSettings?.EmbedBackgroundColor}
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="embedTextColor"
                    label={t("embed-text")}
                    value={
                        values?.EmbedTextColor?.toLocaleUpperCase() ?? "#000000"
                    }
                    onChange={(e) => {
                        handleSettingsChange("EmbedTextColor", e.target.value);
                    }}
                    error={errors?.VideoPlayerSettings?.EmbedTextColor}
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="logoBackgroundColor"
                    label={t("upcoming-bkgd")}
                    value={
                        videoPlayerValues &&
                        videoPlayerValues.VideoPlayer?.LogoBackgroundColor
                            ? videoPlayerValues.VideoPlayer.LogoBackgroundColor.toLocaleUpperCase()
                            : catalogValues &&
                                catalogValues.UpcomingBackgroundColor
                              ? catalogValues.UpcomingBackgroundColor.toLocaleUpperCase()
                              : "#000000"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            videoPlayerValues
                                ? "LogoBackgroundColor"
                                : "UpcomingBackgroundColor",
                            e.target.value
                        );
                    }}
                    error={
                        errors?.VideoPlayerSettings?.LogoBackgroundColor ||
                        errors?.VideoPlayerSettings?.UpcomingBackgroundColor
                    }
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="upcomingTextColor"
                    label={t("upcoming-txt")}
                    value={
                        values?.UpcomingTextColor?.toLocaleUpperCase() ??
                        "#FFFFFF"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            "UpcomingTextColor",
                            e.target.value
                        );
                    }}
                    error={errors?.VideoPlayerSettings?.UpcomingTextColor}
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="interactivePanelBackgroundColor"
                    label={t("interactive-panel-bkgd")}
                    value={
                        values?.InteractivePanelBackgroundColor?.toLocaleUpperCase() ??
                        "#FFFFFF"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            "InteractivePanelBackgroundColor",
                            e.target.value
                        );
                    }}
                    error={
                        errors?.VideoPlayerSettings
                            ?.InteractivePanelBackgroundColor
                    }
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="interactivePanelTextColor"
                    label={t("interactive-panel-txt")}
                    value={
                        values?.InteractivePanelTextColor?.toLocaleUpperCase() ??
                        "#000000"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            "InteractivePanelTextColor",
                            e.target.value
                        );
                    }}
                    error={
                        errors?.VideoPlayerSettings?.InteractivePanelTextColor
                    }
                    allowText
                    variation={false}
                />

                <ColorPicker
                    id="playerControlsColor"
                    label={t("player-controls-color")}
                    value={
                        values?.PlayerControlsColor?.toLocaleUpperCase() ??
                        "#FFFFFF"
                    }
                    onChange={(e) => {
                        handleSettingsChange(
                            "PlayerControlsColor",
                            e.target.value
                        );
                    }}
                    error={errors?.VideoPlayerSettings?.PlayerControlsColor}
                    allowText
                    variation={false}
                />
            </div>
        </>
    );
};
