import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { VideoDetailsPage } from ".";
import { useParams } from "hooks/useParams";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { mapFrameworkBroadcastResponseToBroadcast } from "helpers/mappers/mapBroadcastResponseToBroadcast";
import { usePageHeader } from "hooks/usePageHeader";
import { checkBroadcastType } from "helpers/broadcastHelpers";

/** this page is the sister page of VideoLibraryVideoDetailsPage */
export const CollectionVideoDetailsPage: React.FC = () => {
    const { t } = useTranslation();
    const { playlistBroadcastId, videoPlayerId } = useParams();

    const {
        data: broadcast,
        dispatchApiRequest: refetchBroadcast,
        loading
    } = useSwitcherClient(
        (client) => client.videoPlayerPlaylistBroadcast_GetById,
        {
            args: [playlistBroadcastId, false],
            requestImmediately: true
        }
    );

    const { isPremiere, isScheduledLivestream } = useMemo(() => {
        const localBroadcast = mapFrameworkBroadcastResponseToBroadcast(
            broadcast?.Broadcast
        );

        return checkBroadcastType(localBroadcast);
    }, [broadcast?.Broadcast]);

    const pageTitle = useMemo<string>(() => {
        if (isPremiere) return t("page-titles:premiere-details");
        if (isScheduledLivestream) return t("page-titles:stream-details");
        return t("page-titles:video-details");
    }, [isPremiere, isScheduledLivestream, t]);

    usePageHeader({
        title: pageTitle,
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("breadcrumbs:catalog"),
            t("customization-page:collection-settings"),
            pageTitle
        ]
    });

    return (
        <VideoDetailsPage
            broadcast={broadcast?.Broadcast}
            video={broadcast?.Broadcast?.Videos?.result?.[0]}
            refetchBroadcast={refetchBroadcast}
            playlistBroadcast={broadcast}
            location="collection"
            broadcastLoading={loading}
            collectionId={videoPlayerId}
            isPremiere={isPremiere}
            isScheduledLivestream={isScheduledLivestream}
            disablePlayerSelect
        />
    );
};
