import React, { useCallback, useEffect } from "react";
import { SwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { TextInput } from "components/inputs/text-input/TextInput";
import { Select } from "components/inputs/select/Select";
import {
    videoFormats,
    audioFormats,
    videoBitRates,
    audioBitRates
} from "./form-options";
import { SpeedTest } from "components/speed-test/SpeedTest";
import styles from "./CustomRTMPChannelForm.module.scss";
import { useTranslation } from "react-i18next";
import { usePageHeader } from "hooks/usePageHeader";

interface CustomRTMPChannelFormProps {
    btnText: string;
    secondaryBtnText?: string;
    setting: SwitcherStreamSetting;
    onChange: (update: { [key: string]: any }) => void;
    onPrimaryClick: () => void;
    onSecondaryClick?: () => void;
    showFullHD?: boolean;
}

export const CustomRTMPChannelForm: React.FC<CustomRTMPChannelFormProps> = ({
    btnText,
    secondaryBtnText,
    setting,
    onChange,
    onPrimaryClick,
    onSecondaryClick,
    showFullHD
}) => {
    const { t } = useTranslation("platforms");

    // allow for new "ID" on audio format
    useEffect(() => {
        if (!setting["audio-channel-id"]) {
            const channelCount = setting["audio-channel-count"];
            const sampleRate = setting["audio-sample-rate"];
            onChange({
                "audio-channel-id": audioFormats.find(
                    (format) =>
                        format.channelCount === channelCount &&
                        format.sampleRate === sampleRate
                ).id
            });
        }
    }, [setting, onChange]);

    usePageHeader({
        showBreadcrumbs: true,
        customCrumbs: [
            {
                label: t("page-titles:manage-platforms"),
                route: "/manage-platforms"
            },
            { label: t(setting["channel-name"]), route: "/" }
        ]
    });

    return (
        <>
            <div className={styles["form-card"]}>
                <div className={styles["form-card-body"]}>
                    <div className="row">
                        <div className="col-sm-12">
                            <TextInput
                                readonly={!setting["user-editable"]}
                                id="channel-name"
                                label={t("custom-rtmp-channel-name")}
                                type="text"
                                placeholder="Channel Name"
                                value={setting["channel-name"]}
                                onChange={(e) =>
                                    onChange({ "channel-name": e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-12 col-lg-6">
                            <TextInput
                                readonly={!setting["user-editable"]}
                                id="server-url"
                                label={t("custom-rtmp-server-url")}
                                type="text"
                                placeholder="Server URL"
                                value={setting.url}
                                onChange={(e) =>
                                    onChange({ url: e.target.value })
                                }
                            />
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <TextInput
                                readonly={!setting["user-editable"]}
                                id="rtmp-stream"
                                label={t("custom-rtmp-stream-key")}
                                type="text"
                                placeholder={t("platforms:key-placeholder")}
                                value={setting["rtmp-stream"]}
                                onChange={(e) =>
                                    onChange({ "rtmp-stream": e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <SpeedTest
                            onComplete={useCallback(
                                (results) => onChange(results),
                                [onChange]
                            )}
                            hasFullHD={showFullHD}
                        />
                    </div>
                    <h6>{t("custom-rtmp-broadcast-options")}</h6>
                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <Select
                                label={t("custom-rtmp-video-format")}
                                id="video-format"
                                className={styles.select}
                                selected={setting["video-frame-height"]}
                                onChange={(e) => {
                                    const selectedFormat = videoFormats.find(
                                        (v) =>
                                            v.height ===
                                            parseInt(e.target.value)
                                    );
                                    onChange({
                                        "video-frame-height":
                                            selectedFormat.height,
                                        "video-frame-width":
                                            selectedFormat.width
                                    });
                                }}
                                options={videoFormats.filter((v) =>
                                    showFullHD ? v : v.height < 1080
                                )}
                                optionKey="height"
                                optionLabel="name"
                            />
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <Select
                                label={t("custom-rtmp-video-bitrate")}
                                id="video-bitrate"
                                className={styles.select}
                                selected={setting["video-bit-rate"]}
                                onChange={(e) => {
                                    onChange({
                                        "video-bit-rate": e.target.value
                                    });
                                }}
                                options={videoBitRates}
                                optionKey="value"
                                optionLabel="name"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <Select
                                label={t("custom-rtmp-audio-format")}
                                id="audio-format"
                                className={styles.select}
                                selected={setting["audio-channel-id"]}
                                onChange={(e) => {
                                    const selectedFormat = audioFormats.find(
                                        (a) => {
                                            if (!e.target.value) {
                                                return (
                                                    !a.channelCount &&
                                                    !e.target.value
                                                );
                                            }
                                            return a.id === e.target.value;
                                        }
                                    );
                                    onChange({
                                        "audio-channel-id": selectedFormat?.id,
                                        "audio-channel-count":
                                            selectedFormat?.channelCount,
                                        "audio-sample-rate":
                                            selectedFormat?.sampleRate
                                    });
                                }}
                                options={audioFormats}
                                optionKey="id"
                                optionLabel="name"
                            />
                        </div>
                        <div className="col-sm-12 col-lg-6">
                            <Select
                                label={t("custom-rtmp-audio-bitrate")}
                                id="audio-bitrate"
                                className={styles.select}
                                selected={setting["audio-bit-rate"]}
                                onChange={(e) => {
                                    onChange({
                                        "audio-bit-rate": e.target.value
                                    });
                                }}
                                options={audioBitRates}
                                optionKey="value"
                                optionLabel="name"
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <button
                                className="btn btn-primary"
                                onClick={onPrimaryClick}
                            >
                                {btnText}
                            </button>
                            {onSecondaryClick && (
                                <button
                                    className="btn btn-danger ml-2"
                                    onClick={onSecondaryClick}
                                >
                                    {secondaryBtnText}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
