import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useRef
} from "react";
import {
    GatedContentModal,
    PricingModalTypes
} from "../../../../components/modal/GatedContentModal";
import styles from "./index.module.scss";
import { GatedContentEmpty } from "./GatedContentEmpty";
import { GatedContentTable } from "./GatedContentTable";
import { useSwitcherClient } from "../../../../hooks/useSwitcherClient";
import { addNotification } from "../../../../store/notification/slice";
import { NotificationType } from "../../../../store/notification/types";
import {
    CreatorProduct,
    CreatorProductPricesBindingModel,
    CreatorProductPricesBindingModelEntitledDurationUnit,
    StripeAccount,
    StripeAccountsDetails
} from "@switcherstudio/switcher-api-client";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "../../../../components/modal/DeleteModal";
import { exists } from "helpers/booleans";
import { StripeConnectCard } from "components/cards/StripeConnectButton";
import { GatedContentStatus } from "hooks/useUserStripeData";
import { useGatedContentPassForm } from "hooks/useGatedContentPassForm";
import { GatedContentDisabledTooltip } from "components/tooltips/GatedContentDisabledTooltip";
import { useUserStripeData } from "hooks/useUserStripeData";
import { useClaimCheck } from "hooks/useClaimCheck";
import { LoadingOverlay } from "components/loading-overlay";

export interface PricingDetailsProps {
    account: StripeAccount;
    details: StripeAccountsDetails;
    loading: boolean;
    products: CreatorProduct[];
    productSubscribers: number[];
    getProducts: () => void;
    setLoading: (loading: boolean) => void;
}

export const PricingDetails: React.FC<PricingDetailsProps> = ({
    account,
    details,
    loading,
    products,
    productSubscribers,
    getProducts,
    setLoading
}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalType, setModalType] = useState<PricingModalTypes>(
        PricingModalTypes.Create
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<CreatorProduct>(); //to be used for the modals
    const params = useMemo<URLSearchParams>(
        () => new URL(window.location.href).searchParams,
        []
    );

    const processingStateRef = useRef(false);

    const { dispatchApiRequest: putProductPrices } = useSwitcherClient(
        (client) => client.creatorProductPrices_Update
    );

    const { dispatchApiRequest: putProduct } = useSwitcherClient(
        (client) => client.creatorProducts_Update
    );
    const {
        accountData: { gatedContentStatus }
    } = useUserStripeData({
        requestImmediately: true,
        includeProducts: false
    });

    const hasRecurringPaymentsClaim = useClaimCheck(
        "gatedcontent.recurringpayments"
    );
    const updateModal = useCallback(
        (
            isModal: boolean,
            modalType: PricingModalTypes,
            newProduct?: CreatorProduct
        ) => {
            setModalOpen(isModal);
            setModalType(modalType);
            setSelectedProduct(newProduct);
        },
        []
    );

    useEffect(() => {
        const openModalFromSearchParam = async () => {
            const product = products.find((p) => p.Id === params.get("pass"));
            if (product) {
                updateModal(true, PricingModalTypes.Update, product);
            }
        };
        openModalFromSearchParam();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, products]);

    const updateDeleteModal = useCallback(
        (isModal: boolean, productItem: CreatorProduct) => {
            setDeletionModalOpen(isModal);
            setSelectedProduct(productItem);
        },
        []
    );

    const handleDeleteSubmit = useCallback(async () => {
        setDeletionModalOpen(false);
        setLoading(true);
        const priceRequestObjects = selectedProduct?.Prices?.map(
            (p): CreatorProductPricesBindingModel =>
                ({
                    ...p,
                    IsActive: false,
                    IsPublic: false,
                    EntitledDuration: p.EntitledDuration,
                    EntitledDurationUnit:
                        CreatorProductPricesBindingModelEntitledDurationUnit[
                            p.EntitledDurationUnit
                        ]
                } as CreatorProductPricesBindingModel)
        );
        try {
            await putProductPrices([
                account.Id,
                priceRequestObjects?.[0]?.ProductId,
                {
                    Prices: priceRequestObjects
                }
            ]);
            await putProduct([
                account.Id,
                {
                    Products: [
                        {
                            Id: selectedProduct?.Id,
                            Name: selectedProduct?.Name,
                            Description: selectedProduct?.Name,
                            StripeAccountId: account.Id,
                            IsActive: false
                        }
                    ]
                }
            ]);
            dispatch(
                addNotification({
                    type: NotificationType.Success,
                    message: t(
                        "gated-content-modal:messages:pricing-delete-success"
                    )
                })
            );
        } catch (e) {
            setLoading(false);
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t(
                        "gated-content-modal:errors:pricing-delete-error"
                    )
                })
            );
        } finally {
            setLoading(false);
            await getProducts();
        }
    }, [
        setLoading,
        selectedProduct,
        putProductPrices,
        account.Id,
        putProduct,
        dispatch,
        t,
        getProducts
    ]);

    const deleteTitleText = useMemo<string>(() => {
        if (productSubscribers[selectedProduct?.Id] > 0) {
            return t("gated-content-modal:deletion-has-sub-title");
        }

        return t("gated-content-modal:delete-pricing-title");
    }, [t, selectedProduct, productSubscribers]);

    const deleteSubText = useMemo<string>(() => {
        if (productSubscribers[selectedProduct?.Id] > 0) {
            return t("gated-content-modal:deletion-has-sub-msg", {
                subscriberCount: productSubscribers[selectedProduct.Id]
            });
        }

        return t("gated-content-modal:deletion-confirm-msg");
    }, [t, selectedProduct, productSubscribers]);

    const createPassButton = useMemo(() => {
        if (products.length === 0 || !account) return <></>;
        return (
            <button
                className={`btn btn-primary ${styles["pass-btn"]}`}
                type="button"
                onClick={() => updateModal(true, PricingModalTypes.Create)}
                disabled={gatedContentStatus !== GatedContentStatus.READY}
            >
                {t("gated-content-page:create-btn")}
            </button>
        );
    }, [products.length, account, updateModal, gatedContentStatus, t]);

    const {
        errors,
        handleSubmit,
        handlePriceChange,
        values,
        processing,
        submitDisabled,
        untimedOTPInitiallySelected
    } = useGatedContentPassForm({
        accountId: account?.Id,
        oldProduct: selectedProduct,
        onSubmit: async () => {
            setModalOpen(false); //The modal is closed immediatetly for a more responsive experience
        },
        type: modalType,
        hasRecurringPaymentsClaim
    });

    //used to refresh products after a pass is created or updated because the modal is closed immediately
    useEffect(() => {
        if (processingStateRef.current && !processing) {
            getProducts();
        }
        processingStateRef.current = processing;
    }, [processing, getProducts]);

    const showOverlay = useMemo(() => {
        return loading || processing;
    }, [loading, processing]);

    return (
        <>
            <LoadingOverlay
                isLoading={showOverlay}
                targetInteractiveCssSelelector=".products-container"
                transparentOnLoad
                inlineLoading={false}
            >
                <div className="products-container">
                    <div className={styles["card-container"]}>
                        <StripeConnectCard
                            details={details}
                            gatedContentStatus={gatedContentStatus}
                        />
                        {!loading && (
                            <GatedContentDisabledTooltip
                                gatedContentStatus={gatedContentStatus}
                                labelOrientation="bottom"
                            >
                                {createPassButton}
                            </GatedContentDisabledTooltip>
                        )}
                    </div>
                    <GatedContentModal
                        isOpen={modalOpen}
                        setIsOpen={setModalOpen}
                        type={modalType}
                        onSubmit={() => handleSubmit(values)}
                        errors={errors}
                        onPriceChange={handlePriceChange}
                        values={values}
                        processing={processing}
                        submitDisabled={submitDisabled}
                        hasRecurringPaymentsClaim={hasRecurringPaymentsClaim}
                        untimedOTPInitiallySelected={
                            untimedOTPInitiallySelected
                        }
                    />
                    {deletionModalOpen && (
                        <DeleteModal
                            isOpen={deletionModalOpen}
                            setIsOpen={setDeletionModalOpen}
                            onSubmit={handleDeleteSubmit}
                            titleText={deleteTitleText}
                            subMsg={deleteSubText}
                        />
                    )}
                    {products.length !== 0 && account ? (
                        <GatedContentTable
                            products={products}
                            productSubscribers={productSubscribers}
                            updateModal={updateModal}
                            updateDeleteModal={updateDeleteModal}
                            details={details}
                        />
                    ) : (
                        <GatedContentEmpty
                            updateModal={updateModal}
                            isStripeConnected={exists(account)}
                        />
                    )}
                </div>
            </LoadingOverlay>

            {!showOverlay && products && <></>}
        </>
    );
};
