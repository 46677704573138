import {
    WebLinkResponse as FrameworkWebLinkResponse,
    WebLink
} from "@switcherstudio/switcher-api-client";
import { WebLinkResponse as CoreWebLinkResponse } from "@switcherstudio/api-core-client";

export const isFrameworkWebLinkResponse = (
    response: FrameworkWebLinkResponse | CoreWebLinkResponse
): boolean => !isCoreWebLinkResponse(response);

export const isCoreWebLinkResponse = (
    response: FrameworkWebLinkResponse | CoreWebLinkResponse
): boolean => (response as CoreWebLinkResponse)?.Details !== undefined;

export const mapWebLinkResponseToWebLink = (
    response: FrameworkWebLinkResponse | CoreWebLinkResponse
): WebLink =>
    isFrameworkWebLinkResponse(response)
        ? mapFrameworkWebLinkResponseToWebLink(
              response as FrameworkWebLinkResponse
          )
        : mapCoreWebLinkResponseToWebLink(response as CoreWebLinkResponse);

export const mapFrameworkWebLinkResponseToWebLink = (
    response: FrameworkWebLinkResponse
): WebLink => {
    if (!response) {
        return null;
    }

    return {
        ...response
    };
};

export const mapCoreWebLinkResponseToWebLink = (
    response: CoreWebLinkResponse
): WebLink => {
    if (!response) {
        return null;
    }

    return {
        ...response.Details
    };
};
