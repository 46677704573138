import React, { useMemo } from "react";
import { VideoDetailsPage } from ".";
import { useParams } from "hooks/useParams";
import { useSwitcherClientCore } from "hooks/useSwitcherClient";
import { useTranslation } from "react-i18next";
import { usePageHeader } from "hooks/usePageHeader";
import { checkBroadcastType } from "helpers/broadcastHelpers";

/** this page is the sister page of CollectionVideoDetailsPage */
export const VideoLibraryVideoDetailsPage: React.FC = () => {
    const { t } = useTranslation();
    const { broadcastId } = useParams();

    const {
        data: broadcast,
        dispatchApiRequest: refetchBroadcast,
        loading
    } = useSwitcherClientCore((client) => client.broadcasts_GetBroadcastsById, {
        args: [broadcastId],
        requestImmediately: true
    });

    const { isPremiere } = useMemo(
        () => checkBroadcastType(broadcast),
        [broadcast]
    );

    const pageTitle = useMemo<string>(() => {
        if (isPremiere) return t("page-titles:premiere-details");
        return t("page-titles:video-details");
    }, [isPremiere, t]);

    usePageHeader({
        title: pageTitle,
        showBreadcrumbs: true,
        breadcrumbLabels: [t("page-titles:video-library"), pageTitle]
    });

    return (
        <VideoDetailsPage
            broadcast={broadcast}
            video={broadcast?.[0]?.videos?.[0]}
            refetchBroadcast={refetchBroadcast}
            location="video-library"
            broadcastLoading={loading}
            isPremiere={isPremiere}
        />
    );
};
