import { VideoUploadModalEntryPoint } from "components/video-upload/VideoUploadModalEntryPoint";
import { Link } from "components/buttons/Link";
import { openExternalUrlInNewTab } from "helpers/navigation";
import { AddVideosContentProps } from "./types";
import { Spinner } from "components/spinners/Spinner";
import { useHasPlanRole } from "hooks/useHasPlanRole";
import styles from "./index.module.scss";

export const AddVideosContent = ({
    isVideoLibrary,
    hasVideos,
    refetchVideos,
    firstLoading,
    reloading,
    GenericMultiSelectComponent,
    t,
    userHasNoSubscription,
    videoUploadSessionId,
    setVideoUploadSessionId,
    playerId,
    onClose,
    uploadModalKey,
    omitUpload = false
}: AddVideosContentProps) => {
    const isGrowUser = useHasPlanRole("Grow");
    return (
        <div className={reloading ? "reloading-inactive" : ""}>
            {(isVideoLibrary && userHasNoSubscription) || omitUpload ? (
                <></>
            ) : (
                <div
                    className={
                        isVideoLibrary
                            ? styles["upload-drag-and-drop-container"]
                            : styles["upload-container"]
                    }
                >
                    <VideoUploadModalEntryPoint
                        sessionId={videoUploadSessionId}
                        setSessionId={setVideoUploadSessionId}
                        allowMultipleUploads
                        lockedToPlayer={isVideoLibrary ? undefined : playerId}
                        onUploadComplete={
                            isVideoLibrary ? () => refetchVideos(null) : onClose
                        }
                        isVideoLibrary={isVideoLibrary}
                        showVideoStorageBar={isVideoLibrary}
                        refreshKey={uploadModalKey}
                    />
                </div>
            )}
            {firstLoading ? (
                <div className={styles["loading"]}>
                    <Spinner size={128} />
                </div>
            ) : hasVideos ? (
                <>
                    {!isVideoLibrary && !omitUpload && (
                        <div className={styles["separator"]}>
                            <hr />
                            <small>
                                {t("video-player-page:add-videos-prompt")}
                            </small>
                            <hr />
                        </div>
                    )}
                    <>{GenericMultiSelectComponent}</>
                </>
            ) : (
                !isGrowUser && (
                    <p>
                        {t("video-library:no-videos")}{" "}
                        <Link
                            onClick={() =>
                                openExternalUrlInNewTab(
                                    "https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                                )
                            }
                            to={""}
                        >
                            {t("video-library:download-app")}
                        </Link>
                    </p>
                )
            )}
        </div>
    );
};
