import React, { useEffect, useState, useCallback } from "react";
import { client } from "api/client";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { CustomRTMPChannelForm } from "./CustomRTMPChannelForm";
import { useTranslation } from "react-i18next";
import { SwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { useNavigate } from "react-router-dom";
import { useParams } from "hooks/useParams";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";

export const EditCustomRTMPChannelPage: React.FC = () => {
    const { id } = useParams();
    const { isInBrowser } = useSwitcherSdk();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [channel, setChannel] = useState<SwitcherStreamSetting>();

    useEffect(() => {
        async function getChannel() {
            try {
                const channel =
                    await client.switcherStreamSettings_GetSwitcherStreamSetting(
                        id
                    );
                setChannel(channel);
            } catch (err) {
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "Problem getting custom RTMP details."
                    })
                );
            }
        }

        getChannel();
    }, [dispatch, id, navigate]);

    const onChange = useCallback((update: { [key: string]: any }) => {
        setChannel((prev) => {
            return {
                ...prev,
                ...update
            };
        });
    }, []);

    const updateChannel = useCallback(async () => {
        const regx = /^(rtmp|rtmps):\/\/[^ "]+$/i;
        const isRtmpUrl = regx.test(channel.url);

        if (!isRtmpUrl) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("platforms:invalid-rtmp-error")
                })
            );
            return;
        }
        try {
            await client.switcherStreamSettings_PutSwitcherStreamSetting(
                channel["switcher-stream-setting-id"],
                channel
            );
            navigate(isInBrowser ? "/manage-platforms" : "/platforms");
        } catch (err) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: t("platforms:channel-settings-error")
                })
            );
        }
    }, [channel, dispatch, navigate, t, isInBrowser]);

    const deleteChannel = useCallback(async () => {
        try {
            await client.switcherStreamSettings_DeleteSwitcherStreamSetting(
                channel["switcher-stream-setting-id"]
            );
            navigate(isInBrowser ? "/manage-platforms" : "/platforms");
        } catch (err) {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "There was a problem deleting your channel."
                })
            );
        }
    }, [channel, dispatch, navigate, isInBrowser]);
    return (
        <>
            {channel && (
                <CustomRTMPChannelForm
                    btnText={t("buttons:save")}
                    secondaryBtnText={t("buttons:delete")}
                    setting={channel}
                    onChange={onChange}
                    onPrimaryClick={updateChannel}
                    onSecondaryClick={deleteChannel}
                    showFullHD={true}
                />
            )}
        </>
    );
};
