import { StreamSettingResponse } from "@switcherstudio/api-core-client";
import { ComponentMap } from "components/utility/ComponentMap";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { Link } from "components/buttons/Link";
import { PlatformIcon } from "components/icons/PlatformIcon";
import { getChannelName } from "helpers/streamSettings";
import { useMemo } from "react";

export interface ExternalDestinationsProps {
    destinations: StreamSettingResponse[];
    filteredDestinations?: string[];
}

export const ExternalDestinations = ({
    destinations,
    filteredDestinations = [
        "VideoPlayerSwitcherStreamSetting",
        "SimulcastSwitcherStreamSetting"
    ]
}: ExternalDestinationsProps) => {
    const { t } = useTranslation();

    const localDestinations = useMemo(() => {
        return destinations?.filter(
            (destination) =>
                !filteredDestinations.includes(
                    destination.Details.Discriminator
                )
        );
    }, [destinations, filteredDestinations]);

    if (!localDestinations?.length) return null;

    return (
        <div>
            <h6>{t("livestreams-page:external-destinations")}</h6>
            <ComponentMap
                items={localDestinations}
                element={(destination) => (
                    <div className={styles["destination"]}>
                        <PlatformIcon
                            destination={destination.Details?.Discriminator}
                            useNewIcon
                        />
                        <Link
                            to={destination?.Details?.PermalinkUrl}
                            disabled={!destination?.Details?.PermalinkUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {getChannelName(destination)}
                        </Link>
                    </div>
                )}
            />
            <p className={styles["attention"]}>
                <strong>{t("livestreams-page:attention")}</strong>{" "}
                {t("livestreams-page:attention-text")}
            </p>
        </div>
    );
};
