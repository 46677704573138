import {
    CategoryResponse as FrameworkCategoryResponse,
    Category,
    categoryType
} from "@switcherstudio/switcher-api-client";
import {
    CategoryResponse as CoreCategoryResponse,
    CategoryTypes as CoreCategoryTypes
} from "@switcherstudio/api-core-client";

export const isFrameworkCategoryResponse = (
    response: FrameworkCategoryResponse | CoreCategoryResponse
): boolean => (response as FrameworkCategoryResponse)?.Category !== undefined;

export const isCoreCategoryResponse = (
    response: FrameworkCategoryResponse | CoreCategoryResponse
): boolean => !isFrameworkCategoryResponse(response);

export const mapCategoryResponseToCategory = (
    response: FrameworkCategoryResponse | CoreCategoryResponse
): Category =>
    isFrameworkCategoryResponse(response)
        ? mapFrameworkCategoryResponseToCategory(
              response as FrameworkCategoryResponse
          )
        : mapCoreCategoryResponseToCategory(response as CoreCategoryResponse);

export const mapFrameworkCategoryResponseToCategory = (
    response: FrameworkCategoryResponse
): Category => {
    if (!response) {
        return null;
    }

    return {
        Id: response?.Category?.Id,
        Name: response?.Category?.Name,
        CategoryType: response?.Category?.CategoryType,
        ProjectId: response?.Category?.ProjectId,
        Sequence: response?.Category?.Sequence,
        CreatedAt: response?.Category?.CreatedAt,
        InsertedAt: response?.Category?.InsertedAt,
        UpdatedAt: response?.Category?.UpdatedAt
    };
};

export const mapCoreCategoryResponseToCategory = (
    response: CoreCategoryResponse
): Category => {
    if (!response) {
        return null;
    }

    return {
        Id: response?.Details?.Id,
        Name: response?.Details?.Name,
        CategoryType: mapCoreCategoryTypeToCategoryType(
            response?.Details?.CategoryType
        ),
        ProjectId: null,
        Sequence: response?.Details?.Sequence,
        CreatedAt: new Date(response?.Details?.CreatedAt).toUTCString(),
        InsertedAt: new Date(response?.Details?.CreatedAt).toUTCString(),
        UpdatedAt: new Date(response?.Details?.UpdatedAt).toUTCString()
    };
};

export const mapCoreCategoryTypeToCategoryType = (
    type: CoreCategoryTypes
): categoryType => {
    switch (type) {
        case "Broadcast":
            return categoryType._0;
    }
};

export const mapCategoryTypeToCoreCategoryType = (
    type: categoryType
): CoreCategoryTypes => {
    switch (type) {
        case categoryType._0:
            return "Broadcast";
    }
};

export const mapCategoryToFrameworkCategoryResponse = (
    category: Category
): FrameworkCategoryResponse => {
    return {
        Category: {
            Id: category.Id,
            Name: category.Name,
            CategoryType: category.CategoryType,
            ProjectId: category.ProjectId,
            Sequence: category.Sequence,
            CreatedAt: category.CreatedAt,
            InsertedAt: category.InsertedAt,
            UpdatedAt: category.UpdatedAt
        }
    };
};

export const mapCategoryToCoreCategoryResponse = (
    category: Category
): CoreCategoryResponse => {
    return {
        Details: {
            Id: category.Id,
            Name: category.Name,
            CategoryType: mapCategoryTypeToCoreCategoryType(
                category.CategoryType
            ),
            Sequence: category.Sequence,
            CreatedAt: category.CreatedAt,
            UpdatedAt: category.UpdatedAt
        }
    };
};
