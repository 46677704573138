import React, { useCallback, useEffect } from "react";
import {
    SpeedTestStatus,
    useSpeedTest,
    convertBpsToMbps
} from "hooks/useSpeedTest";
import { ChannelSetting } from "views/page-content/platforms/platform/platform-helper";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

interface SpeedTestProps {
    onComplete: (channel: ChannelSetting) => void;
    hasFullHD: boolean;
}

export const SpeedTest: React.FC<SpeedTestProps> = ({
    onComplete,
    hasFullHD
}) => {
    const [hasRunSpeedTest, setHasRunSpeedTest] = React.useState(false);
    const [resultsDisplayed, setResultsDisplayed] = React.useState(false);
    const { createChannelSetting, results, speedTestStatus } = useSpeedTest();

    const completeTest = useCallback(() => {
        if (speedTestStatus === SpeedTestStatus.Completed) {
            const channel = createChannelSetting(results, hasFullHD);
            onComplete(channel);
        }

        setResultsDisplayed(true);
    }, [createChannelSetting, results, hasFullHD, onComplete, speedTestStatus]);

    useEffect(() => {
        if (
            !hasRunSpeedTest ||
            resultsDisplayed ||
            speedTestStatus === SpeedTestStatus.Waiting ||
            speedTestStatus === SpeedTestStatus.InProgress
        )
            return;

        completeTest();
    }, [
        completeTest,
        hasRunSpeedTest,
        results,
        speedTestStatus,
        resultsDisplayed
    ]);

    const { t } = useTranslation("speed-test");
    return (
        <div className="col-sm-12">
            {speedTestStatus === SpeedTestStatus.Completed &&
                results &&
                hasRunSpeedTest && (
                    <div className="alert alert-info text-center" role="alert">
                        {t("test-complete")}
                        <br />
                        <strong>
                            {t("upload")}: {convertBpsToMbps(results.upload)}{" "}
                            Mbps
                        </strong>
                    </div>
                )}
            {speedTestStatus === SpeedTestStatus.Error && results?.error && (
                <div className="alert alert-danger text-center" role="alert">
                    {results.error.message}
                </div>
            )}
            <button
                type="button"
                className="btn btn-primary btn-block"
                onClick={() => setHasRunSpeedTest(true)}
                disabled={hasRunSpeedTest}
            >
                {hasRunSpeedTest &&
                speedTestStatus === SpeedTestStatus.InProgress ? (
                    <span className={styles["loading-ellipsis"]}>
                        {t("testing")}
                    </span>
                ) : (
                    t("speed-test")
                )}
            </button>
            <div className="col-sm-12 text-center">
                <small>
                    {t("info-1")} <strong>{t("speed-test")}</strong>{" "}
                    {t("info-2")} <strong>{t("stream-quality")}</strong>{" "}
                    {t("info-3")}
                </small>
            </div>
        </div>
    );
};
