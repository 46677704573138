import React, { useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import AppIcon from "assets/AppIcon-1024x1024_new.png";
import ToAppStore from "assets/app-store-cta.png";
import QR from "assets/icons/switcher-qr.png";
import { useTranslation } from "react-i18next";
import { isIOS, isMacOs } from "utils/js-utils";
import { Link } from "components/buttons/Link";
import { useOnboardingSurveyResults } from "hooks/useOnboardingSurveyResults";
import { exists } from "helpers/booleans";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsTablet } from "hooks/useIsTablet";

export const DownloadStep: React.FC = () => {
    const { t } = useTranslation("getting-started");
    const { results } = useOnboardingSurveyResults();
    const { isMobile } = useIsMobile();
    const { isTablet } = useIsTablet();

    const toAppStore = useCallback(() => {
        window.open(
            "https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
        );
    }, []);

    // Show the app icon on iOS and macOS mobile/tablet devices
    const showLogo = useMemo(() => {
        return (isIOS() || isMacOs()) && (isMobile || isTablet);
    }, [isMobile, isTablet]);

    const content = useMemo<string>(() => {
        if (exists(results) && results["options.monetize"]) {
            return t("getting-started:download-step.content-has-video");
        } else if (showLogo) {
            return t("getting-started:download-step.content-ios");
        } else {
            return t("getting-started:download-step.content");
        }
    }, [results, showLogo, t]);

    const prompt = useMemo<string>(() => {
        if (!results) return "";

        if (results["options.monetize"]) {
            return t("getting-started:download-step.prompt-monetize");
        } else if (
            results["options.embed"] ||
            results["video.ready-to-upload"]
        ) {
            return t("getting-started:download-step.prompt-embed");
        } else {
            return t("getting-started:download-step.prompt-stream");
        }
    }, [results, t]);

    return (
        <div className={styles["background"]}>
            <div className={styles["container"]}>
                <div className="text-center">
                    <h4 className={`${styles["title-light"]}`}>
                        <strong>
                            {t("getting-started:download-step.title1")}{" "}
                        </strong>
                        {t("getting-started:download-step.title2")}
                    </h4>
                    <div className={styles["paragraph"]}>
                        <span>{content}</span>
                    </div>
                </div>
            </div>

            <div className={styles["body"]}>
                <div className="placeholder-for-ipad-image"></div>

                <div className={styles["content"]}>
                    <div className={`${styles["apps-container"]} `}>
                        <div
                            className={!showLogo ? styles["qr-container"] : ""}
                        >
                            {showLogo ? (
                                <button
                                    type="button"
                                    onClick={toAppStore}
                                    title="Download on the App Store"
                                >
                                    <img
                                        src={AppIcon}
                                        alt="Switcher App Icon"
                                        className={styles["app-icon"]}
                                    />
                                </button>
                            ) : (
                                <img
                                    src={QR}
                                    className={styles["qr-code"]}
                                    alt="QR code to App Store"
                                    aria-hidden="true"
                                />
                            )}
                            <button
                                type="button"
                                onClick={toAppStore}
                                title="Download on the App Store"
                                className={styles["app-store-cta"]}
                            >
                                <img
                                    src={ToAppStore}
                                    alt="To App Store"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                    <div className={`${styles["continue"]}`}>
                        <p>
                            <strong>{prompt}</strong>
                            <Link to="/home" variant="underline">
                                {t("getting-started:download-step.link")}
                            </Link>{" "}
                            {t("getting-started:download-step.propmt")}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
