import React from "react";
import { Link } from "react-router-dom";
import { PlatformIcon } from "components/icons/PlatformIcon";
import styles from "./index.module.scss";
import { RTMPChannelsTableProps } from "components/platforms/PlatformsTable/RTMPChannelsTable";

export const RTMPChannelsTableV2: React.FC<RTMPChannelsTableProps> = ({
    channels,
    detailsText
}: RTMPChannelsTableProps) => {
    return (
        <table className="table table-striped table-sm">
            <tbody>
                {channels.map((p) => {
                    return (
                        <tr
                            key={p["switcher-stream-setting-id"]}
                            className={styles["platform-row"]}
                        >
                            <td>
                                <div
                                    className={`${styles["platform-link"]} ${styles["rtmp-icon"]}`}
                                >
                                    <PlatformIcon
                                        platformId={""}
                                        useNewIcon={true}
                                    />
                                    {p["channel-name"]}
                                </div>
                            </td>
                            <td
                                className={`${styles["text-right"]} text-right`}
                            >
                                <Link
                                    className={`btn btn-outline-tertiary`}
                                    to={`/platforms/custom-rtmp/${p["switcher-stream-setting-id"]}`}
                                >
                                    {detailsText}
                                </Link>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
