import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "hooks/useParams";
import { usePageHeader } from "hooks/usePageHeader";
import { VideoDetailsPage } from "views/page-content/VideoDetailsPage";
import { useSwitcherClientCore } from "hooks/useSwitcherClient";

/** this page is the sister page of VideoLibraryVideoDetailsPage */
export const LivestreamDetailsPage: React.FC = () => {
    const { t } = useTranslation();
    const { broadcastId } = useParams();

    const {
        data: broadcast,
        dispatchApiRequest: refetchBroadcast,
        loading
    } = useSwitcherClientCore((client) => client.broadcasts_GetBroadcastsById, {
        args: [broadcastId],
        requestImmediately: true
    });

    usePageHeader({
        title: t("page-titles:stream-details"),
        showBreadcrumbs: true,
        breadcrumbLabels: [
            t("page-titles:my-livestreams"),
            t("page-titles:stream-details")
        ]
    });

    return (
        <VideoDetailsPage
            broadcast={broadcast}
            video={null}
            refetchBroadcast={refetchBroadcast}
            location="collection"
            broadcastLoading={loading}
            isScheduledLivestream
        />
    );
};
