import { useIsMountedRef } from "hooks/useIsMountedRef";
import React, { useEffect, useMemo } from "react";
import { Notification, NotificationType } from "store/notification/types";
import styles from "./index.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DEFAULT_FADE = 2500;

interface NotificationPopoverProps {
    /** The notification. */
    notification: Notification;
    /** A handler function attached to the notification close button. */
    close: (id: number) => void;
}

/** A notification popover. */
export const NotificationPopover: React.FC<NotificationPopoverProps> = ({
    notification,
    close
}: NotificationPopoverProps) => {
    const isMountedRef = useIsMountedRef();
    const { t } = useTranslation();

    // if a manual number is set, prefer that
    // Otherwise, errors do not auto-fade and
    // all other messages use DEFAULT_FADE
    let fade = notification.fadeMilliseconds
        ? notification.fadeMilliseconds
        : notification.type === NotificationType.Danger
        ? -1
        : DEFAULT_FADE;

    useEffect(() => {
        if (isMountedRef.current && fade > -1) {
            setTimeout(() => close(notification.id), fade);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMountedRef, notification, fade]);

    const message = useMemo(() => {
        const formattedMessage = t(
            notification.message,
            notification.messageOptions
        );

        const components = notification.linkComponents
            ? Object.assign(
                  {},
                  ...notification.linkComponents.map((component) => ({
                      [component.key]: (
                          <Link
                              className={styles["link"]}
                              to={component.to}
                              title={component.title}
                              onClick={component.onClick}
                          >
                              {component.title}
                          </Link>
                      )
                  }))
              )
            : {};

        return (
            <Trans
                i18nKey={formattedMessage}
                options={notification.messageOptions}
                components={components}
            />
        );
    }, [
        notification.linkComponents,
        notification.message,
        notification.messageOptions,
        t
    ]);

    return (
        <>
            <div className="row justify-content-center">
                <div
                    className={`alert alert-${notification.type} ${
                        notification.dismissible ? `alert-dismissible` : ``
                    } fade show`}
                    role="alert"
                >
                    {message}
                    {!!notification.clickText && !!notification.clickAction ? (
                        <div
                            onClick={() => notification.clickAction()}
                            className={styles["click-action"]}
                        >
                            {notification.clickText}
                        </div>
                    ) : null}
                    {notification.dismissible && (
                        <button
                            type="button"
                            className={`${styles["close-ext"]} close`}
                            data-dismiss="alert"
                            aria-label="Close"
                            onClick={() => close(notification.id)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};
