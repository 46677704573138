import React, { useMemo, useState } from "react";
import { SwitcherStreamSetting } from "@switcherstudio/switcher-api-client";
import { useTranslation, Trans } from "react-i18next";
import { PlatformsTableStreamProvider } from "components/platforms/PlatformsTable";
import { RTMPChannelsTableV2 } from "./PlatformsTableV2/RTMPChannelsTableV2";
import { Link } from "react-router-dom";
import styles from "./PlatformsPageV2.module.scss";
import rollbar from "helpers/rollbar";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useDispatch } from "react-redux";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";
import { useAllPlatformActions } from "hooks/usePlatformActions";
import { AppDispatch } from "store/store";
import { PlatformId } from "../types";
import { usePageHeader } from "hooks/usePageHeader";
import { PlatformsTableV2 } from "./PlatformsTableV2";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { Spinner } from "components/spinners/Spinner";

export const PlatformsPageV2: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { isInBrowser, switcherSdk } = useSwitcherSdk();
    const [providers, setProviders] = useState<PlatformsTableStreamProvider[]>(
        []
    );
    const [customRtmpChannels, setCustomRtmpChannels] = useState<
        SwitcherStreamSetting[]
    >([]);
    const { disconnect, canManage } = useAllPlatformActions();

    usePageHeader({
        title: t("page-titles:manage-platforms"),
        subTitle: (
            <Trans
                i18nKey={t("page-titles:subheaders:manage-platforms")}
                components={{
                    link1: (
                        <Link
                            to={
                                "https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                            }
                            target="_blank"
                            className={styles["link"]}
                        />
                    )
                }}
            />
        ),
        showBreadcrumbs: false,
        shouldHeaderWrap: false
    });

    const { loading: streamingProvidersLoading } = useSwitcherClient(
        (client) => () => client.streamingProviders_GetStreamingProviders(),
        {
            requestImmediately: true,
            onSuccess: (response) => {
                let providers = response.StreamingProviders;

                if (!isInBrowser && switcherSdk?.targetAspectRatio) {
                    // If in the app, filter available platforms by the target aspect ratio
                    const width = switcherSdk?.targetAspectRatio?.numer;
                    const height = switcherSdk?.targetAspectRatio?.denom;
                    providers = providers.filter((p) => {
                        return p.AspectRatios?.some(
                            (ar) => ar.Width === width && ar.Height === height
                        );
                    });
                }

                providers = providers
                    .map(
                        (p): PlatformsTableStreamProvider => ({
                            ...p,
                            CanManage: canManage(p.Id as PlatformId)
                        })
                    )
                    .filter(
                        (p) =>
                            p.Id !== PlatformId.Simulcast &&
                            (p.CanManage || p.Id !== PlatformId.VideoPlayer)
                    );

                setProviders(providers);
            },
            onError: (error) => {
                rollbar.error("Error resetting platforms", error);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: "errors:reset-error"
                    })
                );
            }
        }
    );

    const { loading: streamSettingsLoading } = useSwitcherClient(
        (client) => () =>
            client.switcherStreamSettings_GetAllSwitcherStudioSettings(),
        {
            requestImmediately: true,
            onSuccess: (response) => {
                setCustomRtmpChannels(
                    response.filter(
                        (a) => a["user-editable"] && a["is-direct-to-provider"]
                    )
                );
            }
        }
    );

    const loading = useMemo(() => {
        return streamingProvidersLoading || streamSettingsLoading;
    }, [streamingProvidersLoading, streamSettingsLoading]);

    return (
        <>
            <div className={`row ${styles["platforms-page"]}`}>
                <div className="col-lg-12">
                    {loading ? (
                        <div className={styles["loading"]}>
                            <Spinner size={128} />
                        </div>
                    ) : (
                        <>
                            <PlatformsTableV2
                                platforms={providers}
                                connectText={t("platforms:connect-button")}
                                detailsText={t("buttons:manage")}
                                disconnectText={t(
                                    "platforms:disconnect-button"
                                )}
                                disconnectAction={(platformId: PlatformId) =>
                                    disconnect(
                                        platformId,
                                        providers,
                                        setProviders
                                    )
                                }
                            />
                            <div
                                className={styles["custom-rtmp-section-header"]}
                            >
                                <div>
                                    <h5>{t("platforms:custom-rtmp-header")}</h5>
                                    <small>
                                        <p className="mb-2 text-muted">
                                            {t(
                                                `platforms:custom-rtmp-subheader-v2`
                                            )}
                                        </p>
                                    </small>
                                </div>
                                <div className="text-right">
                                    <Link
                                        className={`btn btn-outline-tertiary mb-2`}
                                        to={"/platforms/custom-rtmp"}
                                    >
                                        {t("platforms:custom-rtmp-setup")}
                                    </Link>
                                </div>
                            </div>
                            <RTMPChannelsTableV2
                                channels={customRtmpChannels}
                                detailsText={t("buttons:manage")}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
