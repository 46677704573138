import { Dispatch, ReactNode, SetStateAction, useCallback } from "react";
import { ModalBase } from "components/modal/ModalBase";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { ComponentItem } from "components/generic-multiselect/types";
import { useGenericMultiSelect } from "../../generic-multiselect/useGenericMultiSelect";
import { useSwitcherSdk } from "hooks/useSwitcherSdk";

export interface GenericMultiSelectModalProps<T> {
    isMultiple: boolean; //should the user be able to select multiple items?
    onSubmit: (ids: string[]) => void; //callback function to be called on modal's onSubmit. returns list of selected ids
    onChange?: (ids: string[]) => void;
    isOpen: boolean; //state of modal's open vs closed
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    previouslySelectedIds: string[]; //if not applicable, send empty array
    allowUnselect?: boolean; // allows unselection of previous items
    titleText: string; //title of modal
    buttonText: string; //text within submit button
    items?: ComponentItem<T>[]; //list of component item objects
    /** Prevents error on selecting no items */
    allowNoSelection?: boolean;
    /** Submit button not clickable if no items selected. Independent of allowNoSelection. */
    preventDismiss?: boolean;
    submitHandlesClose?: boolean;
    showNone?: boolean;
    /** Indicates to modal to show loading state */
    loading?: boolean;
    emptyStateMessage?: JSX.Element;
    /** Allows react components to be added outside of the multiselect */
    nonSelectItems?: ReactNode[];
    submitDisabled?: boolean;
}

export function GenericMultiSelectModal<T>({
    isMultiple,
    onSubmit,
    onChange,
    isOpen,
    setIsOpen,
    previouslySelectedIds = [],
    allowUnselect = false,
    titleText,
    buttonText,
    items,
    allowNoSelection = false,
    preventDismiss = false,
    submitHandlesClose = false,
    showNone = false,
    loading = false,
    emptyStateMessage,
    nonSelectItems,
    submitDisabled
}: GenericMultiSelectModalProps<T>) {
    const { t } = useTranslation();
    const { isInBrowser } = useSwitcherSdk();
    const {
        GenericMultiSelectComponent,
        handleSubmit,
        handleSelectOrDeselectAll,
        anySelected
    } = useGenericMultiSelect<T, T>({
        isMultiple,
        previouslySelectedIds,
        allowUnselect,
        onSubmit,
        onChange,
        items,
        allowNoSelection,
        showNone,
        loading
    });

    const close = useCallback(() => setIsOpen(false), [setIsOpen]);

    const handleSubmitLocal = useCallback(() => {
        handleSubmit && handleSubmit();
        submitHandlesClose && setIsOpen(false);
    }, [submitHandlesClose, handleSubmit, setIsOpen]);

    if (items === undefined || items === null || items.length == 0)
        return <></>;
    return (
        <ModalBase
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            preventDismiss={preventDismiss}
            header={titleText}
            successButton={buttonText}
            onSuccess={handleSubmitLocal}
            successDisabled={submitDisabled}
            onDismiss={close}
            dismissButton={t("buttons:cancel")}
            tertiaryButton={{
                text: !anySelected
                    ? t("buttons:select-all")
                    : t("buttons:deselect-all"),
                onClick: handleSelectOrDeselectAll
            }}
        >
            <div
                className={`${styles["multi-select-container"]} ${
                    !isInBrowser ? styles["in-app"] : ""
                }`}
            >
                <div className={styles["content"]}>
                    {items?.length > 0 ? (
                        GenericMultiSelectComponent
                    ) : (
                        <div className={styles["empty-state"]}>
                            {emptyStateMessage}
                        </div>
                    )}
                </div>

                {nonSelectItems?.map((item, index) => (
                    <div key={index} className={styles["non-select-item"]}>
                        <hr />
                        <div key={index}>{item}</div>
                    </div>
                ))}
            </div>
        </ModalBase>
    );
}
