import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import Logo from "assets/icons/switcherLogoV.png";
import { Link } from "react-router-dom";

export interface UpgradePromptProps {
    message: string;
}

export const UpgradePrompt: React.FC<UpgradePromptProps> = ({ message }) => {
    const { t } = useTranslation();

    return (
        <div className={styles["prompt-container"]}>
            <div className={styles["logo-container"]}>
                <img className="img-fluid" src={Logo} alt="Switcher Logo" />
            </div>
            <p className={styles["warning-text"]}>{message}</p>
            <Link to={"/subscription#openModal"} className="btn btn-primary">
                {t("buttons:upgrade-account")}
            </Link>
        </div>
    );
};
