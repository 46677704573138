import React, { useCallback, useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { updateGeneralForm } from "store/platforms/slice";
import { useTranslation } from "react-i18next";
import {
    fileReaderAsync,
    ASSET_TYPE_MAP
} from "views/page-content/cloud/upload/upload-helpers/helpers";
import PlaceholderFB from "assets/placeholder-fb.png";
import PlaceholderYT from "assets/placeholder-yt.png";
import PlaceholderMS from "assets/placeholder-multistream.png";
import { useIsMountedRef } from "hooks/useIsMountedRef";
import { setLoading } from "store/loading/slice";
import { resizeThumbnail } from "views/page-content/cloud/upload/upload-helpers/thumbnail-helpers";

interface CoverPhotoProps {
    platformId: string;
}

const DEFAULTS = {
    facebook: PlaceholderFB,
    youtube: PlaceholderYT,
    simulcast: PlaceholderMS,
    default: "https://via.placeholder.com/1920x1080/eee?text=16:9"
};

export const CoverPhoto: React.FC<CoverPhotoProps> = ({ platformId }) => {
    const isMountedRef = useIsMountedRef();
    const coverPhotoRef = useRef<HTMLInputElement>();
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const [scheduledImage, setScheduledImage] = useState(null);
    const [defaultUrl, setDefaultUrl] = useState<string>();
    const [defaultFile, setDefaultFile] = useState<File>();

    useEffect(() => {
        const setDefault = async () => {
            // TODO: Update this logic to actually choose the 'default' image
            // in the cast that the platformId is not a DEFAULTS key
            const res = await fetch(DEFAULTS[platformId]);
            const blob = await res.blob();
            const file = new File([blob], "default.png", { type: blob.type });
            if (!isMountedRef.current) {
                return;
            }
            setDefaultFile(file);
            dispatch(updateGeneralForm({ scheduledImageFile: file }));
            if (file) {
                const url = (await fileReaderAsync(file)) as string;
                setDefaultUrl(url);
                dispatch(updateGeneralForm({ scheduledImageFileUrl: url }));
                setScheduledImage(url);
            }
        };
        setDefault();
    }, [dispatch, isMountedRef, platformId]);

    const onFileInputChange = useCallback(
        async (e: HTMLInputElement | DataTransfer) => {
            let file = e.files[0];

            if (file) {
                try {
                    dispatch(setLoading(1));

                    const { file: resizedFile, url: resizedUrl } =
                        await resizeThumbnail(file);

                    dispatch(
                        updateGeneralForm({ scheduledImageFile: resizedFile })
                    );
                    dispatch(
                        updateGeneralForm({ scheduledImageFileUrl: resizedUrl })
                    );
                    setScheduledImage(resizedUrl);
                } finally {
                    dispatch(setLoading(-1));
                }
            }
        },
        [dispatch]
    );

    const clearImage = useCallback(() => {
        coverPhotoRef.current.value = null;
        dispatch(updateGeneralForm({ scheduledImageFile: defaultFile }));
        dispatch(updateGeneralForm({ scheduledImageFileUrl: defaultUrl }));
        setScheduledImage(defaultUrl);
    }, [dispatch, defaultUrl, defaultFile]);

    return (
        <>
            <div>
                <label>{t("platforms:scheduled-post-cover-image")}</label>
                <div className={styles["cover-image-container"]}>
                    <img src={scheduledImage} alt="scheduled post cover" />
                </div>
                <div className={styles["cover-image-buttons"]}>
                    <label
                        htmlFor="scheduled-cover-photo"
                        className="btn btn-primary"
                    >
                        {t("platforms:select-photo")}
                        <input
                            id="scheduled-cover-photo"
                            type="file"
                            ref={coverPhotoRef}
                            accept={[ASSET_TYPE_MAP.image].join(", ")}
                            name="scheduled_photo"
                            onChange={($event) =>
                                onFileInputChange($event.target)
                            }
                        />
                    </label>
                    <button
                        className="btn btn-outline-primary"
                        type="button"
                        onClick={clearImage}
                    >
                        {t("buttons:reset")}
                    </button>
                </div>
            </div>
        </>
    );
};
