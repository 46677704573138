import { Dispatch, SetStateAction } from "react";
import { ModalBase } from "../ModalBase";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
import { createUrl } from "helpers/url";
import CloseIcon from "assets/icons/close.svg?react";

const cx = classNames.bind(styles);

const VITE_SWITCHER_PLAYER_URL = import.meta.env.VITE_SWITCHER_PLAYER_URL;

export interface VideoPlaybackModalProps {
    broadcastId: string;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    autoplay?: boolean;
}

export const VideoPlaybackModal = ({
    broadcastId,
    isOpen = false,
    setIsOpen,
    autoplay = true
}: VideoPlaybackModalProps) => {
    const url = createUrl(VITE_SWITCHER_PLAYER_URL, {
        pathname: "/watch",
        searchParams: {
            b: broadcastId,
            autoplay: autoplay.toString()
        }
    });

    return (
        <ModalBase
            onDismiss={() => setIsOpen(false)}
            isOpen={isOpen}
            variant="video-playback"
        >
            {/* Header is allowed here specifically for the functionality of the video preview*/}
            <div className={styles["header"]}>
                <button
                    className={styles["close-btn"]}
                    onClick={() => setIsOpen(false)}
                >
                    <CloseIcon />
                </button>
            </div>
            <div className={cx("video-container")}>
                <iframe
                    title="playback-modal"
                    src={url.href}
                    style={{ border: "none" }}
                    height="720"
                    width="1280"
                    allowFullScreen
                    allow="autoplay; picture-in-picture"
                ></iframe>
            </div>
        </ModalBase>
    );
};
