import React from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { Select } from "components/inputs/select/Select";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useClaimCheck } from "hooks/useClaimCheck";

export enum FilterOptions {
    All = "all-filter",
    Gated = "gated",
    NotGated = "not-gated",
    InPlayer = "in-player",
    NotInPlayer = "not-in-player",
    Shoppable = "shoppable",
    NotShoppable = "not-shoppable",
    Published = "published",
    Unpublished = "unpublished",
    Scheduled = "scheduled"
}
export enum SortOptions {
    NewestFirst = "newest-first",
    OldestFirst = "oldest-first",
    Next = "next",
    Last = "last",
    TitleAZ = "title-a-z",
    TitleZA = "title-z-a",
    MostViews = "most-views",
    LeastViews = "least-views"
}
export interface SearchSortBarProps<T extends string> {
    /** Sort Dropdown. Defaulted to true. */
    showSort?: boolean;
    /** An array of options objects by which to sort. Has default array of objects if none passed in. */
    sortOptions?: {
        key: string;
        label: string;
    }[];
    /** The user's selected sort option. */
    selectedSortOption?: string;
    /** Take user's input and set the selectedSortOption. */
    setSelectedSortOption?: React.Dispatch<React.SetStateAction<T>>;
    hiddenSortOptions?: SortOptions[];
    /** An array of options objects by which to categorize. */
    categoryOptions?: {
        value: string;
        text: string;
    }[];
    /** The user's selected category option. */
    selectedCategoryOption?: string;
    /** Take user's input and set the selectedCategoryOption. */
    setSelectedCategoryOption?: React.Dispatch<React.SetStateAction<string>>;
    /** Search Bar. Defaulted to true. */
    showSearch?: boolean;
    /** The user's search query. */
    query?: string;
    /** Take user's input and set the query. */
    setQuery?: React.Dispatch<React.SetStateAction<string>>;
    showFilters?: boolean;
    gatedOptions?: FilterOptions[];
    setSelectedFilterOption?: React.Dispatch<React.SetStateAction<string>>;
    selectedFilterOption?: string;
    onClear?: () => void;
}

/**
 *
 * @type T - The type of the sort options.
 */
export const SearchSortBar = <T extends string>({
    showSort = true,
    sortOptions = null,
    selectedSortOption = SortOptions.NewestFirst,
    setSelectedSortOption,
    categoryOptions = [],
    selectedCategoryOption = "all",
    setSelectedCategoryOption,
    hiddenSortOptions = [SortOptions.Next, SortOptions.Last],
    showSearch = true,
    query,
    setQuery,
    showFilters = false,
    selectedFilterOption = FilterOptions.All,
    setSelectedFilterOption,
    onClear
}: SearchSortBarProps<T>) => {
    const { t } = useTranslation("broadcasts-multi-select");
    const hasCatalogClaim = useClaimCheck("catalog");

    return (
        <div className={`${styles["sort-search-container"]} search-sort-bar`}>
            {showSort && (
                <Select
                    id="sort"
                    onChange={(e) => {
                        setSelectedSortOption(e.target.value as T);
                    }}
                    options={
                        sortOptions
                            ? sortOptions
                            : Object.keys(SortOptions)
                                  .filter(
                                      (option) =>
                                          !hiddenSortOptions.includes(
                                              SortOptions[option]
                                          )
                                  )
                                  .map((option) => {
                                      return {
                                          key: SortOptions[option],
                                          label: t(SortOptions[option])
                                      };
                                  })
                    }
                    label="Sort"
                    optionKey="key"
                    optionLabel="label"
                    selected={selectedSortOption}
                    containerClassName={styles["sort-dropdown"]}
                />
            )}
            <Select
                id="category"
                onChange={(e) => {
                    setSelectedCategoryOption(e.target.value as T);
                }}
                options={categoryOptions}
                selected={selectedCategoryOption}
                label={`${
                    hasCatalogClaim
                        ? t("broadcasts-multi-select:tag")
                        : t("broadcasts-multi-select:category")
                }`}
                containerClassName={styles["category-dropdown"]}
            />

            {showFilters && (
                <Select
                    id="filter"
                    onChange={(e) => {
                        setSelectedFilterOption(e.target.value as T);
                    }}
                    options={Object.keys(FilterOptions).map((option) => {
                        let filterOption = FilterOptions[option];
                        let label = filterOption;

                        switch (true) {
                            case filterOption === FilterOptions.InPlayer &&
                                hasCatalogClaim:
                                label = "in-collection";
                                break;
                            case filterOption === FilterOptions.NotInPlayer &&
                                hasCatalogClaim:
                                label = "not-in-collection";
                                break;
                            default:
                                label = filterOption;
                                break;
                        }

                        return {
                            key: filterOption,
                            label: t(label)
                        };
                    })}
                    selected={selectedFilterOption}
                    label="Filter"
                    optionKey="key"
                    optionLabel="label"
                    containerClassName={styles["filter-dropdown"]}
                />
            )}
            {showSearch && (
                <div className={styles["search-bar-container"]}>
                    <TextInput
                        id={"search"}
                        label={"Search"}
                        type={"text"}
                        onChange={(q) => {
                            setQuery(q.target.value as T);
                        }}
                        value={query}
                        className="broadcasts-search-label"
                        onClear={onClear}
                        hideClear={query === ""}
                    />
                </div>
            )}
        </div>
    );
};
