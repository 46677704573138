import React, { useCallback, useState } from "react";
import { UserState } from "store/user/types";
import { client } from "api/client";
import { useDispatch } from "react-redux";
import { NotificationType } from "store/notification/types";
import { addNotification } from "store/notification/slice";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getUserInfo, refreshToken } from "store/user/thunks";
import { ModalBase } from "components/modal/ModalBase";
import { format } from "helpers/time";
import { AppDispatch } from "store/store";

type AccountDeletionConfirmationViewProps = {
    user: UserState;
};

export const AccountDeletionConfirmationView: React.FC<
    AccountDeletionConfirmationViewProps
> = ({ user }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [undoDeletionModalIsOpen, setUndoDeletionModalIsOpen] =
        useState<boolean>();

    const activeUntil = format(
        user.userInfo.ProjectedDeletionDate,
        "MM/DD/YYYY"
    );

    const undoDeletionRequest = useCallback(async () => {
        setUndoDeletionModalIsOpen(true);
        try {
            await client.deletionRequests_DeleteDeletionRequest(
                user.userInfo.UserId
            );
            await dispatch(refreshToken());
            await dispatch(getUserInfo());
            navigate("/home");
        } catch {
            dispatch(
                addNotification({
                    type: NotificationType.Danger,
                    message: "delete-account:deletion-request-undo-failure"
                })
            );
        }
    }, [user.userInfo.UserId, dispatch, navigate]);

    return (
        <>
            <h4 style={{ color: "#DC3545" }}>
                {t("delete-account:deletion-scheduled")}
            </h4>
            <p>
                {t("delete-account:change-of-mind", { activeUntil })}{" "}
                {t("delete-account:download-your-files")}
            </p>
            <button className={`btn btn-primary`} onClick={undoDeletionRequest}>
                {t("delete-account:deletion-request-undo").toUpperCase()}
            </button>
            <ModalBase
                isOpen={undoDeletionModalIsOpen}
                setIsOpen={setUndoDeletionModalIsOpen}
            >
                <p>{t("delete-account:deletion-request-undo-success")}</p>
            </ModalBase>
        </>
    );
};
