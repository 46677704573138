import { BroadcastResponse } from "@switcherstudio/api-core-client";
import { SwitcherStreamSettingsResponse } from "@switcherstudio/switcher-api-client";
import { ChannelSetting } from "views/page-content/platforms/platform/platform-helper";

export type StreamQualityDisplay = `${1080 | 720 | 540 | 360 | 270}p`;

export interface StreamQualityOption {
    name: StreamQualityDisplay;
    setting: ChannelSetting;
}

export const availableQualities: readonly StreamQualityOption[] = [
    {
        name: "270p",
        setting: new ChannelSetting(480, 270, 500000, 1, 64000, 48000)
    },
    {
        name: "360p",
        setting: new ChannelSetting(640, 360, 800000, 1, 64000, 48000)
    },
    {
        name: "540p",
        setting: new ChannelSetting(960, 540, 1200000, 2, 128000, 48000)
    },
    {
        name: "720p",
        setting: new ChannelSetting(1280, 720, 3000000, 2, 128000, 48000)
    },
    {
        name: "1080p",
        setting: new ChannelSetting(1920, 1080, 6000000, 2, 128000, 48000)
    }
] as const;

export const getStreamQualityByString = (
    quality: StreamQualityDisplay
): StreamQualityOption => availableQualities.find((q) => q.name === quality);

export const getStreamQualityByWidth = (width: number): StreamQualityOption => {
    return availableQualities.find(
        (quality) => quality?.setting["video-frame-width"] === width
    );
};

export const getStreamQualityByHeight = (
    height: number
): StreamQualityOption => {
    return availableQualities.find(
        (quality) => quality?.setting["video-frame-height"] === height
    );
};

export const getStreamQualityFromBroadcastResponse = (
    broadcast: BroadcastResponse,
    { defaultQuality = 720 }: { defaultQuality?: number } = {}
): StreamQualityOption => {
    return getStreamQualityByHeight(
        broadcast?.StreamSettings?.[0]?.Details?.VideoFrameHeight ??
            defaultQuality
    );
};

export const getStreamQualityFromSwitcherStreamSettingsResponse = (
    response: SwitcherStreamSettingsResponse,
    { defaultQuality = 720 }: { defaultQuality?: number } = {}
): StreamQualityOption => {
    return getStreamQualityByHeight(
        response?.StreamSettings?.[0]?.Details?.VideoFrameHeight ??
            defaultQuality
    );
};
