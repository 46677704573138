import { useCallback, useMemo, useState, useEffect } from "react";
import { useSwitcherClient } from "./useSwitcherClient";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import {
    CreatorProduct,
    CreatorProductPricesBindingModel,
    CreatorProductPricesBindingModelEntitledDurationUnit
} from "@switcherstudio/switcher-api-client";
import { postEvents } from "store/events/thunks";
import rollbar from "helpers/rollbar";
import { StringDictionary } from "types";
import { buildPricesArray } from "components/modal/GatedContentModal/helpers";
import { GatedContentPassFormProps } from "components/modal/GatedContentModal";
import { displayAmount } from "helpers/stripe";

export enum PricingModalTypes {
    Create,
    Update
}

interface UseGatedContentPassFormProps {
    accountId: string;
    oldProduct?: CreatorProduct;
    onSubmit: () => void;
    type: PricingModalTypes;
    hasRecurringPaymentsClaim: boolean;
}

export const useGatedContentPassForm = ({
    accountId,
    oldProduct,
    type,
    onSubmit,
    hasRecurringPaymentsClaim
}: UseGatedContentPassFormProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [errors, setErrors] = useState<StringDictionary>({});
    const [values, setValues] = useState<GatedContentPassFormProps>();

    const {
        oldOneTimePrice,
        oldRecurringMonthlyPrice,
        oldRecurringAnnualPrice,
        oldOneTimeDuration,
        oldOneTimeDurationUnits,
        untimedOTPInitiallySelected
    } = useMemo(() => {
        const oldOneTimePrice = oldProduct?.Prices?.find(
            (p) => p.IsRecurring === false
        );
        const oldRecurringMonthlyPrice = oldProduct?.Prices?.find(
            (p) => p.IsRecurring === true && p.RecurringInterval === "month"
        );
        const oldRecurringAnnualPrice = oldProduct?.Prices?.find(
            (p) => p.IsRecurring === true && p.RecurringInterval === "year"
        );
        const oldOneTimeDuration = oldProduct?.Prices?.find(
            (p) => p.IsRecurring === false && p.EntitledDuration !== null
        )?.EntitledDuration;
        const oldOneTimeDurationUnits = oldProduct?.Prices?.find(
            (p) => p.IsRecurring === false && p.EntitledDuration !== null
        )?.EntitledDurationUnit;
        const untimedOTPInitiallySelected =
            oldOneTimePrice?.Active && !oldOneTimePrice?.EntitledDuration;

        return {
            oldOneTimePrice,
            oldRecurringMonthlyPrice,
            oldRecurringAnnualPrice,
            oldOneTimeDuration,
            oldOneTimeDurationUnits,
            untimedOTPInitiallySelected
        };
    }, [oldProduct]);

    const { loading: postLoading, dispatchApiRequest: postProduct } =
        useSwitcherClient((client) => client.creatorProducts_Create);
    const { loading: productsUpdateLoading, dispatchApiRequest: putProduct } =
        useSwitcherClient((client) => client.creatorProducts_Update, {});
    const {
        loading: pricesCreateLoading,
        dispatchApiRequest: postProductPrices
    } = useSwitcherClient((client) => client.creatorProductPrices_Create, {});
    const {
        loading: pricesUpdateLoading,
        dispatchApiRequest: putProductPrices
    } = useSwitcherClient((client) => client.creatorProductPrices_Update, {});

    const processing = useMemo(
        () =>
            postLoading ||
            productsUpdateLoading ||
            pricesCreateLoading ||
            pricesUpdateLoading,
        [
            postLoading,
            pricesCreateLoading,
            pricesUpdateLoading,
            productsUpdateLoading
        ]
    );

    const defaultFormValues: GatedContentPassFormProps = useMemo(
        () => ({
            name: "",
            description: "",
            selectedPassType: hasRecurringPaymentsClaim
                ? "recurring"
                : "one-time",
            oneTimePrice: "2.00",
            oneTimePriceSelected: true,
            oneTimeDurationSelected: false,
            oneTimeDuration: "",
            oneTimeDurationUnits: null,
            recurringMonthlyPriceSelected: true,
            recurringAnnualPriceSelected: true,
            recurringMonthlyPrice: "10.00",
            recurringAnnualPrice: "100.00",
            recurringApplySubscriptionUpdatesNewOnly: true,
            oldProduct: null,
            oldRecurringMonthlyPrice: null,
            oldRecurringAnnualPrice: null,
            oldOneTimePrice: null
        }),
        [hasRecurringPaymentsClaim]
    );

    const existingPassValues: GatedContentPassFormProps = useMemo(
        () => ({
            selectedPassType: oldProduct?.Prices?.[0]?.IsRecurring
                ? "recurring"
                : "one-time",
            name: oldProduct?.Name || "",
            description: oldProduct?.Description || "",
            oneTimePrice: "0",
            recurringMonthlyPrice: "0",
            recurringAnnualPrice: "0",
            recurringMonthlyPriceSelected: false,
            recurringAnnualPriceSelected: false,
            oneTimeDurationSelected: false,
            oneTimeDuration: "",
            oneTimeDurationUnits: null,
            recurringApplySubscriptionUpdatesNewOnly: true,
            oldProduct: oldProduct || null,
            oldRecurringMonthlyPrice: oldRecurringMonthlyPrice || null,
            oldRecurringAnnualPrice: oldRecurringAnnualPrice || null,
            oldOneTimePrice: oldOneTimePrice || null,
            ...(oldOneTimePrice && {
                oneTimePrice: displayAmount(oldOneTimePrice?.Amount, {
                    signed: false,
                    compact: false,
                    roundUp: false,
                    useGrouping: false
                })
            }),
            ...(oldRecurringMonthlyPrice && {
                recurringMonthlyPriceSelected: true,
                recurringMonthlyPrice: displayAmount(
                    oldRecurringMonthlyPrice?.Amount,
                    {
                        signed: false,
                        compact: false,
                        roundUp: false,
                        useGrouping: false
                    }
                )
            }),
            ...(oldRecurringAnnualPrice && {
                recurringAnnualPriceSelected: true,
                recurringAnnualPrice: displayAmount(
                    oldRecurringAnnualPrice?.Amount,
                    {
                        signed: false,
                        compact: false,
                        roundUp: false,
                        useGrouping: false
                    }
                )
            }),
            ...(oldOneTimeDuration && {
                oneTimeDurationSelected: true,
                oneTimeDuration: oldOneTimeDuration.toString(),
                oneTimeDurationUnits: oldOneTimeDurationUnits
            })
        }),
        [
            oldOneTimeDuration,
            oldOneTimeDurationUnits,
            oldOneTimePrice,
            oldProduct,
            oldRecurringAnnualPrice,
            oldRecurringMonthlyPrice
        ]
    );

    useEffect(() => {
        if (type === PricingModalTypes.Update) {
            setValues(existingPassValues);
        } else {
            setValues(defaultFormValues);
        }
    }, [
        oldProduct,
        type,
        hasRecurringPaymentsClaim,
        oldOneTimePrice,
        oldRecurringMonthlyPrice,
        oldRecurringAnnualPrice,
        oldOneTimeDuration,
        oldOneTimeDurationUnits,
        defaultFormValues,
        existingPassValues
    ]);

    const validateFields = useCallback(
        (values: GatedContentPassFormProps) => {
            let errors: StringDictionary = {};

            const validatePrice = (id: string, price: string) => {
                const baseError = t(
                    "gated-content-modal:errors:price-min-error"
                );
                if (!price) {
                    errors[id] = t("gated-content-modal:errors:price-error");
                }

                const minPrices = {
                    recurringAnnualPrice: import.meta.env
                        .VITE_MINIMUM_ANNUAL_PRICE,
                    recurringMonthlyPrice: import.meta.env
                        .VITE_MINIMUM_MONTHLY_PRICE,
                    oneTimePrice: import.meta.env.VITE_MINIMUM_ONE_TIME_PRICE
                };

                if (parseFloat(price) < parseFloat(minPrices[id])) {
                    errors[id] = `${baseError} $${minPrices[id]}`;
                }
            };

            if (!values.name?.length) {
                errors.name = t("gated-content-modal:errors:name-error");
            }

            if (values.name.length > 25) {
                errors.name = t("gated-content-modal:errors:name-max-error");
            }

            if (values.description?.length > 500) {
                errors.description = t(
                    "gated-content-modal:errors:description-max-error"
                );
            }

            if (values.selectedPassType === "one-time") {
                validatePrice("oneTimePrice", values.oneTimePrice);
                if (
                    values.oneTimeDurationSelected &&
                    (!values.oneTimeDuration ||
                        parseFloat(values.oneTimeDuration) <= 0)
                ) {
                    errors.oneTimeDuration = t(
                        "gated-content-modal:errors:duration-error"
                    );
                }
            } else {
                if (values.recurringMonthlyPriceSelected) {
                    validatePrice(
                        "recurringMonthlyPrice",
                        values.recurringMonthlyPrice
                    );
                }
                if (values.recurringAnnualPriceSelected) {
                    validatePrice(
                        "recurringAnnualPrice",
                        values.recurringAnnualPrice
                    );
                }
            }

            setErrors(errors);
            return errors;
        },
        [t]
    );

    const getPrices = useCallback(
        (
            productId: string,
            values: GatedContentPassFormProps
        ): CreatorProductPricesBindingModel[] => {
            if (values.selectedPassType === "one-time") {
                const duration = values.oneTimeDurationSelected
                    ? values.oneTimeDuration
                    : null;
                const durationUnits = values.oneTimeDurationSelected
                    ? values.oneTimeDurationUnits || "Hours"
                    : null;

                return [
                    {
                        Id: uuidv4(),
                        Name: values.name,
                        IsActive: true,
                        IsPublic: true,
                        ProductId: productId,
                        Amount: parseFloat(values.oneTimePrice) * 100,
                        BillingScheme: "per_unit",
                        ...(duration && {
                            EntitledDuration: parseFloat(duration)
                        }),
                        ...(durationUnits && {
                            EntitledDurationUnit:
                                CreatorProductPricesBindingModelEntitledDurationUnit[
                                    durationUnits
                                ]
                        })
                    }
                ];
            }

            const prices = [];

            if (values.recurringMonthlyPriceSelected) {
                prices.push({
                    Id: uuidv4(),
                    Name: values.name,
                    IsActive: true,
                    IsPublic: true,
                    ProductId: productId,
                    Amount: parseFloat(values.recurringMonthlyPrice) * 100,
                    BillingScheme: "per_unit",
                    RecurringUsageType: "licensed",
                    IsRecurring: true,
                    RecurringInterval: "month",
                    RecurringIntervalCount: 1
                });
            }

            if (values.recurringAnnualPriceSelected) {
                prices.push({
                    Id: uuidv4(),
                    Name: values.name,
                    IsActive: true,
                    IsPublic: true,
                    ProductId: productId,
                    Amount: parseFloat(values.recurringAnnualPrice) * 100,
                    BillingScheme: "per_unit",
                    RecurringUsageType: "licensed",
                    IsRecurring: true,
                    RecurringInterval: "year",
                    RecurringIntervalCount: 1
                });
            }

            return prices;
        },
        []
    );

    const handleCreate = useCallback(
        async (values: GatedContentPassFormProps) => {
            try {
                const res = await postProduct([
                    accountId,
                    {
                        Products: [
                            {
                                Id: uuidv4(),
                                Name: values.name,
                                Description: values.description,
                                StripeAccountId: accountId,
                                IsActive: true
                            }
                        ]
                    }
                ]);

                await postProductPrices([
                    accountId,
                    res?.Products?.[0]?.Id,
                    { Prices: getPrices(res?.Products?.[0]?.Id, values) }
                ]);

                dispatch(postEvents({ "created-pass": true }));
                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t(
                            "gated-content-modal:messages:pricing-create-success"
                        )
                    })
                );
            } catch (e) {
                rollbar.error("Error creating pass", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t(
                            "gated-content-modal:errors:pricing-create-error"
                        )
                    })
                );
            }
        },
        [accountId, dispatch, getPrices, postProduct, postProductPrices, t]
    );

    const handleUpdate = useCallback(
        async (values: GatedContentPassFormProps) => {
            try {
                if (
                    values.name !== oldProduct?.Name ||
                    values.description !== oldProduct.Description
                ) {
                    await putProduct([
                        accountId,
                        {
                            Products: [
                                {
                                    Id: oldProduct?.Id,
                                    Name: values.name,
                                    Description: values.description,
                                    StripeAccountId: accountId,
                                    IsActive: true
                                }
                            ]
                        }
                    ]);
                }

                const pricesArray = buildPricesArray({
                    recurringMonthlyPriceSelected:
                        values.recurringMonthlyPriceSelected || false,
                    recurringAnnualPriceSelected:
                        values.recurringAnnualPriceSelected || false,
                    recurringMonthlyPrice: values.recurringMonthlyPrice || "0",
                    recurringAnnualPrice: values.recurringAnnualPrice || "0",
                    oneTimePrice: values.oneTimePrice || "0",
                    oneTimeDurationSelected:
                        values.oneTimeDurationSelected || false,
                    oneTimeDuration: values.oneTimeDuration || "",
                    oneTimeDurationUnits: values.oneTimeDurationUnits,
                    oldProduct: oldProduct!,
                    oldRecurringMonthlyPrice: oldRecurringMonthlyPrice!,
                    oldRecurringAnnualPrice: oldRecurringAnnualPrice!,
                    oldOneTimePrice: oldOneTimePrice!,
                    name: values.name || "",
                    selectedPassType: values.selectedPassType || "one-time"
                });

                await putProductPrices([
                    accountId,
                    oldProduct?.Id,
                    {
                        Prices: pricesArray,
                        UpdateExistingCustomersToPrice:
                            !values.recurringApplySubscriptionUpdatesNewOnly
                    }
                ]);

                dispatch(
                    addNotification({
                        type: NotificationType.Success,
                        message: t(
                            "gated-content-modal:messages:pricing-update-success"
                        )
                    })
                );
            } catch (e) {
                rollbar.error("Error updating pass", e);
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: t(
                            "gated-content-modal:errors:pricing-update-error"
                        )
                    })
                );
            }
        },
        [
            accountId,
            dispatch,
            oldProduct,
            oldRecurringMonthlyPrice,
            oldRecurringAnnualPrice,
            oldOneTimePrice,
            putProduct,
            putProductPrices,
            t
        ]
    );

    const handleSubmit = useCallback(
        async (values: GatedContentPassFormProps) => {
            const errors = validateFields(values);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            const oneTimePassHasNoChanges =
                !oldOneTimePrice?.IsRecurring &&
                values.selectedPassType === "one-time" &&
                values.name === oldProduct?.Name &&
                values.description === oldProduct?.Description &&
                parseFloat(values.oneTimePrice) === oldOneTimePrice?.Amount &&
                (!values.oneTimeDurationSelected ||
                    (parseFloat(values.oneTimeDuration) ===
                        oldOneTimePrice?.EntitledDuration &&
                        values.oneTimeDurationUnits ===
                            oldOneTimePrice?.EntitledDurationUnit));

            const recurringPassHasNoChanges =
                oldProduct?.Prices?.[0]?.IsRecurring &&
                values.selectedPassType === "recurring" &&
                values.name === oldProduct?.Name &&
                values.description === oldProduct?.Description &&
                ((!oldRecurringMonthlyPrice &&
                    !values.recurringMonthlyPriceSelected) ||
                    oldRecurringMonthlyPrice?.Amount ===
                        parseFloat(values.recurringMonthlyPrice)) &&
                ((!oldRecurringAnnualPrice &&
                    !values.recurringAnnualPriceSelected) ||
                    oldRecurringAnnualPrice?.Amount ===
                        parseFloat(values.recurringAnnualPrice));

            if (
                type === PricingModalTypes.Update &&
                (oneTimePassHasNoChanges || recurringPassHasNoChanges)
            ) {
                dispatch(
                    addNotification({
                        type: NotificationType.Info,
                        message: t(
                            "gated-content-modal:messages:pricing-update-success"
                        )
                    })
                );
                return;
            }

            onSubmit();

            if (type === PricingModalTypes.Create) {
                await handleCreate(values);
            } else {
                await handleUpdate(values);
            }

            // Reset form values to initial state after successful submission
            setValues(defaultFormValues);
        },
        [
            defaultFormValues,
            dispatch,
            handleCreate,
            handleUpdate,
            oldOneTimePrice,
            oldProduct,
            oldRecurringAnnualPrice,
            oldRecurringMonthlyPrice,
            onSubmit,
            t,
            type,
            validateFields
        ]
    );

    const submitDisabled = useMemo(() => {
        if (!values?.name) return true;

        if (values?.selectedPassType === "one-time" && !values.oneTimePrice)
            return true;

        if (
            hasRecurringPaymentsClaim &&
            values?.selectedPassType === "recurring"
        ) {
            const noRecurringPricesSelected =
                !values.recurringMonthlyPriceSelected &&
                !values.recurringAnnualPriceSelected;
            const monthlySelectedButNoPrice =
                values.recurringMonthlyPriceSelected &&
                !values.recurringMonthlyPrice;
            const annualSelectedButNoPrice =
                values.recurringAnnualPriceSelected &&
                !values.recurringAnnualPrice;

            if (
                noRecurringPricesSelected ||
                monthlySelectedButNoPrice ||
                annualSelectedButNoPrice
            ) {
                return true;
            }
        }

        return Object.keys(errors).length > 0;
    }, [values, hasRecurringPaymentsClaim, errors]);

    const handlePriceChange = useCallback(
        (key: string, val: any) => {
            setValues((prev) => ({ ...prev, [key]: val }));
            if (errors[key]) {
                setErrors((prev) => {
                    const newErrors = { ...prev };
                    delete newErrors[key];
                    return newErrors;
                });
            }
        },
        [errors]
    );

    return {
        submitDisabled,
        processing,
        errors,
        handleSubmit,
        handlePriceChange,
        validateFields,
        values,
        oldOneTimePrice,
        untimedOTPInitiallySelected
    };
};
