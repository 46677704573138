import React, { useCallback, useRef, useMemo, useState } from "react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "./uppy.overrides.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "store/store";
import { useTranslation } from "react-i18next";
import { ModalBase, ModalButtonProps } from "components/modal/ModalBase";
import { UploadOptionsStep } from "./components/UploadOptionsStep";
import { IsUploadingStep } from "./components/IsUploadingStep";
import {
    setTitle,
    setAddToPlayers,
    setSelectedProduct,
    setDescription,
    setThumbnailFile,
    setThumbnailImageURL,
    setCategories,
    setWebLinks,
    setBroadcastStatus,
    setStartsAt,
    setShowInCatalog
} from "store/videoUploadSession/slice";
import { minimizeModal, closeCurrentModal } from "store/modal/slice";
import { UploadedFile } from "store/videoUploadSession/types";
import { BulkUploadConfirmationStep } from "./components/BulkUploadConfirmationStep";
import { DragAndDropStepV2 } from "./components/drag-and-drop/DragAndDropStepV2";
import { useCreateBroadcastForUpload } from "./hooks/useCreateBroadcastForUpload";
import { useGetCloudUsageForUploadSessions } from "./hooks/useGetCloudUsageForUploadSessions";
import { useCreateUppy } from "./hooks/useCreateUppy";
import { useGetUploadSession } from "./hooks/useGetUploadSession";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "store/reducers";
import { useResetUploadSession } from "./hooks/useResetUploadSession";

/**
 * The updated version of our video upload modal. This modal works off of the video upload session
 * that is stored in our Redux store. This modal can either be triggered by using it in tandem with the <VideoUploadModalEntryPoint />
 * component, or by dispatching the modal directly. In the former case, the video upload session state will be
 * pre-set by the action of a user adding files to the drag and drop in the entry point component. In the latter case,
 * the video upload session state would be empty and the user would be taken to the drag and drop step of the upload modal
 * to begin their video upload session from a clean slate from within the modal.
 *
 * Upon finishing a video upload session, the user is able to choose to upload more files. If they do that,
 * the video upload session state is cleared out and the user is taken to the drag and drop step within this modal to begin
 * the process again, regardless of which method they originally used to enter the upload modal (entry point versus going direct to modal)
 *
 * Note: This component consumes an "externalSessionId" which points to the upload session created when a user added files to via an instance
 * of the VideoUploadModalEntryPoint component.
 * While the initialization of the session id must be handled by the parent component to the VideoUploadModalEntryPoint component,
 * the ongoing session management (create/destroy/garbage collection, etc) is handled within this component via the useCreateUppy and useResetUploadSession hooks.
 *
 * */

interface TriggeredVideoUploadModalProps {
    /**
     * If the upload modal was triggered by an instance of the VideoUploadModalEntryPoint,
     * the session id associated with the initial drag and drop session state passed to this modal
     *
     * Note: this session id is only used for completing upload sessions passed to this modal for resolution
     *  In the case that this modal is used without relying on state from a previously created session (from an entry point component instance)
     *  this modal will instead use the `internalUploadSessionId` to indicate the session created from within this modal. This subsequent session
     * id will also be used once the initial upload session is completed and the user clicks the "upload more" button
     * to start the process over again
     * */
    externalUploadSessionId?: string;
}
export const TriggeredVideoUploadModal = ({
    externalUploadSessionId
}: TriggeredVideoUploadModalProps) => {
    /** The upload session id for any subsequent uploads after the first outside-triggered upload session
     * (i.e. the user finishes uploading and clicks "upload more" and goes back to the drag and drop step of *inside* the modal)
     */
    const [internalUploadSessionId, setInternalUploadSessionId] =
        useState<string>(uuidv4());
    const { t } = useTranslation("video-upload");
    const dispatch = useDispatch<AppDispatch>();
    const thumbnailAssetIdRef = useRef<string>(null);
    const { addedVideosExceedCloudUsage } = useGetCloudUsageForUploadSessions();
    const { uploadSessions } = useSelector(
        (s: RootState) => s.videoUploadSession
    );
    const externalSessionDoesNotExist = useMemo(
        () =>
            !externalUploadSessionId ||
            !uploadSessions[externalUploadSessionId],
        [externalUploadSessionId, uploadSessions]
    );
    const isInternalSession = useMemo(
        () => externalSessionDoesNotExist || addedVideosExceedCloudUsage,
        [addedVideosExceedCloudUsage, externalSessionDoesNotExist]
    );
    const { resetUploadSession } = useResetUploadSession();

    /**
     * The state from the external upload session passed to the modal
     *
     * These variables will be used to initialize the event listeners and state for
     * the internal session
     */
    const {
        lockedToPlayer: externalSessionLockedToPlayer,
        onFileAdded: externalSessionOnFileAdded,
        onUploadComplete: externalSessionOnUploadComplete,
        allowAdditionalUploads: externalSessionAllowAdditionalUploads
    } = useGetUploadSession({ sessionId: externalUploadSessionId });

    /**
     * The sessionId currently utilized for executing the upload session functionality
     * Can be either the `externalUploadSessionId` or the `internalUploadSessionId`
     * and can change once a user finishes an external session and opts to continue
     * adding more videos from inside the modal
     */
    const sessionId = useMemo(
        () =>
            isInternalSession
                ? internalUploadSessionId
                : externalUploadSessionId,
        [externalUploadSessionId, internalUploadSessionId, isInternalSession]
    );

    /** The state for the current calculated session (could be external or internal) */
    const {
        uploadsStarted,
        allComplete,
        sessionHasErrors,
        files,
        categories,
        webLinks
    } = useGetUploadSession({ sessionId });

    const filesArray = useMemo(
        () => (!!files ? Object.values(files) : []),
        [files]
    );

    const isSingleUpload = useMemo(() => filesArray.length === 1, [filesArray]);

    // destructured file properties - Used for single file upload only
    const {
        fileId,
        title,
        description,
        addToPlayers,
        selectedProduct,
        thumbnailImageURL,
        broadcastStatus,
        startsAt,
        showInCatalog = true
    } = useMemo(
        () => (isSingleUpload ? filesArray[0] : ({} as UploadedFile)),
        [filesArray, isSingleUpload]
    );

    /** The drag and drop instance used only for the drag and drop step inside the modal
     * Currently, this step is only ever accessed once the user complete's their first upload session
     * and opts to start a new session by clicking the "upload more" button
     */
    const { modalDragAndDropInstance } = useCreateUppy({
        sessionId: internalUploadSessionId,
        setSessionId: setInternalUploadSessionId,
        allowMultipleUploads: true,
        allowAdditionalUploads: externalSessionAllowAdditionalUploads,
        lockedToPlayer: externalSessionLockedToPlayer,
        onFileAdded: externalSessionOnFileAdded,
        onUploadComplete: externalSessionOnUploadComplete,
        location: "modal"
    });
    const uppy = useRef(modalDragAndDropInstance);

    const upload = useCallback(() => {
        filesArray.forEach((file) => file.uppyInstance.upload());
    }, [filesArray]);

    const resetModal = useCallback(
        (closeModal = false, shouldCancel = true) => {
            thumbnailAssetIdRef.current = null;
            if (shouldCancel) {
                filesArray.forEach((file) => {
                    if (file.isUploading) {
                        try {
                            file.uppyInstance.close();
                        } catch (e) {}
                    }
                });
            }

            if (closeModal) {
                dispatch(closeCurrentModal());
            }
            resetUploadSession({ sessionId });
        },
        [dispatch, filesArray, resetUploadSession, sessionId]
    );

    const multipleFilesAddedWaitingUpload = useMemo(
        () =>
            filesArray.length > 0 &&
            !uploadsStarted &&
            !allComplete &&
            !isSingleUpload,
        [allComplete, filesArray.length, isSingleUpload, uploadsStarted]
    );
    const singleFileAddedWaitingUpload = useMemo(
        () =>
            filesArray.length > 0 &&
            !uploadsStarted &&
            !allComplete &&
            isSingleUpload,
        [allComplete, filesArray.length, isSingleUpload, uploadsStarted]
    );
    const filesUploadingOrAllComplete = useMemo(
        () => uploadsStarted || allComplete,
        [allComplete, uploadsStarted]
    );

    const primaryButton: ModalButtonProps = useMemo(() => {
        const getPrimaryActionText = () => {
            if (!filesArray?.length) return undefined;
            if (allComplete) return t("buttons:done");
            if (uploadsStarted) return undefined;
            return t("buttons:upload");
        };

        const getPrimaryAction = () => {
            if (!allComplete) return upload;
            return null;
        };

        return {
            onClick: getPrimaryAction(),
            text: getPrimaryActionText(),
            shouldCloseOnClick: allComplete
        };
    }, [allComplete, filesArray?.length, t, upload, uploadsStarted]);

    const handleDismiss = useCallback(() => {
        if (uploadsStarted && !allComplete) {
            dispatch(minimizeModal());
        } else {
            resetModal(true, !sessionHasErrors);
        }
    }, [allComplete, dispatch, resetModal, sessionHasErrors, uploadsStarted]);

    const setIsOpen = useCallback(() => {
        if (!uploadsStarted || allComplete) {
            resetModal(true, !sessionHasErrors);
        }
    }, [allComplete, resetModal, sessionHasErrors, uploadsStarted]);

    useCreateBroadcastForUpload({ sessionId });

    const secondaryButton: ModalButtonProps = useMemo(() => {
        const secondaryActionText = (() => {
            if (allComplete && !externalSessionAllowAdditionalUploads)
                return null;
            if (allComplete) return t("buttons:upload-another");
            if (uploadsStarted) return t("buttons:minimize");
            return t("buttons:cancel");
        })();

        const secondaryAction = (() => {
            if (allComplete) {
                // upload another video, clear session, go back to first step
                return () => resetModal(false, false);
            }

            return handleDismiss;
        })();

        return {
            text: secondaryActionText,
            onClick: secondaryAction,
            shouldCloseOnClick: !allComplete
        };
    }, [
        externalSessionAllowAdditionalUploads,
        allComplete,
        handleDismiss,
        resetModal,
        t,
        uploadsStarted
    ]);

    return (
        <ModalBase
            header={t("video-upload:upload-video")}
            isOpen={true}
            setIsOpen={setIsOpen}
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            onDismiss={handleDismiss}
        >
            <>
                {isInternalSession && (
                    <DragAndDropStepV2
                        initialUppyInstance={modalDragAndDropInstance}
                        sessionId={internalUploadSessionId}
                    />
                )}

                {/** Upload Options Step -- SINGLE UPLOAD ONLY */}
                {singleFileAddedWaitingUpload && (
                    <UploadOptionsStep
                        file={filesArray[0].file}
                        title={title}
                        setTitle={(title) =>
                            dispatch(setTitle({ sessionId, fileId, title }))
                        }
                        description={description}
                        addToPlayers={
                            externalSessionLockedToPlayer
                                ? [externalSessionLockedToPlayer]
                                : addToPlayers
                        }
                        setAddToPlayers={(addToPlayers) =>
                            dispatch(
                                setAddToPlayers({
                                    sessionId,
                                    fileId,
                                    addToPlayers
                                })
                            )
                        }
                        setDescription={(description) =>
                            dispatch(
                                setDescription({
                                    sessionId,
                                    fileId,
                                    description
                                })
                            )
                        }
                        selectedProduct={selectedProduct}
                        setSelectedProduct={(selectedProduct) =>
                            dispatch(
                                setSelectedProduct({
                                    sessionId,
                                    fileId,
                                    selectedProduct
                                })
                            )
                        }
                        links={webLinks}
                        setAddLinks={(webLinks) =>
                            dispatch(
                                setWebLinks({
                                    sessionId,
                                    webLinks
                                })
                            )
                        }
                        lockedToPlayer={externalSessionLockedToPlayer}
                        setThumbnailFile={(thumbnailFile) =>
                            dispatch(
                                setThumbnailFile({
                                    sessionId,
                                    fileId,
                                    thumbnailFile
                                })
                            )
                        }
                        thumbnailImageURL={thumbnailImageURL}
                        setThumbnailImageURL={(thumbnailImageURL) =>
                            dispatch(
                                setThumbnailImageURL({
                                    sessionId,
                                    fileId,
                                    thumbnailImageURL
                                })
                            )
                        }
                        showThumbnailUploader={true}
                        showCategories={true}
                        showInCatalog={showInCatalog}
                        setShowInCatalog={(showInCatalog) =>
                            dispatch(
                                setShowInCatalog({
                                    sessionId,
                                    fileId,
                                    showInCatalog
                                })
                            )
                        }
                        selectedCategories={categories}
                        setSelectedCategories={(sc) =>
                            dispatch(
                                setCategories({ sessionId, categories: sc })
                            )
                        }
                        showCategoryDropdownWhenEmpty={false}
                        broadcastStatus={broadcastStatus}
                        setBroadcastStatus={(broadcastStatus) =>
                            dispatch(
                                setBroadcastStatus({
                                    sessionId,
                                    fileId,
                                    broadcastStatus
                                })
                            )
                        }
                        startsAt={startsAt}
                        setStartsAt={(startsAt) => {
                            dispatch(
                                setStartsAt({
                                    sessionId,
                                    fileId,
                                    startsAt
                                })
                            );
                        }}
                    />
                )}

                {/** Bulk Upload Confirmation Step -- BULK UPLOADS ONLY */}
                {multipleFilesAddedWaitingUpload && (
                    <BulkUploadConfirmationStep sessionId={sessionId} />
                )}

                {filesUploadingOrAllComplete && (
                    <IsUploadingStep initialUppy={uppy} files={filesArray} />
                )}
            </>
        </ModalBase>
    );
};
