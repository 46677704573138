import {
    SwitcherStreamSetting,
    SwitcherStreamSettingDetailsResponse,
    SwitcherStreamSettingFacebookEdge,
    SwitcherStreamSettingResponse
} from "@switcherstudio/switcher-api-client";

export const mapStreamSettingResponseToStreamSetting = (
    response: SwitcherStreamSettingResponse
): SwitcherStreamSetting =>
    mapStreamSettingDetailResponseToStreamSetting(response?.Details);

/**
 * Maps a SwitcherStreamSettingDetailsResponse to a SwitcherStreamSetting object.
 * This is used to convert the response from the API to a more usable format.
 * Note: SwitcherStreamSettingDetailsResponse does not have the StreamingProviderId property. It does seem to be null in most cases, but could potentially cause problems.
 * @param details - The SwitcherStreamSettingDetailsResponse object to map.
 * @return A SwitcherStreamSetting object.
 */
export const mapStreamSettingDetailResponseToStreamSetting = (
    details: SwitcherStreamSettingDetailsResponse
): SwitcherStreamSetting => {
    return {
        "audio-bit-rate": details?.AudioBitRate,
        "audio-channel-count": details?.AudioChannelCount,
        "audio-sample-rate": details?.AudioSampleRate,
        "channel-name": details?.ChannelName,
        "facebook-edge":
            details?.FacebookEdge as unknown as SwitcherStreamSettingFacebookEdge,
        "facebook-edge-id": details?.FacebookEdgeId,
        "facebook-video-id": details?.FacebookVideoId,
        "is-direct-to-provider": details?.IsDirectToProvider,
        "permalink-url": details?.PermalinkUrl,
        "rtmp-stream": details?.RtmpStream,
        "switcher-stream-setting-id": details?.Id,
        "updated-on": details?.UpdatedOn,
        "user-editable": details?.UserEditable,
        "youtube-stream-id": details?.YouTubeStreamId,
        "youtube-broadcast-id": details?.YouTubeBroadcastId,
        "video-bit-rate": details?.VideoBitRate,
        "video-frame-height": details?.VideoFrameHeight,
        "video-frame-width": details?.VideoFrameWidth,
        broadcastId: details?.BroadcastId,
        discriminator: details?.SwitcherStreamSettingType,
        meta: details?.Meta,
        url: details?.RtmpStreamUrl,
        version: details?.Version,
        "concurrency-token": details?.RowVersion,
        "is-deleted": false, // Not mapped in the API
        "time-before-connection": null // not mapped in the API
    };
};
