import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    addPending,
    moveToReady,
    removePending,
    moveToPendingDownload,
    updateDownloadProgress
} from "./slice";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { VideoDownloadStatus } from "components/recording-card/RecordingCard/types";
import { download } from "helpers/download";
import { Client } from "api/client";
import { DownloadProps } from "hooks/useVideoDownload";
import { addNotification } from "store/notification/slice";
import { NotificationType } from "store/notification/types";
import i18n from "localization/i18n";

const NO_LOAD_client = new Client({ showLoading: false });

const POLLING_INTERVAL = 2000;
let isPolling = false;

export const startPollingDownload = createAsyncThunk<
    void,
    DownloadProps,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>(
    "downloads/startPollingDownload",
    async (downloadProps, { getState, dispatch }) => {
        const { pending } = getState().downloads;

        if (!pending.find((dl) => dl.videoId === downloadProps.videoId)) {
            dispatch(addPending(downloadProps));
        }

        if (!isPolling) {
            isPolling = true;
            dispatch(pollPendingDownloads());
        }
    }
);

export const pollPendingDownloads = createAsyncThunk<
    void,
    void,
    {
        dispatch: AppDispatch;
        state: RootState;
    }
>("downloads/pollPendingDownloads", async (_, { getState, dispatch }) => {
    const { pending } = getState().downloads;

    if (pending.length > 0) {
        const statuses =
            await NO_LOAD_client.broadcasts_GetVideoDownloadStatuses(
                pending.map((dl) => dl.videoId)
            );

        for (const { VideoId, Response } of statuses) {
            const percentComplete = Response.result.default.percentComplete;
            dispatch(
                updateDownloadProgress({
                    videoId: VideoId,
                    percentComplete: percentComplete
                })
            );
            if (
                !Response.result ||
                !Response.success ||
                Response.errors.length > 0 ||
                Response.result.default.status === VideoDownloadStatus.Error
            ) {
                const downloadProps = pending.find(
                    (dl) => dl.videoId === VideoId
                );
                dispatch(removePending(downloadProps));
                dispatch(
                    addNotification({
                        type: NotificationType.Danger,
                        message: i18n.t("errors:download")
                    })
                );
            } else if (
                Response.result.default.status === VideoDownloadStatus.Ready
            ) {
                const downloadProps = pending.find(
                    (dl) => dl.videoId === VideoId
                );
                dispatch(moveToPendingDownload(downloadProps));

                // Adds a delay to ensure cloudlfare is ready for download.  In our testing, we were able to regularly cause 404 errors by immediately moving to trigger the download as soon as we detected it was ready.
                setTimeout(() => {
                    dispatch(moveToReady(downloadProps));
                    download(Response.result.default.url, downloadProps.title);
                }, 10000); // This is an arbitrary timeout, but one that, in testing, safelly allowed a successful download from Cloudflare.
            }
        }
    }

    setTimeout(() => {
        if (pending.length > 0) {
            dispatch(pollPendingDownloads());
        } else {
            isPolling = false;
        }
    }, POLLING_INTERVAL);
});
