import { BroadcastResponse } from "@switcherstudio/api-core-client";
import {
    BroadcastResponseDetails,
    BroadcastResponseDetailsProps
} from "components/entity-details/BroadcastResponseDetails";
import {
    ComponentItem,
    ActionsBarOptions,
    SearchSortOptions
} from "components/generic-multiselect/types";
import { useGenericMultiSelect } from "components/generic-multiselect/useGenericMultiSelect";
import { useMemo } from "react";
import { useAddVideosActions } from "components/add-videos/useAddVideoActions";
import { getBroadcastEntitlements } from "components/add-videos/AddVideos";
import { exists } from "helpers/booleans";
import { isInPast } from "helpers/time";

export interface VideoLibraryBroadcastListProps {
    broadcasts: BroadcastResponse[];
    refetchVideos: (...args: any) => Promise<any>;
    firstLoading: boolean;
    emptyStateCopy?: string;
    showDownload?: boolean;
    searchSortOptions: SearchSortOptions;
    onEdit: (broadcast: BroadcastResponse) => void;
}

export const VideoLibraryBroadcastList = ({
    broadcasts,
    refetchVideos,
    firstLoading,
    emptyStateCopy,
    showDownload = false,
    searchSortOptions,
    onEdit
}: VideoLibraryBroadcastListProps) => {
    const { handleDelete, actionsBarOptions } = useAddVideosActions({
        refetchVideos,
        isVideoLibrary: true,
        enableDownloads: showDownload
    });

    const broadcastListItemComponentItems: ComponentItem<BroadcastResponse>[] =
        useMemo(() => {
            if (firstLoading || !broadcasts?.length) return [];

            return broadcasts.map((broadcast) => {
                const isScheduled =
                    exists(broadcast?.Details?.StartsAt) &&
                    broadcast?.Details?.BroadcastStatus === "Ready";

                return {
                    id: broadcast.Details.Id,
                    component: (
                        <BroadcastResponseDetails
                            broadcast={broadcast}
                            handleDeleteBroadcast={() =>
                                handleDelete(
                                    broadcast,
                                    getBroadcastEntitlements(broadcast)
                                )
                            }
                            onBroadcastUpdate={refetchVideos}
                            disabled={
                                isInPast(broadcast?.Details?.StartsAt) &&
                                broadcast.Videos.length === 0
                            }
                            badges
                            showPricing
                            showEdit
                            onEdit={onEdit}
                            showDownload={showDownload}
                            allowVideoPlaybackOnThumbnailClick={!isScheduled}
                            location="video-library"
                        />
                    ),
                    baseObject: broadcast
                };
            });
        }, [
            firstLoading,
            broadcasts,
            refetchVideos,
            onEdit,
            showDownload,
            handleDelete
        ]);

    const { GenericMultiSelectComponent } = useGenericMultiSelect<
        BroadcastResponse,
        BroadcastResponseDetailsProps
    >({
        isMultiple: true,
        checkBoxLocation: "left",
        allowItemClick: false,
        items: broadcastListItemComponentItems,
        emptyStateCopy,
        actionsBarOptions:
            actionsBarOptions as ActionsBarOptions<BroadcastResponseDetailsProps>,
        searchSortOptions,
        loading: firstLoading,
        showSelectedCountOnActions: true
    });

    return GenericMultiSelectComponent;
};
