import GatedContentCards from "components/radio-cards/gated-content-cards";
import { ActiveSubscribersTable } from "components/modal/GatedContentModal/ActiveSubscribersTable";
import styles from "./index.module.scss";

export const CatalogPurchaseOptions = ({
    formValues,
    priceErrors,
    handlePriceChange,
    createOrUpdate,
    entitlementAndPrices,
    oneTimePassFeatureEnabled,
    untimedOTPInitiallySelected
}) => {
    const product = entitlementAndPrices?.ProductEntitlements?.[0]?.Product;
    const productId = entitlementAndPrices?.ProductEntitlements?.[0]?.ProductId;

    return !productId || !product ? null : (
        <>
            <div className={styles["pass-container"]}>
                <GatedContentCards
                    formValues={formValues}
                    errors={priceErrors}
                    type={createOrUpdate}
                    variant="catalog"
                    onPassChange={handlePriceChange}
                    creatorProductId={productId}
                    shouldShowOneTimePassOption={oneTimePassFeatureEnabled}
                    untimedOTPInitiallySelected={untimedOTPInitiallySelected}
                />
            </div>

            <ActiveSubscribersTable creatorProduct={product} />
        </>
    );
};
