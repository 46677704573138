import React, {
    PropsWithChildren,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import styles from "./index.module.scss";
import { Spinner } from "components/spinners/Spinner";
import classnames from "classnames/bind";
const cx = classnames.bind(styles);

interface LoadingOverlayProps extends PropsWithChildren {
    inlineLoading: boolean;
    isLoading: boolean;
    targetInteractiveCssSelelector?: string;
    /** If set to true the video overlay will be transparent when loading is set to true except on the first load. If false the overlay will hide content. */
    transparentOnLoad?: boolean;
    /** If set to true the video overlay will be transparent when loading for the first time is set to true. If false the overlay will hide content. */
    transparentOnFirstLoad?: boolean;
    /** If set to true, the first load styles will not be applied. Default is false. */
    ignoreFirstLoad?: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
    inlineLoading = false,
    isLoading = false,
    children,
    targetInteractiveCssSelelector,
    transparentOnLoad = false,
    transparentOnFirstLoad = false
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const firstLoad = useRef<boolean>(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [localIsLoading, setLocalIsLoading] = useState<boolean>(isLoading);
    const transparent = useMemo(() => {
        return transparentOnFirstLoad
            ? isLoading
            : !firstLoad.current && isLoading;
    }, [transparentOnFirstLoad, isLoading]);

    useEffect(() => {
        setLocalIsLoading((previousIsLoading) => {
            if (previousIsLoading && !isLoading) {
                firstLoad.current = false;
            }
            return isLoading;
        });
    }, [isLoading]);

    useEffect(() => {
        // Only find elements to modify if we're in transparent mode
        if (
            !containerRef.current ||
            !transparent ||
            !targetInteractiveCssSelelector
        )
            return;

        const elements = containerRef.current.querySelectorAll(
            targetInteractiveCssSelelector
        );

        elements.forEach((element) => {
            if (isLoading) {
                element.classList.add(styles.inactive);
            } else {
                element.classList.remove(styles.inactive);
            }
        });

        //Remove styles when component unmounts
        return () => {
            elements.forEach((element) => {
                element.classList.remove(styles.inactive);
            });
        };
    }, [isLoading, targetInteractiveCssSelelector, transparent]);

    return (
        <div
            className={cx("container", {
                "firstLoad": firstLoad.current && !inlineLoading,
                "inlineLoading": firstLoad.current && inlineLoading
            })}
            ref={containerRef}
        >
            {children}
            {isLoading && (
                <div
                    className={cx("loading", {
                        "transparent": transparentOnLoad && transparent
                    })}
                >
                    <Spinner
                        alignment={inlineLoading ? "left" : "center"}
                        size={inlineLoading ? 50 : 128}
                    />
                </div>
            )}
        </div>
    );
};
