import { useSelector } from "react-redux";
import rollbar from "helpers/rollbar";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { useCatalogData } from "hooks/useCatalogData";
import { RootState } from "store/reducers";
import { useCombineLoading } from "hooks/useCombineLoading";
import { useUserStripeData } from "hooks/useUserStripeData";

export const useAliasedApi = () => {
    /* Get data from store and custom hooks */
    const { userInfo } = useSelector((state: RootState) => state.user);

    // Get catalog data
    const {
        catalogData: catalog,
        updateCatalog,
        loading: catalogLoading
    } = useCatalogData({
        projectId: userInfo?.ProjectId
    });

    // Get Stripe info
    const {
        accountData: { details: stripeDetails, gatedContentStatus, link },
        loading: stripeLoading
    } = useUserStripeData({
        includeProducts: true,
        requestImmediately: true
    });

    /* Define (alias) dispatchApiRequest callbacks for API calls */
    // POST Products
    const { dispatchApiRequest: postProduct, loading: postProductLoading } =
        useSwitcherClient((client) => client.creatorProducts_Create);

    // POST Prices
    const {
        dispatchApiRequest: postProductPrices,
        loading: postProductPricesLoading
    } = useSwitcherClient((client) => client.creatorProductPrices_Create);

    // PUT Prices
    const {
        dispatchApiRequest: putProductPrices,
        loading: putProductPricesLoading
    } = useSwitcherClient((client) => client.creatorProductPrices_Update);

    // POST Entitlements
    const {
        dispatchApiRequest: postEntitlement,
        loading: postEntitlementLoading
    } = useSwitcherClient((client) => client.creatorProductEntitlements_Create);

    // GET Entitlements and Prices by Catalog ID (to refetch after updates)
    const {
        dispatchApiRequest: getEntitlementAndPrices,
        data: entitlementAndPrices,
        loading: entitlementAndPricesLoading
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_GetByCatalogId,
        {
            requestImmediately: true,
            args: [catalog?.Details?.Id, true]
        }
    );

    const {
        dispatchApiRequest: removeActiveSubscriptions,
        loading: removeActiveSubscriptionsLoading
    } = useSwitcherClient(
        (client) => client.creatorProductEntitlements_CancelByProductId,
        {
            requestImmediately: false,
            onError: (e) => {
                rollbar.error("Error removing active subscriptions", e, {
                    productId:
                        entitlementAndPrices?.ProductEntitlements?.[0]
                            ?.ProductId
                });
            }
        }
    );

    const loading = useCombineLoading(
        catalogLoading,
        stripeLoading,
        postProductLoading,
        postProductPricesLoading,
        putProductPricesLoading,
        postEntitlementLoading,
        entitlementAndPricesLoading,
        removeActiveSubscriptionsLoading
    );

    return {
        catalog,
        entitlementAndPrices,
        stripeDetails,
        link,
        gatedContentStatus,
        loading,
        updateCatalog,
        postProduct,
        postProductPrices,
        putProductPrices,
        postEntitlement,
        getEntitlementAndPrices,
        removeActiveSubscriptions
    };
};
