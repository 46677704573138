import { useCallback, useEffect, useState, useMemo } from "react";

export const useMediaQuery = ({
    maxWidth,
    minWidth,
    orientation
}: {
    /** max width in pixels */
    maxWidth?: number;
    /** min width in pixels */
    minWidth?: number;
    /** viewport orientation */
    orientation?: "landscape" | "portrait";
}) => {
    const [matches, setMatches] = useState<boolean>();

    const mediaQueryStr = useMemo(() => {
        let widthQuery =
            maxWidth || maxWidth === 0
                ? `(max-width: ${maxWidth}px)`
                : minWidth
                ? `(min-width: ${minWidth}px)`
                : undefined;

        const orientationQuery = orientation
            ? `(orientation: ${orientation})`
            : undefined;

        if (widthQuery && orientationQuery) {
            return `${widthQuery} and ${orientationQuery}`;
        }

        return widthQuery || orientationQuery || "all"; // 'all' is a fallback that always matches
    }, [maxWidth, minWidth, orientation]);

    const setMatchesFromEvent = useCallback((e: MediaQueryListEvent) => {
        setMatches(e?.matches);
    }, []);

    useEffect(() => {
        // Check if window is defined to prevent errors in SSR environments
        if (typeof window === "undefined") {
            return;
        }

        const mediaQueryList = window.matchMedia(mediaQueryStr);
        setMatches(mediaQueryList.matches);

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener(
                "change",
                setMatchesFromEvent,
                true
            );
        } else {
            mediaQueryList.addListener(setMatchesFromEvent);
        }

        return () => {
            if (mediaQueryList.removeEventListener) {
                mediaQueryList.removeEventListener(
                    "change",
                    setMatchesFromEvent,
                    true
                );
            } else {
                mediaQueryList.removeListener(setMatchesFromEvent);
            }
        };
    }, [mediaQueryStr, setMatchesFromEvent]);

    return matches;
};
