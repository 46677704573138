import React from "react";
import { Link } from "components/buttons/Link";
import { useTranslation } from "react-i18next";
import { ComponentMap } from "components/utility/ComponentMap";
import styles from "./DownloadsPage.module.scss";

import AppIcon from "assets/AppIcon-1024x1024_new.png";
import SwitcherCast from "assets/SwitcherCast_new.png";
import iPadGraphic from "assets/download-page-ipad-graphic.png";
import ToAppStore from "assets/app-store-cta.png";
import QR from "assets/icons/switcher-qr.png";

export interface AppDownload {
    header: string;
    icon: string;
    description:
        | string
        | {
              mobile: string;
              desktop: string;
          };
    content: React.ReactElement;
}

export const DownloadsPage: React.FC = () => {
    const { t } = useTranslation();

    const appDownloadList: AppDownload[] = [
        {
            header: t("downloads:app-download-header"),
            icon: AppIcon,
            description: {
                mobile: t("downloads:ios-description-mobile"),
                desktop: t("downloads:ios-description-desktop")
            },
            content: (
                <div className={`${styles["app-content-container"]}`}>
                    <img
                        src={iPadGraphic}
                        alt="Switcher Studio iOS App"
                        className={styles["ipad-graphic"]}
                    />
                    <div>
                        <img
                            src={QR}
                            className={styles["qr-code"]}
                            alt="QR code to App Store"
                            aria-hidden="true"
                        />

                        <a
                            title="Download on the App Store"
                            href="https://itunes.apple.com/us/app/switcher-studio-pro/id908386221"
                            className={styles["app-store-cta"]}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={ToAppStore}
                                alt="To App Store"
                                aria-hidden="true"
                            />
                        </a>
                    </div>
                </div>
            )
        },
        {
            header: t("downloads:cast-download-header"),
            icon: SwitcherCast,
            description: t("downloads:switchercast-description"),
            content: (
                <div className={`${styles["cast-content-container"]}`}>
                    <strong>{t("downloads:mobile-cast-cta")}</strong>
                    <ComponentMap
                        className={`${styles["button-container"]}`}
                        items={[
                            {
                                href: "https://itunes.apple.com/us/app/switcher-cast/id1238109306?mt=12",

                                label: `${t("downloads:download")} ${t(
                                    "misc:for_para"
                                )} MacOS`
                            },
                            {
                                href: "https://switcherstudio.blob.core.windows.net/staticcontent/software/SwitcherCastWindows.zip",
                                label: `${t("downloads:download")} ${t(
                                    "misc:for_para"
                                )} Windows`
                            }
                        ]}
                        element={({ href, label }) => (
                            <Link
                                to={href}
                                target="_blank"
                                rel="noopener noreferrer"
                                variant="button"
                            >
                                {label}
                            </Link>
                        )}
                    />
                </div>
            )
        }
    ];

    return (
        <ComponentMap
            className={`${styles["apps-container"]}`}
            items={appDownloadList}
            element={({ header, icon, description, content }) => {
                return (
                    <div className={`${styles["dl-container"]}`}>
                        <div className={`${styles["icon-container"]}`}>
                            <img
                                className={`img-fluid ${styles["smaller-icon"]}`}
                                src={icon}
                                alt={`Switcher app ${styles["icon"]}`}
                            />
                        </div>
                        <div className={`${styles["title-container"]}`}>
                            <h5>{header}</h5>
                            {typeof description === "string" ? (
                                <p>{description}</p>
                            ) : (
                                <>
                                    <p className={styles["mobile"]}>
                                        {description.mobile}
                                    </p>
                                    <p className={styles["desktop"]}>
                                        {description.desktop}
                                    </p>
                                </>
                            )}
                            {content}
                        </div>
                    </div>
                );
            }}
        />
    );
};
