import { ModalBase } from "../ModalBase";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closeCurrentModal } from "store/modal/slice";
import { useCallback, useState } from "react";
import { TextInput } from "components/inputs/text-input/TextInput";
import { useDebounce } from "hooks/useDebounce";
import { useSwitcherClient } from "hooks/useSwitcherClient";
import { CustomSlugErrors } from "@switcherstudio/switcher-api-client";
import { ComponentMap } from "components/utility/ComponentMap";
import { CustomSlugInputError } from "./CustomSlugInputError";
import styles from "./index.module.scss";

const validSlugRegex = /^(?!.*(?:watch|embed))[a-zA-Z0-9-]+$/;
const micrositeUrl = import.meta.env.VITE_SWITCHER_PLAYER_URL;

export interface EditCustomSlugModalProps {
    currentSlug: string;
    onSave: (slug: string) => void;
}

export const EditCustomSlugModal = ({
    currentSlug,
    onSave
}: EditCustomSlugModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [customSlug, setCustomSlug] = useState();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<CustomSlugErrors[]>([]);

    const { dispatchApiRequest: checkSlug } = useSwitcherClient(
        (client) => client.videoPlayers_CheckCustomSlug,
        {
            requestImmediately: false,
            onSuccess: (response) => {
                setLoading(false);
                setErrors(response.CustomSlugErrors);
            },
            onError: (_, response) => {
                setLoading(false);
                setErrors(response.CustomSlugErrors);
            }
        }
    );

    useDebounce(customSlug, 500, {
        onUpdate: (value) => validateSlug(value)
    });

    const handleCustomSlugInput = useCallback((e) => {
        setCustomSlug(e.target.value);
        setLoading(true);
    }, []);

    const validateSlug = useCallback(
        async (slug: string) => {
            if (slug === currentSlug) return;

            if (slug && validSlugRegex.test(slug)) {
                await checkSlug([slug]);
            } else {
                if (Boolean(slug)) {
                    setErrors([CustomSlugErrors.Invalid]);
                }
            }
        },
        [checkSlug, currentSlug]
    );

    return (
        <ModalBase
            isOpen={true}
            header={t("edit-custom-slug:header")}
            successButton={t("edit-custom-slug:submit")}
            dismissButton={t("edit-custom-slug:cancel")}
            successDisabled={!customSlug || loading || errors.length > 0}
            onSuccess={() => onSave(customSlug)}
            onDismiss={() => dispatch(closeCurrentModal())}
        >
            <div className={styles["edit-custom-slug-modal"]}>
                <label htmlFor="custom-slug" className={styles["label"]}>
                    {t("edit-custom-slug:paragraph")}
                </label>

                <span className={styles["input-container"]}>
                    <p>{micrositeUrl}/</p>
                    <TextInput
                        id="custom-slug"
                        type="text"
                        placeholder={currentSlug}
                        value={customSlug}
                        onChange={handleCustomSlugInput}
                    />
                </span>

                <div className={styles["error-container"]}>
                    <ComponentMap
                        items={errors}
                        element={(error) => (
                            <CustomSlugInputError error={error} />
                        )}
                    />
                </div>
            </div>
        </ModalBase>
    );
};
