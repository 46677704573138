import { Select } from "components/inputs/select/Select";
import {
    availableQualities,
    getStreamQualityByString,
    StreamQualityDisplay,
    StreamQualityOption
} from "helpers/resolution";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

export interface StreamQualitySelectProps {
    streamQuality: StreamQualityOption;
    setStreamQuality: (streamQuality: StreamQualityOption) => void;
}

export const StreamQualitySelect = ({
    streamQuality,
    setStreamQuality
}: StreamQualitySelectProps) => {
    const { t } = useTranslation();

    return (
        <Select
            id="stream-quality"
            containerClassName={styles["stream-quality-select"]}
            label={t("livestreams-page:quality-selector")}
            options={availableQualities.map((quality) => quality.name)}
            onChange={(event) => {
                setStreamQuality(
                    getStreamQualityByString(
                        event.target.value as StreamQualityDisplay
                    )
                );
            }}
            selected={streamQuality?.name}
        />
    );
};
