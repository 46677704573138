import React, { useMemo } from "react";
import AmazonLiveIcon from "assets/icons/amazon.svg?react";
import FacebookIcon from "assets/icons/facebook.svg?react";
import YouTubeIcon from "assets/icons/youtube.svg?react";
import TwitchIcon from "assets/icons/twitch.svg?react";
import Facebook2025Icon from "assets/icons/facebook2025.svg?react";
import YouTube2025Icon from "assets/icons/youtube2025.svg?react";
import Twitch2025Icon from "assets/icons/twitch2025.svg?react";
import RTMP2025Icon from "assets/icons/rtmp2025.svg?react";
import MsStreamIcon from "assets/icons/msstream.svg?react";
import GenericPlatformIcon from "assets/icons/platform.svg?react";
import AzureMediaServicesIcon from "assets/icons/azuremediaservices.svg?react";
import VideoPlayerIcon from "assets/icons/switcher-player.svg?react";

import { PlatformId } from "views/page-content/platforms/types";

interface PlatformIconProps {
    platformId?: string;
    destination?: string;
    iconUrl?: string;
    useNewIcon?: boolean;
}

export const PlatformIcon: React.FC<PlatformIconProps> = ({
    platformId,
    destination,
    useNewIcon = false
}: PlatformIconProps) => {
    const icon = useMemo((): React.ReactElement => {
        if (platformId) {
            if (useNewIcon)
                switch (platformId) {
                    case PlatformId.Facebook:
                        return <Facebook2025Icon className="facebook" />;
                    case PlatformId.Youtube:
                        return <YouTube2025Icon className="youtube" />;
                    case PlatformId.Twitch:
                        return <Twitch2025Icon className="twitch" />;
                    default:
                        return <RTMP2025Icon />;
                }
            switch (platformId) {
                case PlatformId.AmazonLive:
                    return <AmazonLiveIcon />;
                case PlatformId.AzureMediaServices:
                    return <AzureMediaServicesIcon />;
                case PlatformId.Facebook:
                    return <FacebookIcon />;
                case PlatformId.Youtube:
                    return <YouTubeIcon />;
                case PlatformId.Twitch:
                    return <TwitchIcon />;
                case PlatformId.MsStream:
                    return <MsStreamIcon />;
                case PlatformId.VideoPlayer:
                    return <VideoPlayerIcon />;
                default:
                    return <RTMP2025Icon />;
            }
        } else if (destination) {
            if (useNewIcon)
                switch (destination) {
                    case "FaceBookSwitcherStreamSetting":
                        return <Facebook2025Icon className="facebook" />;
                    case "YouTubeSwitcherStreamSetting":
                        return <YouTube2025Icon className="youtube" />;
                    case "TwitchSwitcherStreamSetting":
                        return <Twitch2025Icon className="twitch" />;
                    default:
                        return <RTMP2025Icon />;
                }
            switch (destination) {
                case "FaceBookSwitcherStreamSetting":
                    return <FacebookIcon />;
                case "YouTubeSwitcherStreamSetting":
                    return <YouTubeIcon />;
                case "TwitchSwitcherStreamSetting":
                    return <TwitchIcon />;
                case "VideoPlayerSwitcherStreamSetting":
                    return <VideoPlayerIcon />;
                default:
                    return <RTMP2025Icon />;
            }
        } else {
            return <GenericPlatformIcon />;
        }
    }, [destination, platformId, useNewIcon]);

    return <span>{icon}</span>;
};
